<template>
  <el-form
    :model="form"
    :rules="formRules"
    ref="form"
    class="leading-none"
    hide-required-asterisk
  >
    <el-form-item prop="name">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
           {{ $t('column_labels.channel_name') }}
        </p>
        <el-input
          v-model="form.name"
          :placeholder="$t('chat_apps.channels.messenger[0]')"
          size="small"
        />
      </slot>
    </el-form-item>

    <hr class="w-1/2 h-0 border-t border-grey-dark my-8 ml-0">

    <el-form-item prop="appId">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.app_id') }}
        </p>
        <p class="note text-xs text-grey-dark leading-tight my-2">
          {{ $t('chat_apps.channels.we_chat[0]')}}
        </p>
        <el-input v-model="form.appId"
          :placeholder="$t('column_labels.app_id')"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item prop="secret">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.app_secret') }}
        </p>
        <p class="note text-xs text-grey-dark leading-tight my-2">
          {{ $t('chat_apps.channels.we_chat[1]')}}
        </p>
        <el-input
          v-model="form.secret"
          :placeholder="$t('column_labels.app_secret')"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item prop="iconUrl">
      <slot name="label">
        <div class="flex justify-between items-center">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('chat_apps.channels.we_chat[2]')}}
          </p>
          <p class="text-xs text-grey leading-none">
            {{ $t('validations.optional')}}
          </p>
        </div>
        <el-input
          v-model="form.iconUrl"
          placeholder="http://www.website.com/account-icon.png"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item class="mt-10">
      <el-button
        type="primary"
        size="small"
        @click="submit"
      >
        {{ $t('chat_apps.templates.create[21]') }}
      </el-button>
      <el-button
        size="small"
        @click="cancel"
      >
        {{ $t('actions.cancel') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'wechat-form',

  props: {
    channel: {
      default: () => {},
      type: Object,
    },
  },

  data() {
    const self = this;

    return {
      form: {
        channel: this.channel.code,
        name: '',
        appId: '',
        secret: '',
        iconUrl: '',
      },

      formRules: {
        channel: [
          { required: true, message: self.$t('validations.required', { value: self.$tc('column_labels.channel', 1) }), trigger: 'blur' },
        ],

        name: [
          { required: true, message: self.$t('validations.required', { value: self.$t('column_labels.channel_name') }), trigger: 'blur' },
        ],

        appId: [
          { required: true, message: self.$t('validations.required', { value: self.$t('column_labels.app_id') }), trigger: 'blur' },
        ],

        secret: [
          { required: true, message: self.$t('validations.required', { value: self.$t('column_labels.app_secret') }), trigger: 'blur' },
        ],

        iconUrl: [
          {
            pattern: /(?:([^:/?#]+):)?(?:\/\/([^/?#]*))?([^?#]*\.(?:jpe?g|png)$)/,
            message: self.$t('validations.valid', { value: self.$t('column_labels.url') }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  methods: {
    cancel() {
      this.$parent.$emit('close');
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('submit', this.form);
          } else {
            return false;
          }
          return false;
        });
      }
    },
  },
};
</script>

<styles lang="scss" scoped>
.el-form-item__label {
  line-height: 0;
  margin: 20px 0 10px;
}
.note {
  word-break: break-word;
}
</styles>
