var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary w-full overflow-hidden"},[_c('section',{staticClass:"relative"},[_c('div',{staticClass:"summary__chart w-full"},[(!_vm.filters.reportType.match(/whatsapp_conversations/))?_c('ChannelChart',{attrs:{"chart-data":_vm.data,"filters":_vm.filters}}):_c('ChargableChart',{attrs:{"chart-data":_vm.data,"filters":_vm.filters,"chargables":_vm.chargables}})],1)]),_c('section',{staticClass:"summary__chart-filter py-4 px-6 mt-10"},[_c('div',{staticClass:"summary__checkbox-container w-full overflow-y-auto"},[(_vm.filters.reportType.match(/whatsapp_conversations/i))?[_c('div',{staticClass:"flex"},_vm._l((_vm.availableCheckbox),function(chargable,i){return _c('div',{key:i,staticClass:"border-solid border px-4 py-2 rounded hover:shadow w-10rem hover:border-red-light ml-3",class:{
              'border-red-light text-black': _vm.chargableSelected.includes(chargable.value),
              'text-orange':
                ['businessInitiated', 'customerInitiated'].includes(chargable.value) &&
                _vm.chargableSelected.includes(chargable.value),
              'border-grey-light text-grey': !_vm.chargableSelected.includes(chargable.value),
            },attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){return _vm.selectChargeable(chargable.value)}}},[_c('div',{staticClass:"flex items-center"},[_c('svg',{staticClass:"summary__sns-legend mr-2"},[_c('rect',{style:({
                    fill: _vm.chargableSelected.includes(chargable.value)
                      ? _vm.getChargableColor(chargable.value)
                      : '#dae1e7',
                  }),attrs:{"width":"8","height":"8","rx":"2"}})]),_c('p',{staticClass:"text-xs font-semibold text-center"},[_vm._v("\n                "+_vm._s(chargable.name)+"\n              ")])]),_c('p',{staticClass:"text-sm text-center font-bold mt-1 text-black"},[_vm._v("\n              "+_vm._s(_vm.toDecimalString(_vm.data[chargable.value] ? _vm.data[chargable.value].total : 0))+"\n            ")])])}),0)]:[_c('div',{staticClass:"flex"},_vm._l((_vm.channels),function(channel){return _c('div',{key:channel.channelCode,staticClass:"border-solid border px-4 py-2 rounded hover:shadow hover:border-red-light ml-3 cursor-pointer",class:{
              'border-red-light text-black': _vm.channelsSelected.includes(channel.channelCode),
              'border-grey-light text-grey': !_vm.channelsSelected.includes(channel.channelCode),
            },attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){return _vm.selectChannel(channel.channelCode)}}},[_c('div',{staticClass:"flex items-center"},[_c('svg',{staticClass:"summary__sns-legend mr-2"},[_c('rect',{style:({
                    fill: _vm.channelsSelected.includes(channel.channelCode)
                      ? _vm.getChannelColor(channel.channelCode)
                      : '#dae1e7',
                  }),attrs:{"width":"12","height":"12","rx":"2"}})]),_c('p',{staticClass:"text-xs font-semibold"},[_vm._v("\n                "+_vm._s(_vm.getChannelName(channel.channelCode))+"\n              ")])]),_c('p',{staticClass:"text-sm font-bold mt-1 text-center"},[_vm._v("\n              "+_vm._s(_vm.toDecimalString(channel.totalSent))+"\n            ")])])}),0)]],2)]),(_vm.filters.reportType.match(/whatsapp_conversations/))?_c('div',{staticClass:"mt-3 mb-2 text-black text-xs p-3 bg-yellow-lightest border border-solid border-yellow rounded w-full leading-normal break-words-p"},[_vm._v("\n    Effective June 1, 2024, WhatsApp introduces new “Authentication-International” rates for\n    authentication messages in specific countries. This update affects businesses sending\n    authentication messages to users in those countries. Click\n    "),_c('a',{staticClass:"text-blue",attrs:{"href":"https://www.8x8.com/cpaas/blog/whatsApp-authentication-rates-Indonesia-India","target":"_blank","rel":"noopener nofollow"}},[_vm._v("\n      here")]),_vm._v("\n    to learn more.\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }