<template>
  <div>
    <div class="ml-5">
      <ToggleChartType @toggle-chart-type="toggleChartType" />
    </div>
    <div>
      <LineChart
        :chart-data="getGraphData()"
        :options="chartOption"
        class="w-full h-full absolute pin-t pin-l mt-10"
      />
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import LineChart from '@/components/common/LineChart.vue';
import ToggleChartType from '@/components/common/ToggleChartType.vue';

import { getCommonChartConfig } from '@/utils/common';

export default {
  name: 'ChargableChart',

  components: {
    LineChart,
    ToggleChartType,
  },

  props: {
    chartData: {
      default: () => [],
      type: Array,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    chargables: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      chartOption: {
        ...getCommonChartConfig(),
      },
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    toggleChartType(type) {
      if (type === 'linear') {
        this.chartOption = { ...getCommonChartConfig() };
      } else if (type === 'logic_scale') {
        this.chartOption = { ...getCommonChartConfig(true) };
      }
    },

    formatThousand(n) {
      return n >= 1000 ? (n / 1000).toFixed(n % 1 ? 2 : 0) : n;
    },

    isSameHour(d1, d2) {
      return Moment(d1).utc().unix() === Moment(d2).utc().unix();
    },

    mergeHourValue(days, dataset) {
      return days.map((date) => {
        const total = dataset
          .filter((d) => this.isSameHour(date, Moment(d.date).utc().format('YYYY-MM-DDTHH:mm:ss')))
          .reduce((acc, curr) => acc + curr.value || 0, 0);
        return total;
      });
    },

    generateDays(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('day').format(format));
      } while (from.add(1, 'days').diff(to) < 0);
      return range;
    },

    generateHours(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('hour').format(format));
      } while (from.add(1, 'hours').diff(to) < 0);
      return range;
    },

    anchorDays(dateRange = {}) {
      const { start, end } = dateRange;

      return {
        start: Moment(start).startOf('day'),
        end: Moment(end).endOf('day'),
        format: Moment(start).isSame(Moment(end), 'day') ? 'hA' : 'MMM D (ddd)',
      };
    },

    dateRange() {
      const { start, end, format } = this.anchorDays({
        start: this.filters.daterange[0],
        end: this.filters.daterange[1],
      });

      const isDay = Moment(start).isSame(Moment(end), 'days');
      const days = isDay
        ? this.generateHours(start, end, format)
        : this.generateDays(start, end, format);
      return days;
    },

    getChannelName(code) {
      const channel = this.chargables.find((c) => c.value === code);
      return channel !== undefined ? channel.name : '';
    },

    getChannelNameByIndex(i) {
      const channels = Object.keys(this.chartData);
      const code = channels[i];
      return this.getChannelName(code);
    },

    populateData() {
      const cd = this.chartData;

      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];

      const isDay = Moment(start).isSame(Moment(end), 'days');
      const days = isDay
        ? this.generateHours(
            Moment(start).startOf('day'),
            Moment(end).endOf('day'),
            'YYYY-MM-DDTHH:mm:ss'
          )
        : this.generateDays(Moment(start).startOf('day'), Moment(end).endOf('day'));

      return Object.keys(cd).map((code) => {
        if (!!this.filters.channel && this.filters.channel !== code) {
          return {};
        }
        // Fill-in missing dates
        let d = [];

        if (isDay) {
          // If hourly
          d = this.mergeHourValue(days, cd[code].data);
        } else {
          // If daily
          days.forEach((day) => {
            // Check if day has data
            const dayData = cd[code].data.find((dd) => Moment(dd.date).isSame(Moment(day), 'day'));

            d.push(dayData ? dayData.value : 0);
          });
        }

        return {
          label: this.getChannelName(code),
          data: d,
          borderWidth: 2,
          radius: 3,
          pointHoverRadius: 3,
          borderColor: this.getChargableColor(code),
          backgroundColor: this.getChargableColor(code, 0),
          pointBackgroundColor: this.getChargableColor(code),
          pointBorderColor: this.getChargableColor(code),
          spanGaps: true,
          lineTension: 0,
        };
      });
    },

    getChargableColor(code, opacity = 1) {
      let rgba = `rgba(252, 214, 107, ${opacity})`;
      switch (code) {
        case 'free':
          rgba = `rgba(255, 141, 1, ${opacity})`;
          break;
        case 'chargeable':
          rgba = `rgba(203, 34, 1, ${opacity})`;
          break;
        case 'businessInitiated':
          rgba = `rgba(174, 214, 241, ${opacity})`;
          break;
        case 'customerInitiated':
          rgba = `rgba(52, 152, 219, ${opacity})`;
          break;
        case 'marketing':
          rgba = `rgba(106, 237, 199, ${opacity})`;
          break;
        case 'authentication':
          rgba = `rgba(153, 139, 227, ${opacity})`;
          break;
        case 'utility':
          rgba = `rgba(247, 100, 185, ${opacity})`;
          break;
        case 'service':
          rgba = `rgba(254, 207, 2, ${opacity})`;
          break;
        default:
          break;
      }
      return rgba;
    },

    dateRangeWithTz() {
      const dr = this.dateRange();

      return dr.map((d) => `${d} ${this.timeZone}`);
    },

    getGraphData() {
      return {
        labels: this.dateRange(),
        datasets: this.populateData(),
      };
    },

    toDecimalString(n) {
      return this.decimalStringFormat(n);
    },
  },
};
</script>

<style lang="scss">
.legend {
  height: 8px;
  width: 8px;
  display: inline-block;
}

.chart-container {
  width: 100%;
  flex-grow: 0;
  height: 200px;
  position: relative;

  &--large {
    height: 300px;
  }

  &--small {
    height: 130px;
  }
}
</style>
