<template>
  <section
    v-loading="loading"
    :element-loading-text="loadingText"
    class="card bg-white p-16 shadow relative block rounded overflow-hidden"
  >
    <h1 class="flex items-center mb-12">
      <img src="../../assets/chat.svg" alt="Chat" class="header-logo mr-2" />
      <p class="text-2xl text-blue">
        {{ $t('sidebar_menu_parent.Chat apps') }}
      </p>
    </h1>
    <div class="card__inner">
      <h2 class="text-black text-2xl">
        {{ $t('chat_apps.register.create_wa.header') }}
      </h2>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="pb-2 mt-4">
        <el-breadcrumb-item v-for="item in breadcrumbs" :key="item.step">
          <span :class="getClass(item.step)">
            {{ item.title }}
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>

      <Start v-show="activeStep === 0" :setup-data="setupData" @update="updateSetupData" />

      <BusinessDetails
        v-show="activeStep === 1"
        :setup-data="setupData"
        @update="updateSetupData"
      />

      <ContactInformation
        v-show="activeStep === 2"
        :setup-data="setupData"
        @update="updateSetupData"
      />

      <FacebookSetup
        v-show="activeStep === 3"
        :setup-data="setupData"
        :err-message="setupData.facebookSetup.errMessage"
        :user="user"
        :trigger-retry="setupData.facebookSetup.triggerRetry"
        @set-loading="setLoading"
        @update="updateSetupData"
        @submit="createChatappSubAccount"
      />

      <Submit v-show="activeStep === 4" :setup-data="setupData" />
    </div>
    <div class="pt-20">
      <el-button
        v-if="activeStep > 0 && activeStep < 5"
        type="default"
        icon="el-icon-arrow-left"
        size="small"
        @click="activeStep = activeStep - 1"
      >
        {{ $t('actions.back') }}
      </el-button>
      <el-button
        v-if="activeStep < 4"
        type="primary"
        icon="el-icon-arrow-right"
        size="small"
        :disabled="!allowNext"
        @click="clickNext()"
      >
        {{ $t('actions.next') }}
      </el-button>
      <el-button v-if="activeStep === 4" type="primary" size="medium" @click="clickDone()">
        {{ $t('chat_apps.channels.waes.done') }}
      </el-button>
      <el-button
        v-if="
          activeStep === 3 &&
          (setupData.facebookSetup.processRetry || setupData.facebookSetup.submitRetry)
        "
        size="small"
        @click="selectRetry"
      >
        {{ $t('chat_apps.register.facebook_setup.retry') }}
      </el-button>
    </div>
    <img src="../../assets/dashboard-illustration.png" alt="" class="card__chat-illustration" />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import timeFormat from '../../mixins/timeFormat';
import amplitude from '../../mixins/amplitude';
import Start from './Start.vue';
import BusinessDetails from './BusinessDetails.vue';
import ContactInformation from './ContactInformation.vue';
import FacebookSetup from './FacebookSetup.vue';
import Submit from './Submit.vue';
import Moment from 'moment';

export default {
  name: 'Discover',

  components: {
    Start,
    BusinessDetails,
    ContactInformation,
    FacebookSetup,
    Submit,
  },

  mixins: [timeFormat, amplitude],

  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      activeStep: 0,
      allowNext: false,
      loading: false,
      loadingText: '',
      step: '',
      subAccount: {},

      breadcrumbs: [
        { step: 0, title: this.$t('chat_apps.register.create_wa.breadcrumbs.start') },
        { step: 1, title: this.$t('chat_apps.register.create_wa.breadcrumbs.business_details') },
        { step: 2, title: this.$t('chat_apps.register.create_wa.breadcrumbs.contact_information') },
        { step: 3, title: this.$t('chat_apps.register.create_wa.breadcrumbs.facebook_setup') },
        { step: 4, title: this.$t('chat_apps.channels.waes.done') },
      ],
      setupData: {
        hasFacebook: false,
        hasCleanNumber: false,

        businessDetails: {
          channel: 'WA',
          name: '',
          phone: '',
          phoneNational: '',
          countryCode: '',
          accountName: '',
          category: 'ENTERTAINMENT',
          waCategory: 'ENTERTAIN',
          tagline: '',
          description: '',
          validated: false,
        },

        contactInfo: {
          email: '',
          websiteUrl: '',
          avatarUrl: '',
          street: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
          timezone: 'UTC+00 (UTC)',
          validated: false,
        },

        facebookSetup: {
          selectedWabaId: null,
          selectedPhoneNumberId: null,
          wabaSetupDone: false,
          processRetry: false,
          triggerRetry: false,
          submitRetry: false,
          submitDone: false,
          errMessage: null,
        },
      },
    };
  },

  computed: {
    ...mapState('channels', ['startTime']),
  },

  watch: {
    activeStep: {
      immediate: true,
      handler() {
        this.checkNext();
      },
    },

    setupData: {
      immediate: true,
      deep: true,
      handler() {
        this.checkNext();
      },
    },
  },

  methods: {
    ...mapActions({
      submitPrepaidCA: 'channels/submitPrepaidCA',
      saveWAESDetails: 'channels/saveWAESDetails',
    }),
    ...mapMutations('channels', ['SET_SUB_ACCOUNT', 'SET_START_TIME']),

    getClass(step) {
      if (step === this.activeStep) return 'text-green';
      if (step > this.activeStep) return 'text-grey-light';
      return 'text-grey';
    },

    updateSetupData(data) {
      this.setupData = { ...this.setupData, ...data };
    },

    setLoading(data) {
      const { loading, text } = data;
      this.loading = loading;
      this.loadingText = text;
    },

    checkNext() {
      const { hasFacebook, hasCleanNumber, businessDetails, contactInfo, facebookSetup } =
        this.setupData;

      switch (this.activeStep) {
        case 0:
          this.allowNext = hasFacebook && hasCleanNumber;
          break;
        case 1:
          this.allowNext = businessDetails.validated;
          break;
        case 2:
          this.allowNext = contactInfo.validated;
          break;
        case 3:
          this.allowNext =
            facebookSetup.selectedWabaId && facebookSetup.wabaSetupDone && facebookSetup.submitDone;
          break;
      }
    },

    selectRetry() {
      if (this.setupData.facebookSetup.processRetry) {
        this.setupData.facebookSetup.triggerRetry = true;
      } else if (this.setupData.facebookSetup.submitRetry && this.step === 'create-subaccount') {
        this.createChatappSubAccount();
      }
    },

    async createChatappSubAccount() {
      const { businessDetails } = this.setupData;
      const { name: channelName } = businessDetails;
      const createSaDetails = {
        channelName,
        subAccountName: `${this.user.AccountId}_CA_WA`,
        additionalChannel: false,
      };

      this.loading = true;
      this.loadingText = 'Creating sub account...';
      this.step = 'create-subaccount';

      try {
        // Create SubAccount to be used in Prepaid CA
        this.subAccount = await this.submitPrepaidCA(createSaDetails);
        this.SET_SUB_ACCOUNT({ data: this.subAccount });

        // Fetch user data in Nav for updated values
        const event = new Event('fetchUserData');
        window.dispatchEvent(event);

        this.setupData.facebookSetup.submitDone = true;
        this.setupData.facebookSetup.submitRetry = false;
        this.setupData.facebookSetup.processRetry = false;

        // Send amplitude event
        this.sendAmplitudeEvent('whatsapp_signup_subaccount_created', {
          uiArea: 'wa_embedded_signup_page',
          userId: this.user && this.user.UserId,
          subAccountId: this.subAccount.SubAccountId,
          timestamp: Moment().format(),
        });

        // Show user notification
        this.$notify({
          type: 'success',
          message: 'Sub account is successfully created.',
        });
      } catch (e) {
        this.setupData.facebookSetup.submitDone = false;
        this.SET_SUB_ACCOUNT({ data: null });

        if (e) {
          const { data } = e;
          this.setupData.facebookSetup.submitRetry = true;
          this.setupData.facebookSetup.processRetry = false;
          this.setupData.facebookSetup.errMessage =
            (data && data.error) || 'Something went wrong while processing request';
        }
      } finally {
        this.loading = false;
        this.loadingText = '';
      }
    },

    getFieldsFilled(fields, form) {
      let count = 0;
      for (const field of Object.keys(form)) {
        if (fields.includes(field) && form[field]) {
          count += 1;
        }
      }
      return count;
    },

    clickNext() {
      const timestamp = Moment().format();

      if (this.activeStep === 0) {
        let fieldsFilled = 0;
        if (this.setupData.hasFacebook) fieldsFilled++;
        if (this.setupData.hasCleanNumber) fieldsFilled++;

        this.sendAmplitudeEvent('whatsapp_signup_form_filled', {
          uiArea: 'wa_embedded_signup_page',
          userId: this.user && this.user.UserId,
          formId: 'account-setup',
          formFieldsFilled: fieldsFilled,
          timestamp,
        });
      }

      if (this.activeStep === 1) {
        const fields = ['name', 'phone', 'accountName', 'category', 'tagline', 'description'];
        const formFieldsFilled = this.getFieldsFilled(fields, this.setupData.businessDetails);
        this.sendAmplitudeEvent('whatsapp_signup_form_filled', {
          uiArea: 'wa_embedded_signup_page',
          userId: this.user && this.user.UserId,
          formId: 'business-details',
          formFieldsFilled,
          timestamp,
        });
      }

      if (this.activeStep === 2) {
        const fields = [
          'email',
          'websiteUrl',
          'street',
          'city',
          'state',
          'address1',
          'address2',
          'country',
          'zipcode',
          'timezone',
          'profilePicture',
        ];
        const formFieldsFilled = this.getFieldsFilled(fields, this.setupData.contactInfo);
        this.sendAmplitudeEvent('whatsapp_signup_form_filled', {
          uiArea: 'wa_embedded_signup_page',
          userId: this.user && this.user.UserId,
          formId: 'contact-information',
          formFieldsFilled,
          timestamp,
        });
      }

      if (this.activeStep === 3) {
        this.submitData();
        this.sendAmplitudeEvent('whatsapp_signup_waba_integration_completed', {
          uiArea: 'wa_embedded_signup_page',
          userId: this.user && this.user.UserId,
          timestamp,
        });
      }

      this.activeStep = this.activeStep + 1;
    },

    clickDone() {
      const totalTimeSpent = this.startTime ? (Date.now() - this.startTime) / 1000 : 0;
      const formattedTotalTimeSpent =
        totalTimeSpent > 0 ? this.formatTimeInSeconds(totalTimeSpent) : 'No data';

      this.sendAmplitudeEvent('whatsapp_signup_completed', {
        uiArea: 'wa_embedded_signup_page',
        userId: this.user && this.user.UserId,
        accountId: this.user && this.user.AccountId,
        subAccountId: this.subAccount.SubAccountId,
        totalTimeSpent: formattedTotalTimeSpent,
        numberOfStepsCompleted: 4,
        timestamp: Moment().format(),
      });

      this.$router.push('/channels');
    },

    async submitData() {
      const { businessDetails, contactInfo, facebookSetup } = this.setupData;
      const {
        name: channelName,
        accountName,
        category,
        waCategory,
        tagline: about,
        description,
      } = businessDetails;
      const {
        email,
        websiteUrl: accountUrl,
        avatarUrl: iconUrl,
        street,
        city,
        state,
        country,
        zipcode,
      } = contactInfo;
      const { selectedPhoneNumberId, selectedWabaId } = facebookSetup;
      const address = `${street} ${city}, ${state}, ${country} ${zipcode}`;
      const params = {
        type: 'WhatsApp',
        name: channelName,
        comment: `${channelName} WA Channel created from Connect for Prepaid CA`,
        accountName,
        about,
        description,
        accountUrl,
        iconUrl,
        email,
        category,
        waCategory,
        address: address.trim(),
        whatsappTemplateNamespace: selectedPhoneNumberId,
        whatsappBusinessAccountId: selectedWabaId,
        boundedSubAccount: this.subAccount && this.subAccount.SubAccountId,
        subAccountId: this.subAccount && this.subAccount.SubAccountId,
        userId: this.user.UserId,
        accountId: this.user.AccountId,
        userEmail: this.user.Email || this.user.Login || '',
      };

      this.loading = true;
      this.loadingText = 'Finishing setup...';
      this.step = 'register-phone';

      try {
        // Submit channel details to Service API
        // Trigger background task
        await this.saveWAESDetails(params);

        this.setupData.facebookSetup.submitDone = true;
        this.setupData.facebookSetup.submitRetry = false;
        this.setupData.facebookSetup.processRetry = false;

        // Send amplitude event
        this.sendAmplitudeEvent('whatsapp_signup_channel_creation_started', {
          uiArea: 'wa_embedded_signup_page',
          userId: this.user && this.user.UserId,
          channelName,
          timestamp: Moment().format(),
        });
      } catch (e) {
        this.setupData.facebookSetup.submitDone = false;

        if (e) {
          const { data } = e;
          this.setupData.facebookSetup.submitRetry = true;
          this.setupData.facebookSetup.processRetry = false;
          this.setupData.facebookSetup.errMessage =
            (data && data.error) || 'Something went wrong while processing request';
        }
      } finally {
        this.loading = false;
        this.loadingText = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin: 0 auto;
  max-width: 1200px;

  &__title {
    height: 70px;
  }

  // &__inner {
  //   padding-right: 25em;
  // }

  &__chat-illustration {
    width: 35%;
    position: absolute;
    bottom: -20px;
    right: 50px;
  }
}

.list {
  & li {
    padding-left: 10px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:before {
      content: '';
      width: 5px;
      height: 5px;
      background: var(--red);
      position: absolute;
      left: -5px;
      top: 6px;
      border-radius: 50%;
    }
  }
}

.header-logo {
  width: 48px;
}
</style>
