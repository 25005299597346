<template>
  <form class="relative">
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <!-- date range Select -->
        <div>
          <span class="text-xs block mb-2">
            {{ $t('column_labels.date_range') }} {{ timeZone }}
          </span>
          <el-date-picker
            v-model="dates"
            type="daterange"
            format="dd MMM yyyy"
            unlink-panels
            range-separator="—"
            :start-placeholder="$t('date_labels.start_date')"
            :end-placeholder="$t('date_labels.end_date')"
            size="small"
            class="ca-datepicker"
            :picker-options="pickerOptions"
            @change="(v) => updateFilter({ daterange: v })"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="flex flex-col rounded p-4 w-full justify-between text-black bg-grey-lighter">
      <h3 class="font-medium text-black text-sm mb-5">
        {{ $t('app_labels.filters') }}
      </h3>
      <div>
        <!-- subAccountId Select -->
        <div class="px-1">
          <span class="text-xs block mb-2"> {{ $tc('column_labels.subaccount', 1) }}</span>
          <el-select
            :value="filters.subAccountId"
            :disabled="loading"
            size="small"
            class="w-full"
            filterable
            @change="(v) => updateFilter({ subAccountId: v })"
          >
            <el-option
              v-for="subaccount in subaccountList"
              :key="subaccount.value"
              :label="subaccount.name"
              :value="subaccount.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- Channel Select -->
        <div class="mt-4 px-1">
          <span class="text-xs block mb-2">{{ $tc('column_labels.channel', 1) }}</span>
          <el-select
            :value="filters.channel"
            :disabled="loading"
            size="small"
            filterable
            class="w-full"
            @change="(v) => updateFilter({ channel: v })"
          >
            <el-option
              v-for="channel in [
                {
                  name: $t('chat_apps.logs.filters[0]'),
                  value: '',
                },
                ...channelList,
              ]"
              :key="channel.value"
              :label="channel.name"
              :value="channel.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- DirectionSelect -->
        <div class="mt-4 px-1">
          <span class="text-xs block mb-2">{{ $t('column_labels.direction') }}</span>
          <el-select
            :value="filters.direction"
            :disabled="loading"
            size="small"
            filterable
            class="w-full"
            @change="(v) => updateFilter({ direction: v })"
          >
            <el-option
              v-for="callDir in callDirectionList"
              :key="callDir.value"
              :label="callDir.label"
              :value="callDir.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- Status Select -->
        <div class="mt-4 px-1">
          <span class="text-xs block mb-2">{{ $t('column_labels.status') }}</span>
          <el-select
            :value="filters.status"
            :disabled="loading || filters.direction.match(/Incoming/)"
            size="small"
            filterable
            class="w-full"
            @change="(v) => updateFilter({ status: v })"
          >
            <el-option
              v-for="status in [
                {
                  label: $t('chat_apps.logs.filters[1]'),
                  value: '',
                },
                ...statusList,
              ]"
              :key="status.value"
              :label="status.label"
              :value="status.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- Country Select -->
        <div class="mt-4 px-1">
          <span class="text-xs block mb-2">
            {{ $t('column_labels.country') }}
          </span>
          <el-select
            :value="filters.country"
            :disabled="loading || filters.direction.match(/Incoming/)"
            size="small"
            filterable
            class="w-full"
            @change="(v) => updateFilter({ country: v })"
          >
            <el-option
              v-for="status in [
                { name: $t('chat_apps.logs.filters[2]'), value: '' },
                ...countryList,
              ]"
              :key="status.value"
              :label="status.name"
              :value="status.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- UMID filter -->
        <div class="w-full mt-4 px-1">
          <span class="text-xs block mb-2">{{ $t('column_labels.umid') }}</span>
          <el-input
            v-model="umid"
            :disabled="loading || filters.direction.match(/Incoming/)"
            :debounce="debounceDefault"
            :placeholder="$t('chat_apps.logs.filters[3]')"
            size="small"
            @change="(v) => updateFilter({ umid: umid })"
          />
        </div>
        <div class="w-full mt-4 px-1 text-center">
          <el-button
            type="primary"
            size="small"
            :disabled="countryLoading"
            @click.prevent="applyFilters"
          >
            <span>
              {{ $t('actions.apply_filter') }}
            </span>
          </el-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'Filter',

  props: {
    filters: {
      default: () => {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    channelList: {
      default: () => [],
      type: Array,
    },
    statusList: {
      default: () => [],
      type: Array,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
    countryList: {
      default: () => [],
      type: Array,
    },
    defaultDaterange: {
      default: () => [],
      type: Array,
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    const callDirectionList = [
      { label: this.$t('chat_apps.logs.directions[0]'), value: '' },
      { label: this.$t('chat_apps.logs.directions[1]'), value: 'Outgoing' },
      { label: this.$t('chat_apps.logs.directions[2]'), value: 'Incoming' },
    ];

    const self = this;
    return {
      debounceDefault: 500,

      msisdn: this.filters.msisdn || '',

      umid: this.filters.umid || '',

      callDirectionList,

      dates: [],

      pickerOptions: {
        disabledDate(time) {
          return Moment() < time || Moment().diff(time, 'months') >= 6;
        },
        shortcuts: [
          {
            text: self.$t('date_labels.today'),
            onClick(picker) {
              const end = Moment().endOf('day');
              const start = Moment().startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_7_days'),
            onClick(picker) {
              const start = Moment().subtract(1, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_14_days'),
            onClick(picker) {
              const start = Moment().subtract(2, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_30_days'),
            onClick(picker) {
              const start = Moment().subtract(30, 'days').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  created() {
    this.dates = this.defaultDaterange;
  },
  methods: {
    applyFilters() {
      this.$emit('apply-filters');
    },
    updateFilter(v) {
      this.$emit('update-filter', v);
    },
  },
};
</script>


<style scoped lang="scss">
.ca-datepicker {
  width: 100% !important;
}
</style>
