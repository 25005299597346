<template>
  <el-dialog
    :title="title"
    width="50%"
    :visible.sync="isVisible"
    destroy-on-close
    @open="openDialog"
    @close="closeDialog"
  >
    <el-form ref="form" :model="form" :rules="formRules">
      <div class="w-full flex flex-wrap">
        <div class="flex w-full">
          <el-form-item class="w-full" prop="reportType">
            <el-select
              v-model="form.reportType"
              size="small"
              :placeholder="$t('column_labels.report_type')"
              class="w-full px-2"
            >
              <el-option
                v-for="rt in reportTypeList"
                :key="rt.value"
                :label="rt.name"
                :value="rt.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="w-full" prop="metric">
            <el-select
              v-model="form.metric"
              size="small"
              :placeholder="$t('column_labels.metric_type')"
              class="w-full px-2"
            >
              <el-option
                v-for="metric in metricOptions"
                :key="metric.value"
                :label="metric.name"
                :value="metric.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item class="w-full justify-center flex" prop="daterange">
          <div class="w-full px-2">
            <el-date-picker
              v-model="form.daterange"
              type="daterange"
              size="small"
              format="dd MMM yyyy"
              unlink-panels
              :start-placeholder="$t('date_labels.start_date')"
              :end-placeholder="$t('date_labels.end_date')"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
        </el-form-item>
      </div>
      <hr class="w-full h-px bg-grey-light my-5" />
      <p class="text-xs text-grey">
        {{ $t('chat_apps.reports.export_modal[0]') }}
        <a
          class="text-blue no-underline hover:underline"
          :href="`mailto:${supportEmail}?subject=Request chat logs`"
        >
          {{ supportEmail }}
        </a>
        .
      </p>
      <p class="text-sm text-grey-darker mt-5 mb-3">
        {{ $t('chat_apps.reports.export_modal[1]') }}
        <b class="text-grey-darkest">
          {{ userEmail }}
        </b>
        {{ $t('chat_apps.reports.export_modal[2]') }}
      </p>
      <a v-if="!addEmail" class="text-sm text-blue cursor-pointer" @click="showEmailInput">
        {{ $t('chat_apps.reports.export_modal[3]') }}
      </a>
      <el-form-item v-else class="w-1/2" prop="email">
        <el-input
          v-model="form.email"
          size="small"
          placeholder="Type additional email here"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="exportData">
        {{ $t('chat_apps.reports.export_modal[4]') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'ExportModal',

  props: {
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    isVisible: {
      default: false,
      type: Boolean,
    },
    reportTypeList: {
      default: () => [],
      type: Array,
    },
    metricList: {
      default: () => {},
      type: Object,
    },
  },

  data() {
    const self = this;

    return {
      addEmail: false,

      userEmail: '',

      form: {
        reportType: '',
        metric: '',
        daterange: [],
        email: '',
      },

      formRules: {
        metric: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.metric') }),
            trigger: 'blur',
          },
        ],
        daterange: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.date_range'),
            }),
            trigger: 'blur',
          },
        ],
        email: [
          {
            type: 'email',
            message: self.$t('validations.required', { value: self.$t('fields.email') }),
            trigger: 'blur',
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return Moment() < time || Moment().diff(time, 'months') >= 6;
        },
      },
      supportEmail: this.appConfig.supportEmail,
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    metricOptions() {
      const { byChannel, whatsappConversations } = this.metricList;

      return this.form.reportType.match(/channel_wise/i) ? byChannel : whatsappConversations;
    },
  },

  watch: {
    metricOptions() {
      const { byChannel, whatsappConversations } = this.metricList;
      const options = this.form.reportType.match(/channel_wise/i)
        ? byChannel
        : whatsappConversations;
      this.form.metric =
        this.filters.reportType !== this.form.reportType ? options[0].value : this.filters.metric;
    },
  },

  created() {
    this.userEmail = this.getLoginUser().Login;
    this.form.email1 = this.userEmail;
  },

  methods: {
    getLoginUser() {
      let user = {};

      try {
        const cpv3User = localStorage.getItem('CPV3_User') || {};
        user = JSON.parse(cpv3User);
      } catch (e) {
        // do nothing
      }

      return user;
    },

    openDialog() {
      this.form.metric = this.filters.metric;
      this.form.reportType = this.filters.reportType;
      this.form.daterange = this.filters.daterange;
    },

    closeDialog() {
      this.$emit('close-modal');
      this.addEmail = false;
      this.resetForm();
    },

    resetForm() {
      this.$refs.form.resetFields();

      this.form.metric = '';
      this.form.daterange = '';
    },

    exportData() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { reportType, metric: m, daterange, email } = this.form;

            let metric = m;

            const whatsAppConvo = {
              free: 'free',
              chargeable: 'chargeable',
              customerInitiated: 'customer_initiated',
              businessInitiated: 'business_initiated',
              marketing: 'marketing',
              authentication: 'authentication',
              utility: 'utility',
              service: 'service',
            };

            if (Object.keys(whatsAppConvo).find((v) => v === metric)) {
              metric = whatsAppConvo[metric];
            }

            if (reportType === 'whatsapp_conversations' && metric === 'all') {
              metric = '';
            }

            // if (reportType === 'whatsapp_conversations') {
            //   metric = '';
            // }

            const payload = {
              reportType,
              metric,
              daterange,
              emails: [this.userEmail],
              offset: this.timeZoneOffset,
            };

            // Add additional email
            if (email) {
              payload.emails.push(email);
            }

            this.$emit('submit', payload);
          } else {
            return false;
          }
          return false;
        });
      }
    },

    showEmailInput() {
      this.addEmail = true;
    },
  },
};
</script>
