<template>
  <div class="whatsapp-form">
    <el-form
      ref="businessDetailsForm"
      :model="businessDetailsForm"
      :rules="businessDetailsFormRules"
      class="pt-4"
      label-position="top"
    >
      <el-form-item prop="name" :label="$t('column_labels.channel_name')">
        <el-input
          v-model="businessDetailsForm.name"
          :placeholder="$t('fields.account_name_placeholder')"
          size="small"
          maxlength="35"
          show-word-limit
        />
      </el-form-item>

      <el-form-item prop="phone" :label="$t('column_labels.phone_no')">
        <div class="relative">
          <vue-tel-input
            v-model="businessDetailsForm.phone"
            required
            :input-options="{ showDialCode: true }"
            class="whatsapp-form__tel-input"
            @input="handlePhoneValidate"
            @blur="handleOnBlurPhone"
            @country-changed="countryCodeUpdate"
          />
        </div>
      </el-form-item>

      <el-form-item prop="accountName" :label="$t('fields.business_name')">
        <el-input
          v-model="businessDetailsForm.accountName"
          placeholder="Ex. Acme Corp SG"
          size="small"
          maxlength="50"
          show-word-limit
        />
      </el-form-item>

      <el-form-item prop="category" :label="$t('column_labels.category')">
        <el-select
          v-model="businessDetailsForm.category"
          :placeholder="$t('actions.select_category')"
          filterable
          size="small"
          @change="changeWACategory"
        >
          <el-option v-for="c in categories" :key="c.category" :label="c.name" :value="c.category">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="tagline" :label="$t('fields.tagline')">
        <el-input
          v-model="businessDetailsForm.tagline"
          :placeholder="$t('fields.business_tagline_placeholder')"
          maxlength="130"
          show-word-limit
          size="small"
        />
      </el-form-item>

      <el-form-item prop="description" :label="$t('column_labels.description')">
        <el-input
          v-model="businessDetailsForm.description"
          type="textarea"
          :rows="4"
          :placeholder="$t('fields.business_description_placeholder')"
          maxlength="250"
          show-word-limit
        />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'BusinessDetails',

  props: {
    setupData: {
      type: Object,
      default: () => {},
    },

    update: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const validatePhone = (rule, value, callback) => {
      if (this.validPhone && value.trim().length) {
        callback();
      } else {
        callback(new Error('Invalid phone'));
      }
    };

    const self = this;

    return {
      validPhone: false,
      categories: [
        {
          category: 'AUTOMOTIVE',
          waCategory: 'AUTO',
          name: this.$t('chat_apps.channels.waes.categories[0]'),
        },
        {
          category: 'BEAUTY',
          waCategory: 'BEAUTY',
          name: this.$t('chat_apps.channels.waes.categories[1]'),
        },
        {
          category: 'CLOTHING',
          waCategory: 'APPAREL',
          name: this.$t('chat_apps.channels.waes.categories[2]'),
        },
        {
          category: 'EDUCATION',
          waCategory: 'EDU',
          name: this.$t('chat_apps.channels.waes.categories[3]'),
        },
        {
          category: 'ENTERTAINMENT',
          waCategory: 'ENTERTAIN',
          name: this.$t('chat_apps.channels.waes.categories[4]'),
        },
        {
          category: 'EVENT_PLANNING',
          waCategory: 'EVENT_PLAN',
          name: this.$t('chat_apps.channels.waes.categories[5]'),
        },
        {
          category: 'FINANCE',
          waCategory: 'FINANCE',
          name: this.$t('chat_apps.channels.waes.categories[6]'),
        },
        {
          category: 'FOOD',
          waCategory: 'GROCERY',
          name: this.$t('chat_apps.channels.waes.categories[7]'),
        },
        {
          category: 'PUBLIC_SERVICE',
          waCategory: 'GOVT',
          name: this.$t('chat_apps.channels.waes.categories[8]'),
        },
        {
          category: 'HOTEL',
          waCategory: 'HOTEL',
          name: this.$t('chat_apps.channels.waes.categories[9]'),
        },
        {
          category: 'HEALTH',
          waCategory: 'HEALTH',
          name: this.$t('chat_apps.channels.waes.categories[10]'),
        },
        {
          category: 'NON_PROFIT',
          waCategory: 'NONPROFIT',
          name: this.$t('chat_apps.channels.waes.categories[11]'),
        },
        {
          category: 'PROFESSIONAL_SERVICES',
          waCategory: 'PROF_SERVICES',
          name: this.$t('chat_apps.channels.waes.categories[12]'),
        },
        {
          category: 'SHOPPING',
          waCategory: 'RETAIL',
          name: this.$t('chat_apps.channels.waes.categories[13]'),
        },
        {
          category: 'TRAVEL',
          waCategory: 'TRAVEL',
          name: this.$t('chat_apps.channels.waes.categories[14]'),
        },
        {
          category: 'RESTAURANT',
          waCategory: 'RESTAURANT',
          name: this.$t('chat_apps.channels.waes.categories[15]'),
        },
        {
          category: 'OTHER',
          waCategory: 'OTHER',
          name: this.$t('chat_apps.channels.waes.categories[16]'),
        },
      ],

      businessDetailsForm: {
        channel: 'WA',
        name: '',
        phone: '',
        phoneNational: '',
        countryCode: '',
        country: '',
        accountName: '',
        category: 'ENTERTAINMENT',
        waCategory: 'ENTERTAIN',
        tagline: '',
        description: '',
        validated: false,
      },

      businessDetailsFormRules: {
        name: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.channel_name'),
            }),
            trigger: 'blur',
          },
        ],

        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: 'blur',
          },
        ],

        accountName: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.business_name') }),
            trigger: 'blur',
          },
        ],

        category: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.category') }),
            trigger: 'blur',
          },
        ],

        tagline: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.tagline') }),
            trigger: 'blur',
          },
        ],

        description: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.description'),
            }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  watch: {
    businessDetailsForm: {
      deep: true,
      handler() {
        const { name, phone, accountName, category, tagline, description } =
          this.businessDetailsForm;

        if (!!name && !!phone && !!accountName && !!category && !!tagline && !!description) {
          this.$refs.businessDetailsForm.validate((valid) => {
            if (valid) {
              this.submit({ validated: true });
            }
            return false;
          });
        } else {
          this.submit({ validated: false });
        }
      },
    },
  },

  methods: {
    countryCodeUpdate(obj) {
      const { iso2, dialCode } = obj;
      this.businessDetailsForm.country = iso2 || this.businessDetailsForm.country;
      this.businessDetailsForm.countryCode = dialCode;
    },

    handlePhoneValidate(n, validator) {
      this.validPhone = validator.isValid;
      if (validator.isValid) {
        this.businessDetailsForm.phoneNational = validator.number.significant;
      }
      this.$refs.businessDetailsForm.validateField('phone');
    },

    handleOnBlurPhone() {
      this.$refs.businessDetailsForm.validateField('phone');
    },

    changeWACategory(category) {
      const cat = this.categories.find((c) => c.category === category);

      this.businessDetailsForm.waCategory = cat.waCategory;
    },

    submit(validated) {
      this.$emit('update', {
        businessDetails: {
          ...this.businessDetailsForm,
          ...validated,
        },
      });
    },
  },
};
</script>

<styles lang="scss" scoped>
.whatsapp-form {
  max-width: 400px;

  .el-form-item.is-error {
    .whatsapp-form__tel-input {
      border: solid 1px tomato !important;
    }
  }
  .el-form-item.is-success {
    .whatsapp-form__tel-input {
      border: solid 1px #67C23A !important;
    }
  }
  .el-form-item__label {
    line-height: 0;
    margin: 20px 0 10px;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0px;
  }

  .note {
    word-break: break-word;
  }
  &__tel-input {
    margin-top: 10px;
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid #DCDFE6 !important;
    box-shadow: none !important;

    .vti__selection {
      height: 30px;
    }

    input {
      font-size: 13px;
      color: #606266;
      border-radius: 4px !important;

      &::placeholder {
        color: #E0E2E5;
        opacity: 1;
      }
    }
  }
}
</styles>
