<template>
  <div>
    <div class="chat-app-logs">
      <el-table v-loading="loading" :data="data" :row-class-name="tableRowClassName">
        <!-- Expand -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="block">
              <div class="flex">
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.umid') }}</p>
                  <p class="text-xs">{{ scope.row.UMID }}</p>
                </div>
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.client_message_id') }}</p>
                  <p v-if="scope.row.ClientMessageId" class="text-xs">
                    {{ scope.row.ClientMessageId }}
                  </p>
                  <p v-else class="text-xs">—</p>
                </div>
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">
                    {{ $t('column_labels.date_delivered') }} {{ timeZone }}
                  </p>
                  <p v-if="scope.row.DeliveredAt" class="text-xs break-words pr-2">
                    {{
                      getLocalDateTimeTz(
                        scope.row.DeliveredAt,
                        timeZoneOffset,
                        timeZone,
                        'MMM DD YYYY HH:mm A'
                      )
                    }}
                  </p>
                  <p v-else class="text-xs">—</p>
                </div>
                <div class="w-1/2">
                  <p class="text-xs mb-2">
                    <span>{{ $t('column_labels.status') }}: </span>
                    <span class="font-bold">{{ scope.row.Status }}</span>
                  </p>
                  <p class="text-xs mb-2">
                    For more information about the different<br />
                    statuses.
                    <a
                      href="https://support.wavecell.com/hc/en-us/articles/115005285643-What-are-the-different-message-statuses-"
                      class="text-red no-underline cursor-pointer hover:text-red-darker"
                      target="_blank"
                    >
                      See Message Statuses
                    </a>
                  </p>
                  <div
                    v-if="Boolean(scope.row.PlatformErrorCode)"
                    :class="`error-codes mt-2 ${showMessageBody ? 'absolute' : ''}`"
                  >
                    <div class="text-xs">
                      Error Code:
                      <span class="font-600">{{ getErrorCode(scope.row) }}</span>
                    </div>
                    <div class="text-xs">
                      Error Description:
                      <span class="font-600">{{ scope.row.CodeDescription || 'Unknown' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex relative mt-5">
                <div
                  v-if="
                    showMessageBody &&
                    scope.row.Direction === 'Outgoing' &&
                    (scope.row.TemplateBody || scope.row.Content)
                  "
                  class="w-1/2 pr-3"
                >
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.content') }}</p>
                  <div v-if="scope.row.CampaignMeta && scope.row.CampaignMeta.match(/amazonaws.com/) && scope.row.Content"> <!-- eslint-disable-line -->
                    <SecuredImage :campaign="scope.row.CampaignMeta" />
                  </div>
                  <div
                    v-else-if="getContentLength(scope.row)"
                    class="inline-block text-xs mb-2 p-2 rounded leading-normal break-normal whitespace-pre-wrap break-words border border-grey-light"
                    :style="{ wordBreak: 'normal' }"
                  >
                    {{
                      messageBody(scope.row.Direction, scope.row.Content || scope.row.TemplateBody)
                    }}
                  </div>
                  <div
                    v-else
                    class="inline-block text-xs mb-2 p-2 rounded leading-normal break-normal whitespace-pre-wrap break-words border border-grey-light"
                    :style="{ wordBreak: 'normal' }"
                  >
                    {{ scope.row.Content || scope.row.TemplateBody }}
                  </div>
                </div>
                <div
                  v-else-if="
                    showMessageBody &&
                    scope.row.Direction === 'Incoming' &&
                    (scope.row.TemplateBody || scope.row.Content)
                  "
                  class="w-1/2 pr-3"
                >
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.content') }}</p>
                  <div v-if="scope.row.CampaignMeta && scope.row.CampaignMeta.match(/amazonaws.com/) && scope.row.Content"> <!-- eslint-disable-line -->
                    <SecuredImage :campaign="scope.row.CampaignMeta" />
                  </div>
                  <div
                    v-else-if="getContentLength(scope.row)"
                    class="inline-block text-xs mb-2 p-2 rounded leading-normal break-normal whitespace-pre-wrap break-words border border-grey-light"
                    :style="{ wordBreak: 'normal' }"
                  >
                    {{
                      messageBody(scope.row.Direction, scope.row.Content || scope.row.TemplateBody)
                    }}
                  </div>
                  <div
                    v-else
                    class="inline-block text-xs mb-2 p-2 rounded leading-normal break-normal whitespace-pre-wrap break-words border border-grey-light"
                    :style="{ wordBreak: 'normal' }"
                  >
                    {{ scope.row.Content || scope.row.TemplateBody }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <!-- Msg direction icon -->
        <el-table-column width="40px">
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.Direction"
              :enterable="false"
              class="help-cursor"
              effect="dark"
              placement="bottom"
            >
              <img :src="getMsgDirectionIcon(scope.row.Direction)" class="block" />
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- SubAccount column -->
        <el-table-column
          v-slot="scope"
          :label="$tc('column_labels.subaccount', 1)"
          show-overflow-tooltip
        >
          <div class="text-xs truncate">{{ scope.row.SubAccountId }}</div>
        </el-table-column>

        <!-- Channel column -->
        <el-table-column
          v-slot="scope"
          :label="$tc('column_labels.channel', 1)"
          show-overflow-tooltip
        >
          <div class="text-xs truncate">{{ scope.row.Channel }}</div>
        </el-table-column>

        <!-- Country column -->
        <el-table-column v-slot="scope" :label="$t('column_labels.country')" show-overflow-tooltip>
          <div class="text-xs truncate">
            {{ regionCodeToName(scope.row.Country) }}
          </div>
        </el-table-column>

        <!-- User ID column -->
        <el-table-column v-slot="scope" :label="$t('column_labels.user_id')" show-overflow-tooltip>
          <div class="text-xs truncate">
            {{ formatPhoneNumber(scope.row.MSISDN, scope.row.ChannelUserId) }}
          </div>
        </el-table-column>

        <!-- Date Sent column -->
        <el-table-column show-overflow-tooltip>
          <template slot="header">
            <el-tooltip
              :enterable="false"
              effect="dark"
              :content="$t('chat_apps.logs.table[0]')"
              placement="bottom"
            >
              <span class="text-xs flex items-center help-cursor">
                {{ $t('column_labels.date_sent') }} {{ timeZone }}
                <i class="el-icon-info ml-1"></i>
              </span>
            </el-tooltip>
          </template>
          <template v-slot="scope">
            <div class="text-xs truncate">
              {{
                getLocalDateTimeTz(
                  scope.row.SentAt,
                  timeZoneOffset,
                  timeZone,
                  'MMM DD YYYY HH:mm A'
                )
              }}
            </div>
          </template>
        </el-table-column>

        <!-- Date Read column -->
        <el-table-column show-overflow-tooltip>
          <template slot="header">
            <span class="text-xs flex items-center help-cursor">
              {{ $t('column_labels.date_read') }} {{ timeZone }}
            </span>
          </template>
          <template v-slot="scope">
            <p v-if="scope.row.ReadAt" class="text-xs truncate">
              {{
                getLocalDateTimeTz(
                  scope.row.ReadAt,
                  timeZoneOffset,
                  timeZone,
                  'MMM DD YYYY HH:mm A'
                )
              }}
            </p>
            <p v-else class="text-xs">—</p>
          </template>
        </el-table-column>

        <!-- Status column -->
        <el-table-column v-slot="scope" :label="$t('column_labels.status')" align="right">
          <div class="text-xs inline-block text-black rounded-sm ca-tag">
            {{ scope.row.Status }}
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'; // eslint-disable-line
import Moment from 'moment';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { mapState } from 'vuex';

import incomingMsgIcon from '@/assets/incoming-msg.svg';
import outgoingMsgIcon from '@/assets/outgoing-msg.svg';
import { transformToHTML } from '@/utils/common';
import timeFormat from '../../../mixins/timeFormat';

import SecuredImage from './SecuredImage.vue';

export default {
  name: 'Table',

  components: {
    SecuredImage,
  },

  mixins: [timeFormat],

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    data: {
      default: () => [],
      type: Array,
    },
    languageList: {
      default: () => [],
      type: Array,
    },
    showMessageBody: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    getErrorCode(row) {
      const { PlatformErrorCode: code } = row;
      return code === 0 ? 0 : code || '-';
    },

    sanitize(text) {
      return _.unescape(this.$sanitize(text));
    },

    getContentLength(row) {
      // eslint-disable-next-line
      return (row.Content || row.TemplateBody).replace(/([a-zA-Z]*)_([a-zA-Z]*)/g, '').length;
    },

    sanitizeText(text) {
      return transformToHTML(this.sanitize(text.trim()))
        .replace(/\n/g, '<p></p>')
        .replace(/<\/p>/g, '\n')
        .replace(/<p>/g, '');
    },

    messageBody(direction, content = '') {
      return this.sanitizeText(content);
    },

    getMsgDirectionIcon(dir) {
      let iconUrl = incomingMsgIcon;
      if (dir.match(/Outgoing/)) {
        iconUrl = outgoingMsgIcon;
      }
      return process.env.VUE_APP_BASE_URL.replace(/\/$/g, '') + iconUrl;
    },
    formatDateTime(dt) {
      return Moment.utc(dt).format('MMM DD YYYY HH:mm A');
    },

    formatPhoneNumber(msisdn, channelUserId = '0') {
      if (!channelUserId) return '';

      const s = msisdn.match(/^[+][0-9]/) ? msisdn : `+${msisdn}`;
      const pn = parsePhoneNumber(s);
      const msisdnFormatted = pn.number.international;

      if (msisdnFormatted) {
        return msisdnFormatted;
      }

      const cuid = channelUserId.match(/^[+][0-9]/) ? channelUserId : `+${channelUserId}`;
      const cuidpn = parsePhoneNumber(cuid);
      const cuidFormatted = cuidpn.number.international;

      return cuidFormatted || channelUserId; // use raw userId since some are not phone numbers
    },

    regionCodeToName(region = '') {
      const s = region || '';
      const country = this.languageList.find((l) => l.value.toLowerCase() === s.toLowerCase());
      return country && s.length ? country.name : region;
    },
  },
};
</script>
<style lang="scss">
.chat-app-logs {
  .error-codes {
    border-radius: 4px;
    background: #f1f1f1;
    max-width: 400px;
    padding: 12px;
    .font-600 {
      font-weight: 600;
    }
  }
}
</style>
<style lang="scss">
.el-table {
  .template-row {
    &--disable {
      color: lightgray;
      background-color: whitesmoke;
      pointer-events: none;
    }
  }

  &__expanded-cell {
    background-color: #f2f5ff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none !important;

    &:hover {
      background-color: #f2f5ff !important;
    }
  }
}

.help-cursor {
  cursor: help;
}

.template-status {
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    margin-right: 5px;
    width: 6px;
    height: 6px;
    border-radius: 999px;
    background-color: gray;
  }

  &--approved {
    &:before {
      background-color: #38c172;
    }
  }

  &--rejected {
    &:before {
      background-color: #e3342f;
    }
  }
}

.template-bubble {
  padding: 5px;
  background: #f2f4f8 !important;
  border: solid 1px #dae1e7 !important;
  width: 250px;

  &__header {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  &__name {
    word-break: break-all;
    font-weight: 600;
  }

  &__text {
    padding: 5px;
    background: white;
    line-height: 1.5;
  }

  & .popper__arrow {
    border-top-color: #dae1e7 !important;

    &:after {
      border-left: 5px solid transparent !important;
      border-right: 5px solid transparent !important;
      border-top: 5px solid #f2f4f8 !important;
    }
  }
}

.ca-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}
</style>
