<template>
  <div>
    <div class="c-table w-full flex flex-col">
      <div class="table__filter w-full flex justify-between">
        <div class="flex">
          <div class="text-black h-full flex justify-center items-center">
            <h2 class="text-base font-semibold">
              {{ titleRangeType() }}
            </h2>
          </div>
        </div>
      </div>
      <div class="mt-5 w-full relative">
        <el-table
          v-loading="loading"
          size="small"
          class="w-full"
          :data="tableData"
          :summary-method="getSummaries"
          show-summary
          fit
        >
          <!-- Week column -->
          <el-table-column v-slot="scope" :label="tableRangeType()" prop="week" fixed>
            <div class="text-xs truncate">
              {{ formatDate(scope.row.date) }}
            </div>
          </el-table-column>

          <!-- Total column -->
          <el-table-column
            v-slot="scope"
            :label="$t('column_labels.total')"
            prop="total"
            align="right"
            fixed="left"
          >
            <div v-if="!scope.row.total" class="text-xs truncate text-grey-light">—</div>
            <div v-else class="text-xs truncate">
              {{ formatNumber(scope.row.total) }}
            </div>
          </el-table-column>

          <template v-if="filters.reportType.match(/whatsapp_conversations/)">
            <el-table-column prop="free" align="right">
              <template slot="header">
                <span>
                  {{ $t('column_labels.free') }}
                  <el-popover placement="top-start" width="300" trigger="hover">
                    <div class="text-xs">{{ $t('chat_apps.reports.free_label') }}.</div>
                    <i slot="reference" class="el-icon-question" />
                  </el-popover>
                </span>
              </template>
              <template slot-scope="scope">
                <span v-if="!scope.row.free" class="text-xs truncate text-grey-light">—</span>
                <span v-else class="text-xs truncate">{{ formatNumber(scope.row.free) }}</span>
              </template>
            </el-table-column>

            <el-table-column
              v-slot="scope"
              :label="$t('column_labels.chargeable')"
              prop="chargeable"
              align="right"
            >
              <div v-if="!scope.row.chargeable" class="text-xs truncate text-grey-light">—</div>
              <div v-else class="text-xs truncate">
                {{ formatNumber(scope.row.chargeable) }}
              </div>
            </el-table-column>

            <!-- Authentication column -->
            <el-table-column
              v-if="filters.metric.match(/all|authentication/i)"
              v-slot="scope"
              :label="$t('chat_apps.categories.Authentication')"
              prop="authentication"
              align="right"
            >
              <div v-if="!scope.row.authentication" class="text-xs truncate text-grey-light">—</div>
              <div v-else class="text-xs truncate">
                {{ formatNumber(scope.row.authentication) }}
              </div>
            </el-table-column>

            <!-- Marketing column -->
            <el-table-column
              v-if="filters.metric.match(/all|marketing/i)"
              v-slot="scope"
              :label="$t('chat_apps.categories.Marketing')"
              prop="marketing"
              align="right"
            >
              <div v-if="!scope.row.marketing" class="text-xs truncate text-grey-light">—</div>
              <div v-else class="text-xs truncate">
                {{ formatNumber(scope.row.marketing) }}
              </div>
            </el-table-column>

            <!-- Marketing column -->
            <el-table-column
              v-if="filters.metric.match(/all|utility/i)"
              v-slot="scope"
              :label="$t('chat_apps.categories.Utility')"
              prop="utility"
              align="right"
            >
              <div v-if="!scope.row.utility" class="text-xs truncate text-grey-light">—</div>
              <div v-else class="text-xs truncate">
                {{ formatNumber(scope.row.utility) }}
              </div>
            </el-table-column>

            <!-- serivce column -->
            <el-table-column
              v-if="filters.metric.match(/all|service/i)"
              v-slot="scope"
              :label="$t('column_labels.service')"
              prop="service"
              align="right"
            >
              <div v-if="!scope.row.service" class="text-xs truncate text-grey-light">—</div>
              <div v-else class="text-xs truncate">
                {{ formatNumber(scope.row.service) }}
              </div>
            </el-table-column>
          </template>

          <!-- Channels for By channel -->
          <template v-else>
            <!-- Total message-->
            <el-table-column
              v-for="channel in channels"
              :key="channel.channelCode"
              v-slot="scope"
              :label="getChannelName(channel.channelCode)"
              :prop="channel.channelCode"
              align="right"
            >
              <div v-if="!scope.row[channel.channelCode]" class="text-xs truncate text-grey-light">
                —
              </div>
              <div v-else class="text-xs truncate">
                {{ formatNumber(scope.row[channel.channelCode]) }}
              </div>
            </el-table-column>
          </template>

          <!-- WhatsApp Read Rate column -->
          <el-table-column
            v-if="filters.metric === 'outgoing' && filters.channels.includes('WA')"
            v-slot="scope"
            :label="$t('column_labels.read_rate')"
            prop="readRate"
            align="right"
          >
            <div v-if="!scope.row.readRate" class="text-xs truncate text-grey-light">—</div>
            <div v-else class="text-xs truncate">
              {{ toPercentString(scope.row.readRate) }}
            </div>
          </el-table-column>

          <!-- Delivery Rate column -->
          <el-table-column
            v-if="filters.metric === 'outgoing'"
            v-slot="scope"
            :label="$t('column_labels.delivery_rate')"
            prop="deliveryRate"
            align="right"
            fixed="right"
          >
            <div v-if="!scope.row.deliveryRate" class="text-xs truncate text-grey-light">—</div>
            <div v-else class="text-xs truncate">
              {{ toPercentString(scope.row.deliveryRate) }}
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="text-right c-pagination">
      <el-pagination
        :current-page.sync="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="data.length"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import channelOptions from '@/json/channels.json';

export default {
  name: 'Table',

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    data: {
      default: () => [],
      type: Array,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    channels: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      currency: '€', // Default currency

      channelOptions,

      page: 1,
      pageSize: 31,
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    tableData() {
      const results = this.data;

      const start = this.page * this.pageSize - this.pageSize;
      const end = start + this.pageSize;

      return results.slice(start, end);
    },
  },

  watch: {
    filters() {
      this.page = 1;
    },
  },

  methods: {
    handleCurrentChange(page) {
      this.page = Number(page);
    },

    tableRangeType() {
      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];
      return Moment(start).isSame(end, 'days')
        ? this.$t('column_labels.time')
        : `${this.$t('column_labels.date')} ${this.timeZone}`;
    },

    titleRangeType() {
      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];
      return Moment(start).isSame(end, 'days')
        ? this.$t('chat_apps.reports.table[0]')
        : this.$t('chat_apps.reports.table[1]');
    },

    getChannelName(code) {
      const channel = this.channelOptions.find((c) => c.code === code);
      return channel ? channel.name : code;
    },

    formatDate(dt) {
      let d = '';
      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];
      const ddt = Moment.utc(dt);
      if (!Moment(start).isSame(end, 'days')) {
        if (Moment().isSame(ddt, 'day')) {
          d = this.$t('date_labels.today');
        } else {
          d = ddt.format('MMM DD YYYY');
        }
      } else {
        d = ddt.format('HH:ss');
      }
      return d;
    },

    // Get table summary
    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      const totalColumnValues = this.data.map((item) => item.total);
      const totalSummary = this.data.reduce((acc, curr) => acc + Number(curr.total), 0);

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('app_labels.summary');
          return;
        }
        const values = this.data.map((item) => Number(item[column.property]));
        // If column values are numbers then compute the sum
        if (!values.every((value) => Number.isNaN(value))) {
          if (!column.property.match(/deliveryRate|readRate/g)) {
            const sum = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!Number.isNaN(value)) {
                return prev + curr;
              }
              return prev;
            }, 0);
            sums[index] = this.formatNumber(sum);
          } else {
            // Calculate Weighted Average for READ RATE AND DELIVERY RATE Columns
            const sum = values.reduce((prev, curr, i) => {
              const value = Number(curr);
              const total = totalColumnValues[i];
              if (!Number.isNaN(value)) {
                const weightedValue = total * value;
                return prev + weightedValue;
              }
              return prev;
            }, 0);
            sums[index] = sum ? this.toPercentString(sum / totalSummary) : 0;
          }
        } else {
          sums[index] = 0;
        }
      });
      return sums;
    },

    // Add % to the end of the number
    toPercentString(n) {
      return `${n.toFixed(n % 1 ? 2 : 0)}%`;
    },

    // Format number e.g: 1000 - 1,000
    formatNumber(n) {
      return n
        .toFixed(n % 1 ? 2 : 0)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },
};
</script>

<style lang="scss">
.c-table {
  &__filter {
  }

  &__dropdown {
    min-width: 150px;
  }
}

.c-pagination {
  background-color: #e4edff;

  .el-pagination {
    padding-top: 0;
    padding-bottom: 0;

    button:disabled,
    button,
    ul > li {
      background-color: #f2f5ff;
    }

    button,
    ul > li {
      color: #8795a1;
    }

    button:disabled {
      color: #cccccc;
    }

    button,
    ul > li,
    span {
      height: 36px;
    }

    span,
    ul > li {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    ul > li.active {
      background: white;
    }
  }
}

th .initiated {
  color: #f6993f !important;
}
</style>
