<template>
  <div class="flex w-full relative">
    <div class="w-full flex flex-col">
      <h2 class="text-black text-sm font-semibold">{{ $t('column_labels.message_delivered') }}</h2>
      <p class="pt-1 text-grey-dark text-xs">{{ $t('column_labels.by_channel') }}</p>
      <div class="mt-5 ml-5">
        <ToggleChartType @toggle-chart-type="toggleChartType" />
      </div>
      <div class="chart-container chart-container--full mt-5">
        <LineChart
          :chart-data="getGraphData()"
          :options="chartOption"
          class="w-full h-full absolute pin-t pin-l"
        />
      </div>
      <!-- <div class="flex justify-between text-xs mt-2">
        <span>{{ formatDateLabel().start }}</span>
        <span>{{ formatDateLabel().end }}</span>
      </div> -->
    </div>
    <div class="mx-10">
      <!-- <div class="text-sm ml-6 text-grey-dark mb-1">{{ $t('column_labels.total') }}</div>
      <div class="ml-6  text-2xl font-semibold mb-3">
        {{ toDecimalString(getTotalAllChannels(messagesPerChannel)) }}
      </div> -->
      <div v-for="(channel, i) in messagesPerChannel" :key="i" class="flex items-center my-5">
        <svg class="trend">
          <polygon
            points="0,10 0,20 20,20 20,0"
            :style="{ fill: getChannelColor(channel.channelCode) }"
          />
        </svg>
        <div class="block ml-3">
          <p class="text-sm text-grey-dark">{{ getChannelName(channel.channelCode) }}</p>
          <p class="mt-1 text-sm text-black font-semibold">
            {{ toDecimalString(channel.totalSent) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import LineChart from '@/components/common/LineChart.vue';
import { getCommonChartConfig } from '@/utils/common';
import ToggleChartType from '@/components/common/ToggleChartType.vue';

export default {
  name: 'SentChart',

  components: {
    LineChart,
    ToggleChartType,
  },

  props: {
    chartData: {
      default: () => [],
      type: Array,
    },
    messagesPerChannel: {
      default: () => [],
      type: Array,
    },
    channelList: {
      default: () => [],
      type: Array,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    decimalStringFormat: {
      default: () => {},
      type: Function,
    },
  },

  data() {
    return {
      chartOption: {
        ...getCommonChartConfig(),
      },
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    toggleChartType(type) {
      if (type === 'linear') {
        this.chartOption = { ...getCommonChartConfig() };
      } else if (type === 'logic_scale') {
        this.chartOption = { ...getCommonChartConfig(true) };
      }
    },

    getTotalAllChannels(msgsPerChannel = {}) {
      if (Object.keys(msgsPerChannel).length) {
        return Object.keys(msgsPerChannel).reduce(
          (a, b) => a + parseInt(msgsPerChannel[b].totalSent, 10),
          0
        );
      }
      return 0;
    },

    formatDateLabel() {
      const days = this.dateRange();
      const startDate = days[0];
      const endDate = days[days.length - 1];

      if (this.filters.daterange <= 0) {
        return {
          start: Moment(startDate).format(`MMMM DD, YYYY HH:mm ${this.timeZone}`),
          end: Moment(endDate).format(`MMMM DD, YYYY HH:mm ${this.timeZone}`),
        };
      }
      return {
        start: Moment(startDate).format(`MMMM DD, YYYY ${this.timeZone}`),
        end: Moment(endDate).format(`MMMM DD, YYYY ${this.timeZone}`),
      };
    },

    formatThousand(n) {
      return n >= 1000 ? (n / 1000).toFixed(n % 1 ? 2 : 0) : n;
    },

    isSameHour(d1, d2) {
      return Moment(d1).utc().unix() === Moment(d2).utc().unix();
    },

    mergeHourValue(days, dataset) {
      return days.map((date) => {
        const total = dataset
          .filter((d) => this.isSameHour(date, d.date))
          .reduce((acc, curr) => acc + curr.value || 0, 0);
        return total;
      });
    },

    generateDays(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('day').format(format));
      } while (from.add(1, 'days').diff(to) < 0);
      return range;
    },

    generateHours(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('hour').format(format));
      } while (from.add(1, 'hours').diff(to) < 0);
      return range;
    },

    anchorDays(dayOffset) {
      let from = null;
      let to = null;
      let format = 'MMM D (ddd)';

      switch (dayOffset) {
        // Today
        case -1:
          from = Moment().utc().startOf('day');
          to = Moment().utc().endOf('day');
          format = 'hA';
          break;
        // 7 days
        case 7:
          from = Moment().utc().subtract(1, 'week').startOf('day');
          to = Moment().utc();
          break;
        // 14 days
        case 14:
          from = Moment().utc().subtract(2, 'week').startOf('day');
          to = Moment().utc();
          format = 'MMM D';
          break;
        // 30 days
        case 30:
          from = Moment().utc().subtract(30, 'days').startOf('day');
          to = Moment().utc();
          format = 'MMM D';
          break;
        default:
          break;
      }
      return { from, to, format };
    },

    dateRangeWithTz() {
      const dr = this.dateRange();

      return dr.map((d) => `${d} ${this.timeZone}`);
    },

    dateRange() {
      const dayOffset = this.filters.daterange;
      const range = this.anchorDays(dayOffset);
      const isDay = Moment(range.from).utc().isSame(Moment(range.to).utc(), 'days');
      const days = isDay
        ? this.generateHours(range.from, range.to, range.format)
        : this.generateDays(range.from, range.to, range.format);
      return days;
    },

    getChannelName(code) {
      const channel = this.channelList.find((c) => c.code === code);
      return channel !== undefined ? channel.name : '';
    },

    populateData() {
      const cd = this.chartData;

      const dayOffset = this.filters.daterange;
      const range = this.anchorDays(dayOffset);
      const isDay = Moment(range.from).utc().isSame(Moment(range.to).utc(), 'days');
      const days = isDay
        ? this.generateHours(range.from, range.to)
        : this.generateDays(range.from, range.to);

      return Object.keys(cd).map((code) => {
        if (!!this.filters.channel && this.filters.channel !== code) {
          return {};
        }
        // Fill-in missing dates
        let d = [];

        if (isDay) {
          // If hourly
          d = this.mergeHourValue(days, cd[code].data);
        } else {
          // If daily
          days.forEach((day) => {
            // Check if day has data
            const dayData = cd[code].data.find((dd) =>
              Moment(dd.date).utc().isSame(Moment(day).utc(), 'day')
            );

            d.push(dayData ? dayData.value : 0);
          });
        }

        return {
          label: this.getChannelName(code),
          data: d,
          borderWidth: 2,
          radius: 3,
          pointHoverRadius: 3,
          borderColor: this.getChannelColor(code),
          backgroundColor: this.getChannelColor(code, 0),
          pointBackgroundColor: this.getChannelColor(code),
          pointBorderColor: this.getChannelColor(code),
          spanGaps: true,
          lineTension: 0,
        };
      });
    },

    getChannelColor(code, opacity = 1) {
      let rgba = 'rgba(14, 0, 121, 0.7)';
      switch (code) {
        case 'WA':
          rgba = `rgba(58, 220, 73, ${opacity})`;
          break;
        case 'WC':
          rgba = `rgba(1, 164, 24, ${opacity})`;
          break;
        case 'FB':
          rgba = `rgba(43, 134, 255, ${opacity})`;
          break;
        case 'GG':
          rgba = `rgba(128,97,94, ${opacity})`;
          break;
        case 'IM':
          rgba = `rgba(131, 139, 152, ${opacity})`;
          break;
        case 'LN':
          rgba = `rgba(114, 187, 83, ${opacity})`;
        case 'LO':
          rgba = `rgba(114, 187, 83, ${opacity})`;
          break;
        case 'VB':
          rgba = `rgba(134, 75, 178, ${opacity})`;
          break;
        case 'ZN':
          rgba = `rgba(58, 121, 216, ${opacity})`;
          break;
        case 'ZL':
          rgba = `rgba(58, 121, 216, ${opacity})`;
          break;
        case 'SM':
          rgba = `rgba(235,81,112, ${opacity})`;
          break;
        case 'KK':
          rgba = `rgba(255, 222, 4, ${opacity})`;
          break;
        default:
          break;
      }
      return rgba;
    },

    getGraphData() {
      return {
        labels: this.dateRange(),
        datasets: this.populateData(),
      };
    },

    toDecimalString(n) {
      return this.decimalStringFormat(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.trend {
  width: 20px;
  height: 20px;
  display: block;
}
.chart-container {
  width: 100%;
  flex-grow: 0;
  height: 200px;
  position: relative;

  &--full {
    min-height: 300px;
    height: 100%;
  }

  &--large {
    height: 300px;
  }

  &--small {
    height: 130px;
  }
}
</style>
