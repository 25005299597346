<template>
  <div class="whatsapp-form">
    <el-form
      ref="contactInfoForm"
      :model="contactInfoForm"
      :rules="contactInfoFormRules"
      class="pt-4"
      label-position="top"
    >
      <el-form-item prop="email" :label="$t('chat_apps.channels.whatsapp[3]')">
        <el-input
          v-model="contactInfoForm.email"
          placeholder="Ex. contact@my-company.com"
          size="small"
        />
      </el-form-item>

      <el-form-item prop="websiteUrl" :label="$t('column_labels.website_url')">
        <el-input
          v-model="contactInfoForm.websiteUrl"
          placeholder="https://www.my-company.com"
          size="small"
        />
      </el-form-item>

      <el-form-item prop="street" :label="$t('column_labels.business_address')">
        <el-input
          v-model="contactInfoForm.street"
          :placeholder="$t('fields.street')"
          size="small"
          maxlength="40"
          show-word-limit
        />
      </el-form-item>

      <el-form-item prop="city">
        <el-input
          v-model="contactInfoForm.city"
          :placeholder="$t('fields.city')"
          size="small"
          maxlength="30"
          show-word-limit
        />
      </el-form-item>

      <el-form-item prop="state">
        <el-input
          v-model="contactInfoForm.state"
          :placeholder="$t('fields.state')"
          size="small"
          maxlength="30"
          show-word-limit
        />
      </el-form-item>

      <div class="flex">
        <div class="w-3/5 mr-5">
          <el-form-item prop="country">
            <el-select
              v-model="contactInfoForm.country"
              :placeholder="$t('fields.country')"
              class="w-full"
              size="small"
              filterable
            >
              <el-option
                v-for="country in countriesJson"
                :key="country.value"
                :label="country.name"
                :value="country.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="w-2/5">
          <el-form-item prop="zipcode">
            <el-input
              v-model="contactInfoForm.zipcode"
              :placeholder="$t('fields.zip')"
              size="small"
              step="0"
            />
          </el-form-item>
        </div>
      </div>

      <el-form-item prop="timezone" :label="$t('date_labels.timezone')">
        <el-select
          v-model="contactInfoForm.timezone"
          :placeholder="$t('date_labels.pick_time_zone')"
          filterable
          size="small"
        >
          <el-option
            v-for="tz in timeZones"
            :key="tz.name"
            :label="`${tz.utcOffsetStr} (${tz.name})`"
            :value="`${tz.utcOffsetStr} (${tz.name})`"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        class="profile-picture"
        prop="profilePicture"
        :label="$t('column_labels.profile_pic')"
      >
        <el-upload
          ref="uploader"
          drag
          action=""
          auto-upload="false"
          accept="image/*"
          :limit="1"
          :on-exceed="handleExceed"
          :before-upload="beforeUpload"
          :http-request="onUploadRequest"
        >
          <span class="icon-novo-folder-open text-2xl" />
          <div class="el-upload__text text-xs">
            Click to upload or drag and drop your image here.
          </div>
          <div slot="tip" class="el-upload__tip text-xs break-words" :style="{ color: '#858585' }">
            Image width and height must be greater than 192 pixels.
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import countriesJson from '@/json/countries.json';
import ct from 'countries-and-timezones';

export default {
  name: 'ContactInformation',

  props: {
    setupData: {
      type: Object,
      default: () => {},
    },

    update: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const self = this;

    return {
      validPhone: false,
      countriesJson,
      timeZones: null,

      contactInfoForm: {
        email: '',
        websiteUrl: '',
        street: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        timezone: 'UTC+00 (UTC)',
        profilePicture: null,
      },

      contactInfoFormRules: {
        email: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.email') }),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: self.$t('validations.valid', { value: self.$t('fields.email') }),
            trigger: ['blur', 'change'],
          },
        ],

        websiteUrl: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.website_url'),
            }),
            trigger: 'blur',
          },
          {
            pattern:
              /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            message: self.$t('validations.valid', { value: self.$t('column_labels.website_url') }),
            trigger: 'blur',
          },
        ],

        street: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.street'),
            }),
            trigger: 'blur',
          },
        ],

        city: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.city'),
            }),
            trigger: 'blur',
          },
        ],

        state: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.state'),
            }),
            trigger: 'blur',
          },
        ],

        address1: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.address_line', { no: '1' }),
            }),
            trigger: 'blur',
          },
        ],

        address2: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.address_line', { no: '2' }),
            }),
            trigger: 'blur',
          },
        ],

        country: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.country') }),
            trigger: 'blur',
          },
        ],

        zipcode: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.zip') }),
            trigger: 'blur',
          },
        ],

        timezone: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('date_labels.timezone') }),
            trigger: 'blur',
          },
        ],

        profilePicture: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.avatar_url') }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  watch: {
    contactInfoForm: {
      deep: true,
      handler() {
        const {
          email,
          websiteUrl,
          profilePicture,
          street,
          city,
          state,
          country,
          zipcode,
          timezone,
        } = this.contactInfoForm;

        if (
          !!email &&
          !!websiteUrl &&
          !!profilePicture &&
          !!street &&
          !!city &&
          !!state &&
          !!country &&
          !!zipcode &&
          !!timezone
        ) {
          this.$refs.contactInfoForm.validate((valid) => {
            if (valid) {
              this.submit({ validated: true });
            }

            return false;
          });
        } else {
          this.submit({ validated: false });
        }
      },
    },
  },

  created() {
    const allTz = ct.getAllTimezones();

    if (!this.timeZones) {
      this.timeZones = Object.values(allTz).sort((a, b) => a.utcOffset - b.utcOffset);
    }
  },

  methods: {
    submit(validated) {
      this.$emit('update', {
        contactInfo: {
          ...this.contactInfoForm,
          ...validated,
        },
      });
    },

    onUploadRequest(fileObj) {
      this.contactInfoForm.profilePicture = fileObj.file;
      fileObj.onSuccess();
    },

    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        // Check if the file is an image
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
          this.$message.error('File is not an image.');
          reject();
          return;
        }

        // Check if the file dimensions adhere to Meta's standard dimensions
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (img.width <= 192 || img.height <= 192) {
              this.$message.error('Image width and height must be greater than 192 pixels.');
              reject();
            } else {
              resolve(file);
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        'You can only upload one file at a time. Remove the current file and upload again.'
      );
    },
  },
};
</script>

<styles lang="scss" scoped>
.whatsapp-form {
  max-width: 400px;

  .el-form-item.is-error {
    .whatsapp-form__tel-input {
      border: solid 1px tomato !important;
    }
  }
  .el-form-item.is-success {
    .whatsapp-form__tel-input {
      border: solid 1px #67C23A !important;
    }
  }
  .el-form-item__label {
    line-height: 0;
    margin: 20px 0 10px;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0px;
  }

  .note {
    word-break: break-word;
  }
  &__tel-input {
    margin-top: 10px;
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid #DCDFE6 !important;
    box-shadow: none !important;

    .vti__selection {
      height: 30px;
    }

    input {
      font-size: 13px;
      color: #606266;
      border-radius: 4px !important;

      &::placeholder {
        color: #E0E2E5;
        opacity: 1;
      }
    }
  }

  .el-upload {
    width: 100%;
    margin-top: 10px;
  }

  .el-upload-dragger {
    width: auto;
    height: fit-content;
    padding: 20px;
  }

  .el-upload-dragger .el-upload__text {
    line-height: 40px;
  }

  .el-upload__tip {
    margin-top: 15px;
  }

  .profile-picture {
    .el-form-item__content {
      line-height: 0 !important;
    }
  }
}
</styles>
