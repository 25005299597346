import mimeTypes from 'mime-types';
import http from '@/utils/http';
import axios from 'axios';
// import { saveAs } from 'file-saver';

export default {
  // eslint-disable-next-line no-unused-vars
  downloadFile({ commit }, url) {
    window.open(url);
  },
  // eslint-disable-next-line no-unused-vars
  // saveFile({ commit }, url) {
  //   saveAs(url);
  // },

  async getUploadUrl({ commit }, payload) {
    const { file } = payload;
    const slug = 'files/upload-url';

    // sometimes mimetype is empty or unknown
    const fallBackMimeType = mimeTypes.lookup(file.name) || 'application/octet-stream';
    const mimeType = file.type ? file.type : fallBackMimeType;

    const q = encodeURI(`filename=${file.name}&type=${mimeType}`);

    try {
      const { data: signed } = await http.get(`/${slug}?${q}`);

      const { url: uploadUrl, db_url: dbUrl } = signed;

      return {
        uploadUrl,
        dbUrl,
        mimeType,
      };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async request({ commit }, payload) {
    const { method, url, data, headers } = payload;
    try {
      await http.request({
        method,
        url,
        data,
        headers,
        transformRequest: [
          (d, h) => {
            // eslint-disable-next-line no-param-reassign
            if (h.Authorization) {
              // eslint-disable-next-line no-param-reassign
              delete h.Authorization;
            }

            return d;
          },
        ],
      });

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async downloadFileUrl({ commit }, payload = {}) {
    const { fileUrl } = payload;
    const jwt = localStorage.getItem('WWW-Authenticate');

    const response = await axios.get(fileUrl, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    return response;
  },
};
