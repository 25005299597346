export default {
  methods: {
    sendAmplitudeEvent(eventName, data) {
      try {
        const { track } = this.$telemetry;
        track(eventName, data);
      } catch (err) {
        console.error(`Error in sending the amplitude event: ${err}`);
      }
    },
  },
};
