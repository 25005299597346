<template>
  <div
    class="channel-card bg-white shadow w-full block p-3 text-center rounded"
    :class="[{ 'channel-card__disabled': !getChannelInfo(channelCode).supported }]"
    @click="openModal"
  >
    <img :src="getIcon(channelCode)" width="25" height="25px" alt="channel-icon" />
    <p class="mt-2 text-xs text-black">{{ getChannelName(channelCode) }}</p>
    <p v-if="getChannelInfo(channelCode).supported" class="text-xs text-blue mt-2">
      {{
        channelCode === 'WA' ? $t('chat_apps.channels.card[2]') : $t('chat_apps.channels.card[0]')
      }}
    </p>
    <p v-else class="text-xs text-grey-dark mt-2">{{ $t('chat_apps.channels.card[1]') }}</p>
  </div>
</template>

<script>
import channelsJson from '@/json/channels.json';

import whatsAppIcon from '@/assets/channels/whatsapp.svg';
import weChatIcon from '@/assets/channels/wechat.svg';
import facebookIcon from '@/assets/channels/facebook.svg';
import googleRcsIcon from '@/assets/channels/googlercs.svg';
import messengerIcon from '@/assets/channels/appleimessage.svg';
import lineIcon from '@/assets/channels/line.svg';
import viberIcon from '@/assets/channels/viber.svg';
import zaloIcon from '@/assets/channels/zalo.svg';
import smsIcon from '@/assets/channels/sms.svg';
import kakaoIcon from '@/assets/channels/kakao.svg';

export default {
  name: 'ChannelCard',

  props: {
    channelCode: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      channelsJson,
    };
  },

  methods: {
    getIcon(code) {
      let icon = '';
      switch (code) {
        case 'WA':
          icon = whatsAppIcon;
          break;
        case 'WC':
          icon = weChatIcon;
          break;
        case 'FB':
          icon = facebookIcon;
          break;
        case 'GG':
          icon = googleRcsIcon;
          break;
        case 'IM':
          icon = messengerIcon;
          break;
        case 'LN':
          icon = lineIcon;
          break;
        case 'LO':
          icon = lineIcon;
          break;
        case 'VB':
          icon = viberIcon;
          break;
        case 'ZL':
          icon = zaloIcon;
          break;
        case 'ZN':
          icon = zaloIcon;
          break;
        case 'SM':
          icon = smsIcon;
          break;
        case 'KK':
          icon = kakaoIcon;
          break;
        default:
          break;
      }
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    getChannelInfo(code) {
      return this.channelsJson.find((c) => c.code === code);
    },

    getChannelName(code) {
      return this.channelsJson.find((c) => c.code === code).name;
    },

    openModal() {
      this.$emit('open-channel-form', this.channelCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-card {
  cursor: pointer;
  transition: all 0.05s ease-in;
  border: solid 1px transparent;

  &:hover {
    border-color: #3490dc;
    transform: scale(1.03);
    transform-origin: center;
  }

  &__disabled {
    cursor: default;

    &:hover {
      border-color: #dae1e7;
      opacity: 0.7;
      transform: none;
      filter: grayscale(100%);
    }
  }
}
</style>
