/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    filterEnums: {},
    subaccounts: [],
    waChannels: [],
    user: {},
    hasSmsAccess: false,
    hasChatAppsAccess: false,
    hasSmsEngageAccess: false,
    hasVoiceAccess: false,
    showMessageBody: false,

    timeZoneName: null,
    timeZoneOffset: 0,
    timeZone: '(UTC-00)',
    isTimeZoneAllowed: false,
  },

  getters: {
    // Get WhatsApp channels
    waChannels: (st) => st.waChannels,

    // Get filter enums
    enums: (st) => st.filterEnums,

    // Get the filter option based on the object key
    // s is custom label; e.g. 'All foo bar statuses' will be the label for default/all value
    getFilterList: (st) => (key, s) => {
      let list = [];
      const defaultFilterList = [
        {
          name: s || `All ${key}`,
          value: '',
        },
      ];

      if (!st.filterEnums || typeof st.filterEnums !== 'object') {
        return defaultFilterList;
      }

      if (Object.keys(st.filterEnums).length) {
        let obj = st.filterEnums[key];

        if (key === 'category') {
          obj = {
            AUTHENTICATION: 'Authentication',
            MARKETING: 'Marketing',
            UTILITY: 'Utility',
          };
        }

        list = Object.keys(obj).map((o) => ({
          name: obj[o],
          value: o,
        }));
      }
      return [...defaultFilterList, ...list];
    },

    // Get subaccounts
    getSubaccountsList: (st) => {
      let subaccs = [];
      if (st.subaccounts.length) {
        subaccs = st.subaccounts.map((s) => ({
          name: s.SubAccountId,
          value: s.SubAccountUid,
        }));
      }
      return subaccs;
    },

    getUser: (st) => st.user,
    getSmsAccess: (st) => st.hasSmsAccess,
    getChatAppsAccess: (st) => st.hasChatAppsAccess,
    getSmsEngageAccess: (st) => st.hasSmsEngageAccess,
    getVoiceAccess: (st) => st.hasVoiceAccess,
    getMessageBodyAccess: (st) => st.showMessageBody,
    getSubAccounts: (st) => st.subaccounts,
  },

  mutations: {
    SET_FILTER_ENUMS: (st, payload) => {
      st.filterEnums = payload.enums;
    },
    SET_SUBACCOUNTS: (st, payload) => {
      st.subaccounts = payload.subaccounts;
    },
    SET_WA_CHANNELS: (st, payload) => {
      st.waChannels = payload.channels;
    },
    SET_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.user = payload.user;

      const {
        Product_SMS: hasSMSAccess,
        Product_CA: hasCAAccess,
        Product_VO: hasVoiceAccess,
        SmsToSurveyEnabled: hasSurvey,
        HasSubAccountsWithSurveyForms: hasSurveyForms,
        BetaFeatures: betaFeatures,
        Roles: userRoles,
        TimeZoneName,
        TimeZoneGMTOffset,
      } = payload.user;

      state.hasSmsAccess = hasSMSAccess;
      state.hasChatAppsAccess = hasCAAccess;
      state.hasVoiceAccess = hasVoiceAccess && betaFeatures.includes('VO-Messaging');
      state.showMessageBody = userRoles.includes('CA.ShowMessageBody_v2');
      state.hasSmsEngageAccess = hasSMSAccess && hasSurvey && hasSurveyForms;
      state.isTimeZoneAllowed = true;

      // User timezone settings
      state.timeZoneName = TimeZoneName || 'UTC / Greenwich Mean Time'; // eslint-disable-next-line
      state.timeZoneOffset = TimeZoneGMTOffset ? (TimeZoneGMTOffset / 60) : 0; // offset should be in minutes
      let timeZone = '(UTC-00)';
      if (TimeZoneGMTOffset) {
        const offset = new Date(TimeZoneGMTOffset * 1000)
          .toUTCString()
          .split(' ')[4]
          .replace(/:00$/, '');
        let prefix = '-';
        if (TimeZoneGMTOffset > 0) {
          prefix = '+';
        }
        timeZone = `(UTC${prefix}${offset})`;
      }
      state.timeZone = timeZone;
    },
  },

  actions,
};
