<!-- eslint-disable max-len -->
<template>
  <div class="summary w-full overflow-hidden">
    <!-- Charts -->
    <section class="relative">
      <div class="summary__chart w-full">
        <!-- Channels chart -->
        <ChannelChart
          v-if="!filters.reportType.match(/whatsapp_conversations/)"
          :chart-data="data"
          :filters="filters"
        />
        <!-- Chargable chart -->
        <ChargableChart v-else :chart-data="data" :filters="filters" :chargables="chargables" />
      </div>
      <!-- <div class="flex justify-between text-xs mt-2">
        <span>{{ formatDateLabel(filters.daterange[0], true) }}</span>
        <span>{{ formatDateLabel(filters.daterange[1]) }}</span>
      </div> -->
    </section>

    <!-- SNS filter -->
    <section class="summary__chart-filter py-4 px-6 mt-10">
      <div class="summary__checkbox-container w-full overflow-y-auto">
        <!-- Free vs Charged radio group -->
        <template v-if="filters.reportType.match(/whatsapp_conversations/i)">
          <div class="flex">
            <div
              v-for="(chargable, i) in availableCheckbox"
              :key="i"
              class="border-solid border px-4 py-2 rounded hover:shadow w-10rem hover:border-red-light ml-3"
              role="button"
              tabindex="0"
              :class="{
                'border-red-light text-black': chargableSelected.includes(chargable.value),
                'text-orange':
                  ['businessInitiated', 'customerInitiated'].includes(chargable.value) &&
                  chargableSelected.includes(chargable.value),
                'border-grey-light text-grey': !chargableSelected.includes(chargable.value),
              }"
              @click="selectChargeable(chargable.value)"
            >
              <div class="flex items-center">
                <svg class="summary__sns-legend mr-2">
                  <rect
                    width="8"
                    height="8"
                    rx="2"
                    :style="{
                      fill: chargableSelected.includes(chargable.value)
                        ? getChargableColor(chargable.value)
                        : '#dae1e7',
                    }"
                  />
                </svg>
                <p class="text-xs font-semibold text-center">
                  {{ chargable.name }}
                </p>
              </div>
              <p class="text-sm text-center font-bold mt-1 text-black">
                {{ toDecimalString(data[chargable.value] ? data[chargable.value].total : 0) }}
              </p>
            </div>
          </div>
        </template>
        <!-- Channels checkbox group -->
        <template v-else>
          <div class="flex">
            <div
              v-for="channel in channels"
              :key="channel.channelCode"
              class="border-solid border px-4 py-2 rounded hover:shadow hover:border-red-light ml-3 cursor-pointer"
              role="button"
              tabindex="0"
              :class="{
                'border-red-light text-black': channelsSelected.includes(channel.channelCode),
                'border-grey-light text-grey': !channelsSelected.includes(channel.channelCode),
              }"
              @click="selectChannel(channel.channelCode)"
            >
              <div class="flex items-center">
                <svg class="summary__sns-legend mr-2">
                  <rect
                    width="12"
                    height="12"
                    rx="2"
                    :style="{
                      fill: channelsSelected.includes(channel.channelCode)
                        ? getChannelColor(channel.channelCode)
                        : '#dae1e7',
                    }"
                  />
                </svg>
                <p class="text-xs font-semibold">
                  {{ getChannelName(channel.channelCode) }}
                </p>
              </div>
              <p class="text-sm font-bold mt-1 text-center">
                {{ toDecimalString(channel.totalSent) }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </section>
    <div
      v-if="filters.reportType.match(/whatsapp_conversations/)"
      class="mt-3 mb-2 text-black text-xs p-3 bg-yellow-lightest border border-solid border-yellow rounded w-full leading-normal break-words-p"
    >
      Effective June 1, 2024, WhatsApp introduces new “Authentication-International” rates for
      authentication messages in specific countries. This update affects businesses sending
      authentication messages to users in those countries. Click
      <a
        href="https://www.8x8.com/cpaas/blog/whatsApp-authentication-rates-Indonesia-India"
        class="text-blue"
        target="_blank"
        rel="noopener nofollow"
      >
        here</a
      >
      to learn more.
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import channelOptions from '@/json/channels.json';
import ChannelChart from './ChannelChart.vue';
import ChargableChart from './ChargableChart.vue';

import { formatLocalizedNumber } from '../../../utils/common';

export default {
  name: 'Summary',

  components: {
    ChannelChart,
    ChargableChart,
  },

  props: {
    data: {
      default: () => {},
      type: Object,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    channels: {
      default: () => [],
      type: Array,
    },
    chargables: {
      default: () => [],
      type: Array,
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    availableCheckbox() {
      return this.chargables.filter(
        (c) => this.filters.metric.includes(c.value) || this.filters.metric === 'all'
      );
    },
  },

  data() {
    const self = this;
    return {
      channelsSelected: [],

      chargableSelected: [],

      channelOptions,

      pickerOptions: {
        disabledDate(time) {
          return Moment() < time || Moment().diff(time, 'months') >= 6;
        },
        shortcuts: [
          {
            text: self.$t('date_labels.today'),
            onClick(picker) {
              const end = Moment().endOf('day');
              const start = Moment().startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_7_days'),
            onClick(picker) {
              const start = Moment().subtract(1, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_14_days'),
            onClick(picker) {
              const start = Moment().subtract(2, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_30_days'),
            onClick(picker) {
              const start = Moment().subtract(1, 'month').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  watch: {
    filters(oldVal, newVal) {
      if (JSON.stringify(oldVal.chargable) !== JSON.stringify(newVal.chargable)) {
        this.chargableSelected = this.filters.chargable;
      }
    },
  },

  created() {
    const { channels, chargable } = this.filters;
    this.channelsSelected = channels;
    this.chargableSelected = chargable;
  },

  methods: {
    selectChannel(code) {
      if (this.channelsSelected.includes(code)) {
        this.channelsSelected = this.channelsSelected.filter((v) => v !== code);
      } else {
        this.channelsSelected = this.channelsSelected.concat([code]);
      }

      this.updateChannelFilter(this.channelsSelected);
    },

    selectChargeable(value) {
      if (this.chargableSelected.includes(value)) {
        this.chargableSelected = this.chargableSelected.filter((v) => v !== value);
      } else {
        this.chargableSelected = this.chargableSelected.concat([value]);
      }

      this.updateChargableFilter(this.chargableSelected);
    },

    formatDateLabel(d, isStart = false) {
      const dt = isStart ? Moment(d).startOf('day') : Moment(d).endOf('day');

      let date = Moment(dt).format('MMMM DD, YYYY');
      if (this.getTimeInterval() === 60) {
        date = Moment(dt).format('MMMM DD, YYYY HH:mm');
      }
      return `${date} ${this.timeZone}`;
    },

    getTimeInterval() {
      const { daterange } = this.filters;
      const interval = daterange[1] - daterange[0];
      return interval <= 86399999 ? 60 : 1440;
    },

    // Convert 120,900 to 120.9k
    toDecimalString(n, option = {}) {
      return formatLocalizedNumber(n, option);
    },

    // Format number e.g: 1000 - 1,000
    formatNumber(n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },

    updateChannelFilter(channels) {
      this.$emit('update-checkbox-filter', { channels });
    },

    updateChargableFilter(chargable) {
      this.$emit('update-checkbox-filter', { chargable });
    },

    getOptionsName(val, options) {
      return options.find((o) => o.value === val).name;
    },

    handleReportType(command) {
      this.$emit('update-filter', { reportType: command });
    },

    handleMetric(command) {
      this.$emit('update-filter', { metric: command });
    },

    handleDaterange(dt) {
      this.$emit('update-filter', dt);
    },

    getChannelName(code) {
      const channel = this.channelOptions.find((c) => c.code === code);
      return channel ? channel.name : code;
    },

    getChargableColor(code) {
      let rgba = 'rgb(252, 214, 107)';
      switch (code) {
        case 'free':
          rgba = `rgba(255, 141, 1, 1)`;
          break;
        case 'chargeable':
          rgba = `rgba(203, 34, 1, 1)`;
          break;
        case 'businessInitiated':
          rgba = 'rgba(174, 214, 241, 1)';
          break;
        case 'customerInitiated':
          rgba = 'rgba(52, 152, 219, 1)';
          break;
        case 'marketing':
          rgba = 'rgba(106, 237, 199, 1)';
          break;
        case 'authentication':
          rgba = 'rgba(153, 139, 227, 1)';
          break;
        case 'utility':
          rgba = 'rgba(247, 100, 185, 1)';
          break;
        case 'service':
          rgba = 'rgba(254, 207, 2, 1)';
          break;
        default:
          break;
      }
      return rgba;
    },

    getChannelColor(code) {
      let rgba = 'rgba(14, 0, 121, 0.7)';
      switch (code) {
        case 'WA':
          rgba = 'rgba(58, 220, 73, 1)';
          break;
        case 'WC':
          rgba = 'rgba(1, 164, 24, 1)';
          break;
        case 'FB':
          rgba = 'rgba(43, 134, 255, 1)';
          break;
        case 'GG':
          rgba = 'rgb(128,97,94)';
          break;
        case 'IM':
          rgba = 'rgba(131, 139, 152, 1)';
          break;
        case 'LN':
          rgba = 'rgba(114, 187, 83, 1)';
          break;
        case 'VB':
          rgba = 'rgba(134, 75, 178, 1)';
          break;
        case 'ZN':
          rgba = 'rgba(58, 121, 216, 1)';
          break;
        case 'SM':
          rgba = 'rgb(235,81,112)';
          break;
        case 'KK':
          rgba = 'rgba(255, 222, 4, 1)';
          break;
        default:
          break;
      }
      return rgba;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  position: relative;

  .w-10rem {
    width: 10rem;
  }

  &__dropdown {
    min-width: 150px;
    background-color: #eff0f3;
    transition: background-color 0.1s ease-in;

    &:hover {
      background-color: #eff8ff;
    }

    &--border {
      position: relative;

      &:before {
        content: '';
        width: 1px;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__chart {
    min-height: 380px;
    height: 100%;
    position: relative;
  }

  &__chart-filter {
    & .el-checkbox {
      display: inline-flex;
      align-items: center;
    }
  }

  &__sns-legend {
    width: 8px;
    height: 8px;
  }

  &__checkbox-container {
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
}

.el-checkbox {
  margin-right: 10px;
  transform: scale(0.95);
  transform-origin: center;
  filter: grayscale(1);

  transition: all 0.1s ease-in;

  & * {
    opacity: 0.8;
    transition: opacity 0.1s ease-in;
  }

  &.is-disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.8;
    background: whitesmoke;
  }

  &.is-checked {
    filter: none;
    transform: scale(1);

    & * {
      opacity: 1;
    }
  }
}
</style>
