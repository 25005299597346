<template>
  <div class="w-full h-full">
    <GmapMap
      :center="{ lat: +lat, lng: +long }"
      :zoom="5"
      map-type-id="roadmap"
      :options="{ ...options }"
      class="w-full h-full"
    >
      <GmapMarker
        :position="{ lat: +lat, lng: +long }"
        :clickable="false"
        :name="`${lat} - ${long}`"
        @click="infoWinOpen = true"
      />
      <GmapInfoWindow
        :options="{ pixelOffset: { height: -40, width: 0 } }"
        :opened="infoWinOpen"
        :position="{ lat: +lat, lng: +long }"
        @closeclick="infoWinOpen = false"
      >
        <div>
          <h5 className="font-semibold">{{ lat }} - {{ long }}</h5>
        </div>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>
<script>
export default {
  name: 'GoogleMap',

  props: {
    lat: {
      required: true,
      type: String,
      default: '0.000000',
    },

    long: {
      required: true,
      type: String,
      default: '0.000000',
    },

    options: {
      type: String,
      default: () => ({}),
    },
  },

  data() {
    return {
      infoWinOpen: false,
    };
  },
};
</script>
