<template>
  <div class="h-full w-full relative flex">
    <!-- <h2 class="text-black text-sm font-semibold">
      {{ $t('chat_apps.dashboard.sent_undelivered[0]') }}
    </h2> -->
    <div class="w-full h-full">
      <div class="mb-5 ml-5 mt-5"><ToggleChartType @toggle-chart-type="toggleChartType" /></div>
      <div class="chart-container chart-container--full w-full">
        <LineChart
          :chart-data="getGraphData()"
          :options="chartOption"
          class="w-full h-full absolute pin-t pin-l"
        />
      </div>
    </div>
    <div v-if="chartData" class="mx-10">
      <!-- <div class="text-sm ml-6 text-grey-dark mb-1">{{ $t('column_labels.total_msgs') }}</div>
      <div class="ml-6  text-2xl font-semibold mb-3">
        {{ toDecimalString(chartData.sent.total) }}
      </div> -->
      <div class="flex items-center my-5">
        <svg class="trend">
          <polygon points="0,10 0,20 20,20 20,0" :style="{ fill: getLabelColor('delivered') }" />
        </svg>
        <div class="block ml-3">
          <p class="text-sm text-grey-dark">{{ $t('column_labels.delivered') }}</p>
          <p class="mt-1 text-sm text-black font-semibold">
            {{ toDecimalString(chartData.sent.total || 0) }}
            <!-- <span class="text-sm font-semibold">({{ getDeliveredPercent() }})</span> -->
          </p>
        </div>
      </div>
      <div class="flex items-center my-5">
        <svg class="trend">
          <polygon points="0,10 0,20 20,20 20,0" :style="{ fill: getLabelColor('undelivered') }" />
        </svg>
        <div class="block ml-3">
          <p class="text-sm text-grey-dark">{{ $t('column_labels.undelivered') }}</p>
          <p class="mt-1 text-sm text-black font-semibold">
            {{ toDecimalString(chartData.undelivered.total || 0) }}
            <span class="text-sm font-semibold">({{ getUndeliveredPercent() }})</span>
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="flex justify-between text-xs mt-2">
      <span>{{ formatDateLabel().start }}</span>
      <span>{{ formatDateLabel().end }}</span>
    </div> -->
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import LineChart from '@/components/common/LineChart.vue';
import ToggleChartType from '@/components/common/ToggleChartType.vue';

import { getCommonChartConfig } from '@/utils/common';

export default {
  name: 'SentUndeliveredChart',

  components: {
    LineChart,
    ToggleChartType,
  },

  props: {
    chartData: {
      default: () => {},
      type: Object,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    decimalStringFormat: {
      default: () => {},
      type: Function,
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    return {
      chartOption: {
        ...getCommonChartConfig(),
      },
    };
  },

  methods: {
    toggleChartType(type) {
      if (type === 'linear') {
        this.chartOption = { ...getCommonChartConfig() };
      } else if (type === 'logic_scale') {
        this.chartOption = { ...getCommonChartConfig(true) };
      }
    },

    formatDateLabel() {
      const days = this.dateRange();
      const startDate = days[0];
      const endDate = days[days.length - 1];

      if (this.filters.daterange <= 0) {
        return {
          start: Moment(startDate).format(`MMMM DD, YYYY HH:mm ${this.timeZone}`),
          end: Moment(endDate).format(`MMMM DD, YYYY HH:mm ${this.timeZone}`),
        };
      }
      return {
        start: Moment(startDate).format(`MMMM DD, YYYY ${this.timeZone}`),
        end: Moment(endDate).format(`MMMM DD, YYYY ${this.timeZone}`),
      };
    },

    getUndeliveredPercent() {
      const { sent, undelivered } = this.chartData;
      return this.toPercentString((undelivered.total / (sent.total || 1)) * 100);
    },

    getDeliveredPercent() {
      const { sent, undelivered } = this.chartData;
      return this.toPercentString(100 - (undelivered.total / (sent.total || 1)) * 100);
    },

    // Add % to the end of the number
    toPercentString(n) {
      return `${n.toFixed(n % 1 ? 2 : 0)}%`;
    },

    generateDays(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('day').format(format));
      } while (from.add(1, 'days').diff(to) < 0);
      return range;
    },

    generateHours(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('hour').format(format));
      } while (from.add(1, 'hours').diff(to) < 0);
      return range;
    },

    anchorDays(dayOffset) {
      let from = null;
      let to = null;
      let format = 'MMM D (ddd)';

      switch (dayOffset) {
        // Today
        case -1:
          from = Moment().utc().startOf('day');
          to = Moment().utc().endOf('day');
          format = 'hA';
          break;
        // 7 days
        case 7:
          from = Moment().utc().subtract(1, 'week').startOf('day');
          to = Moment().utc();
          break;
        // 14 days
        case 14:
          from = Moment().utc().subtract(2, 'week').startOf('day');
          to = Moment().utc();
          break;
        // 30 days
        case 30:
          from = Moment().utc().subtract(30, 'days').startOf('day');
          to = Moment().utc();
          break;
        default:
          break;
      }
      return { from, to, format };
    },

    dateRangeWithTz() {
      const dr = this.dateRange();

      return dr.map((d) => `${d} ${this.timeZone}`);
    },

    dateRange() {
      const dayOffset = this.filters.daterange;
      const range = this.anchorDays(dayOffset);
      const isDay = Moment(range.from).utc().isSame(Moment(range.to).utc(), 'days');
      const days = isDay
        ? this.generateHours(range.from, range.to, range.format)
        : this.generateDays(range.from, range.to, range.format);
      return days;
    },

    populateData() {
      const cd = this.chartData || [];

      const dayOffset = this.filters.daterange;
      const range = this.anchorDays(dayOffset);
      const isDay = Moment(range.from).utc().isSame(Moment(range.to).utc(), 'days');
      const days = isDay
        ? this.generateHours(range.from, range.to)
        : this.generateDays(range.from, range.to);

      return Object.keys(cd).map((code) => {
        // Fill-in missing dates
        const d = [];

        if (isDay) {
          // If hourly
          cd[code].data.forEach((hourData) => {
            d.push(hourData.value);
          });
        } else {
          // If daily
          days.forEach((day) => {
            // Check if day has data
            const dayData = cd[code].data.find((dd) =>
              Moment(dd.date).utc().isSame(Moment(day).utc(), 'day')
            );

            d.push(dayData ? dayData.value : 0);
          });
        }

        const label =
          code === 'sent'
            ? this.$t('column_labels.delivered')
            : this.$t('column_labels.undelivered');

        return {
          label: label.charAt(0).toUpperCase() + label.slice(1),
          data: d,
          borderWidth: 2,
          radius: 3,
          pointHoverRadius: 3,
          borderColor: this.getLabelColor(code),
          backgroundColor: this.getLabelColor(code, 0),
          pointBackgroundColor: this.getLabelColor(code),
          pointBorderColor: this.getLabelColor(code),
          spanGaps: true,
          lineTension: 0,
        };
      });
    },

    getLabelColor(code, opacity = 1) {
      let rgba = `rgba(52, 152, 219, ${opacity})`;
      switch (code) {
        case 'sent':
          rgba = `rgba(52, 152, 219, ${opacity})`;
          break;
        case 'undelivered':
          rgba = `rgba(174, 214, 241, ${opacity})`;
          // SMS
          break;
        default:
          break;
      }
      return rgba;
    },

    getGraphData() {
      return {
        labels: this.dateRange(),
        datasets: this.populateData().reverse(), // Reverse to show udelivered first
      };
    },

    toDecimalString(n) {
      return this.decimalStringFormat(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  height: 8px;
  width: 8px;
  display: inline-block;
}

.trend {
  width: 20px;
  height: 20px;
  display: block;

  &--wa {
    fill: #25d366;
  }
  &--wc {
    fill: #9de60b;
  }
  &--fb {
    fill: #4267b2;
  }
  &--sm {
    fill: #eb526f;
  }
  &--zl {
    fill: #0071b5;
  }
  &--kk {
    fill: #f7e011;
  }
}
.chart-container {
  width: 100%;
  flex-grow: 0;
  height: 300px;
  position: relative;

  &--large {
    height: 300px;
  }

  &--full {
    min-height: 300px;
    height: 100%;
  }

  &--small {
    height: 130px;
  }
}
</style>
