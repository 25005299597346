<template>
  <div>
    <img v-if="imgSrc" :src="imgSrc" alt="viber-image"/>
    <span v-else>N/A</span>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'securedImage',

  props: {
    campaign: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      imgSrc: '',
    };
  },

  created() {
    this.downloadImage();
  },

  methods: {
    downloadImage() {
      try {
        const campaignObj = JSON.parse(this.campaign);

        const fileUrl = campaignObj.message && campaignObj.message.imageURL ? campaignObj.message.imageURL : '';

        if (!fileUrl) return;

        // fetching images with jwt
        const jwt = localStorage.getItem('WWW-Authenticate');

        axios.get(fileUrl, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }).then((response) => {
          this.imgSrc = response.data.url;
        }).catch((err) => {
          window.Bugsnag.notify(err);
        });
      } catch (err) {
        window.Bugsnag.notify(err);
      }
    },
  },
};
</script>
