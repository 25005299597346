<template>
  <el-form
    :model="form"
    :rules="formRules"
    ref="form"
    class="leading-none"
    hide-required-asterisk
  >
    <el-form-item prop="name">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.channel_name') }}
        </p>
        <el-input
          v-model="form.name"
          placeholder="For internal reference - Ex. Zalo SG"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item prop="accountName">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
           {{ $t('column_labels.official_account_name') }}
        </p>
        <el-input
          v-model="form.accountName"
          :placeholder="$t('chat_apps.channels.zalo[0]')"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item prop="apiToken">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.api_access_token') }}
        </p>
        <el-input
          v-model="form.apiToken"
          placeholder="Ex. 3183127943297490298021"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item class="mt-10">
      <el-button
        type="primary"
        size="small"
        @click="submit"
      >
       {{ $t('chat_apps.templates.create[21]') }}
      </el-button>
      <el-button
        size="small"
        @click="cancel"
      >
        {{ $t('actions.cancel') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'zalo-form',

  props: {
    channel: {
      default: () => {},
      type: Object,
    },
  },

  data() {
    const self = this;
    return {
      form: {
        channel: this.channel.code,
        name: '',
        accountName: '',
        apiToken: '',
      },

      formRules: {
        channel: [
          { required: true, message: self.$t('validations.required', { value: self.$tc('column_labels.channel', 1) }), trigger: 'blur' },
        ],

        name: [
          { required: true, message: self.$t('validations.required', { value: self.$t('column_labels.channel_name') }), trigger: 'blur' },
        ],

        accountName: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.account_name') }), trigger: 'change' },
        ],

        apiToken: [
          { required: true, message: self.$t('validations.required', { value: self.$t('column_labels.api_access_token') }), trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    cancel() {
      this.$parent.$emit('close');
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('submit', this.form);
          } else {
            return false;
          }
          return false;
        });
      }
    },
  },
};
</script>

<styles lang="scss" scoped>
.el-form-item__label {
  line-height: 0;
  margin: 20px 0 10px;
}
</styles>
