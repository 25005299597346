/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    filters: {
      subAccountId: 'all',
      channel: '',
      daterange: 7,
    },
    data: {},
    loading: false,
  },

  getters: {
    getSentChartData: (st) => st.data.sentMessages,

    getMessagesPerChannel: (st) => st.data.sentMessagesPerChannel,

    getDeliveryChartData: (st) => st.data.sentVsUndelivered,

    getDeliveryRate: (st) => st.data.deliveryRate,

    getDeliveryRateLabels: (st) => {
      const data = st.data.deliveryRate;

      if (data === undefined || !data) return [];

      return [{ name: 'delivery', value: data.average || 0 }];
    },

    getReceivedMessages: (st) => st.data.receivedMessages,

    getReceivedMessagesLabels: (st) => {
      const data = st.data.receivedMessages;

      if (data === undefined || !data) return [];

      return [{ name: 'received', value: data.total || 0 }];
    },

    getChargedAndFreeMessages: (st) => st.data.chargedAndFreeMessages,

    getChargedAndFreeMessagesLabels: (st) => {
      const list = st.data.chargedAndFreeMessages;

      if (list === undefined || !list) return [];

      return Object.keys(list).map((item) => ({
        name: item,
        value: list[item].total || 0,
      }));
    },

    getWhatsAppConversations: (st) => {
      if (
        st.data &&
        st.data.whatsAppConversations &&
        Object.keys(st.data.whatsAppConversations).length &&
        st.data.whatsAppConversations.conversations &&
        Object.keys(st.data.whatsAppConversations).length
      ) {
        delete st.data.whatsAppConversations.conversations.freeConversation;
        delete st.data.whatsAppConversations.conversations.customerInitiated;
        delete st.data.whatsAppConversations.conversations.businessInitiated;

        return st.data.whatsAppConversations.conversations;
      }

      return {};
    },

    getWAFreeAndChargeable: (st) => {
      if (
        st.data &&
        st.data.whatsAppConversations &&
        Object.keys(st.data.whatsAppConversations).length
      ) {
        return Object.keys(st.data.whatsAppConversations).reduce((a, b) => {
          if (b === 'chargeable') {
            a.chargeable = st.data.whatsAppConversations[b] || 0;
          }

          if (b === 'free') {
            a.free = st.data.whatsAppConversations[b] || 0;
          }
          return a;
        }, {});
      }

      return { chargeable: 0, free: 0 };
    },

    getWhatsAppConversationsLabels: (st) => {
      let list = null;

      if (
        st.data &&
        st.data.whatsAppConversations &&
        Object.keys(st.data.whatsAppConversations).length &&
        st.data.whatsAppConversations.conversations &&
        Object.keys(st.data.whatsAppConversations).length
      ) {
        delete st.data.whatsAppConversations.conversations.freeConversation;

        list = st.data.whatsAppConversations.conversations;
      }

      if (!list) return [];

      return Object.keys(list).map((item) => {
        if (!item.match(/initiated/i)) {
          return {
            name: item,
            value: list[item].total || 0,
          };
        }
      });
    },
  },

  mutations: {
    SET_LOADING: (st, payload) => {
      st.loading = payload.bool;
    },
    SET_FILTERS: (st, payload) => {
      st.filters = payload.filters;
    },
    SET_DATA: (st, payload) => {
      st.data = payload.data;
    },
  },

  actions,
};
