/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    timezones: [],
    timezone: 'UTC / Greenwich Mean Time',
  },

  getters: {
    getTimeZones: (st) => st.timezones,
    getTimeZone: (st) => st.timezone,
  },

  mutations: {
    SET_TIMEZONES(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.timezones = payload.timezones;
    },

    SET_TIMEZONE(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.timezone = payload.timezone;
    },
  },

  actions,
};
