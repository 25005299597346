var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative leading-none"},[_c('el-select',{attrs:{"disabled":Boolean(_vm.context.category === 'AUTHENTICATION'),"placeholder":_vm.$t('chat_apps.templates.custom_buttons[0]'),"size":"small","filterable":""},on:{"change":function($event){return _vm.resetField()}},model:{value:(_vm.buttonType),callback:function ($$v) {_vm.buttonType=$$v},expression:"buttonType"}},_vm._l((_vm.buttonTypeOptions),function(button){return _c('el-option',{key:button,attrs:{"disabled":Boolean(button === 'OTP'),"label":button === 'OTP'
          ? _vm.$t('chat_apps.categories.OTP')
          : _vm.$t(("chat_apps.button_types." + (_vm.buttonTypesJson[button].label))),"value":button}})}),1),_c('div',{staticClass:"mt-5"},_vm._l((_vm.buttons),function(field,index){return _c('div',{key:index,staticClass:"relative"},[(_vm.buttonType === 'CALL_TO_ACTION')?_c('div',{staticClass:"relative mb-5 flex items-center",attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.onDrag($event, index)},"drop":function($event){return _vm.onDrop($event, index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[(_vm.buttons.length > 1)?_c('i',{staticClass:"el-icon-more drag-icon text-grey"}):_vm._e(),_c('div',{staticClass:"p-4 border rounded bg-grey-lightest w-full"},[_c('div',{staticClass:"flex -m-2"},[_c('div',{staticClass:"relative w-full p-2"},[_c('span',{staticClass:"text-xs font-bold mb-1 block"},[_vm._v("\n                "+_vm._s(_vm.$t('column_labels.type_of_action'))+" \n              ")]),_c('el-form-item',{attrs:{"prop":'buttons.items.' + index + '.type',"rules":{
                  required: true,
                  message: _vm.$t('validations.select', {
                    value: _vm.$t('column_labels.type_of_action'),
                  }),
                  trigger: ['change', 'blur'],
                }}},[_c('el-select',{staticClass:"w-full",attrs:{"disabled":_vm.buttons.length >= _vm.buttonTypesJson[_vm.buttonType].max,"placeholder":_vm.$t('chat_apps.templates.custom_buttons[1]'),"size":"small"},on:{"change":function($event){return _vm.resetCallToActionFields(index)}},model:{value:(field.type),callback:function ($$v) {_vm.$set(field, "type", $$v)},expression:"field.type"}},_vm._l((_vm.buttonActionOptions),function(button){return _c('el-option',{key:button,attrs:{"label":_vm.$t(("chat_apps.button_actions." + (_vm.buttonActionsJson[button].label))),"value":button}})}),1)],1)],1),_c('div',{staticClass:"relative w-full p-2"},[_c('span',{staticClass:"text-xs font-bold mb-1 block"},[_vm._v("  "+_vm._s(_vm.$t('column_labels.button_text'))+" \n              ")]),_c('el-form-item',{attrs:{"prop":'buttons.items.' + index + '.text',"rules":[
                  {
                    required: true,
                    message: _vm.$t('validations.required', {
                      value: _vm.$t('column_labels.button_text'),
                    }),
                    trigger: ['change', 'blur'],
                  },
                  {
                    validator: _vm.checkDuplicateButtonText,
                    trigger: ['change', 'blur'],
                  } ]}},[_c('el-input',{staticClass:"w-full",attrs:{"size":"small","type":"text","placeholder":_vm.$t('column_labels.button_text'),"maxlength":"20","show-word-limit":""},model:{value:(field.text),callback:function ($$v) {_vm.$set(field, "text", $$v)},expression:"field.text"}})],1)],1),(field.type === 'URL')?_c('div',{staticClass:"relative flex-no-shrink w-32 p-2"},[_c('span',{staticClass:"text-xs font-bold mb-1 block"},[_vm._v(_vm._s(_vm.$t('column_labels.url_type')))]),_c('el-form-item',{attrs:{"prop":'buttons.items.' + index + '.urlType',"rules":{
                  required: true,
                  message: _vm.$t('validations.required', { value: _vm.$t('column_labels.url_type') }),
                  trigger: ['change', 'blur'],
                }}},[_c('el-select',{staticClass:"w-full",attrs:{"size":"small","filterable":""},model:{value:(field.urlType),callback:function ($$v) {_vm.$set(field, "urlType", $$v)},expression:"field.urlType"}},_vm._l((Object.keys(_vm.buttonActionsJson['URL'].types)),function(buttonType){return _c('el-option',{key:buttonType,attrs:{"label":_vm.$t(
                        ("chat_apps.button_actions." + (_vm.buttonActionsJson['URL'].types[buttonType].label))
                      ),"value":buttonType}})}),1)],1)],1):(field.type === 'PHONE_NUMBER')?_c('div',{staticClass:"relative flex-no-shrink w-32 p-2"},[_c('span',{staticClass:"text-xs font-bold mb-1 block"},[_vm._v(_vm._s(_vm.$t('column_labels.country')))]),_c('el-form-item',{attrs:{"prop":'buttons.items.' + index + '.country',"rules":[
                  {
                    required: true,
                    message: _vm.$t('validations.required', { value: _vm.$t('column_labels.country') }),
                    trigger: ['change', 'blur'],
                  } ]}},[_c('el-select',{staticClass:"w-full",attrs:{"size":"small","filterable":""},model:{value:(field.country),callback:function ($$v) {_vm.$set(field, "country", $$v)},expression:"field.country"}},_vm._l(([
                      { name: _vm.$t('app_labels.none'), value: '' } ].concat( _vm.countriesJson )),function(country){return _c('el-option',{key:country.value,attrs:{"label":((country.value || _vm.$t('app_labels.none')) + " " + (country.callingCode || '')),"value":country.value}})}),1)],1)],1):_vm._e(),(field.type === 'URL')?_c('div',{staticClass:"relative w-full p-2"},[_c('span',{staticClass:"text-xs font-bold mb-1 block"},[_vm._v("\n                "+_vm._s(_vm.$t('column_labels.website_url'))+"\n              ")]),_c('el-form-item',{attrs:{"prop":'buttons.items.' + index + '.url',"rules":[
                  {
                    required: true,
                    message: _vm.$t('validations.required', {
                      value: _vm.$t('column_labels.website_url'),
                    }),
                    trigger: ['change', 'blur'],
                  },
                  {
                    type: 'url',
                    message: _vm.$t('validations.valid', { value: _vm.$t('column_labels.website_url') }),
                    trigger: ['change', 'blur'],
                  } ]}},[_c('el-input',{staticClass:"w-full",attrs:{"size":"small","type":"url","placeholder":"https://www.website-name.com","maxlength":"2000"},model:{value:(field.url),callback:function ($$v) {_vm.$set(field, "url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"field.url"}},[(field.urlType === 'DYNAMIC')?_c('i',{attrs:{"slot":"suffix"},slot:"suffix"},[_c('el-tooltip',{attrs:{"effect":"dark","content":_vm.$t('chat_apps.templates.custom_buttons[2]'),"placement":"top"}},[_c('span',{staticClass:"text-xs font-normal roman dynamic-text text-black"},[_vm._v("\n                        "+_vm._s("/{{1}}")+"\n                      ")])])],1):_vm._e()])],1)],1):(field.type === 'PHONE_NUMBER')?_c('div',{staticClass:"relative w-full p-2"},[_c('span',{staticClass:"text-xs font-bold mb-1 block"},[_vm._v(_vm._s(_vm.$t('column_labels.phone_no')))]),_c('el-form-item',{attrs:{"prop":'buttons.items.' + index + '.phone',"rules":[
                  {
                    required: true,
                    message: _vm.$t('validations.required', { value: _vm.$t('column_labels.phone_no') }),
                    trigger: ['change', 'blur'],
                  },
                  {
                    validator: _vm.validPhoneNumber,
                    trigger: 'blur',
                  } ]}},[_c('el-input',{staticClass:"w-full",attrs:{"size":"small","type":"text","placeholder":_vm.$t('column_labels.phone_no'),"maxlength":"20","show-word-limit":""},on:{"change":function (e) { return _vm.setRegionViaPhone(e, index); }},model:{value:(field.phone),callback:function ($$v) {_vm.$set(field, "phone", $$v)},expression:"field.phone"}})],1)],1):_vm._e()])]),(_vm.buttons.length > 1)?_c('a',{staticClass:"text-sm ml-2",on:{"click":function($event){$event.preventDefault();return _vm.removeField(index)}}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e()]):(_vm.buttonType === 'QUICK_REPLY' || _vm.buttonType === 'OTP')?_c('div',{staticClass:"relative mb-5"},[_c('span',{staticClass:"text-xs font-bold mb-1 block"},[_vm._v(_vm._s(_vm.$t('column_labels.button_text')))]),_c('div',{staticClass:"flex"},[(_vm.context.category === 'MARKETING')?_c('el-form-item',{staticClass:"mr-3",attrs:{"prop":'buttons.items.' + index + '.type'}},[_c('el-select',{attrs:{"size":"small","type":"text"},on:{"change":function (ev) { return _vm.changeQuickReplyTypes(ev, index); }},model:{value:(field.type),callback:function ($$v) {_vm.$set(field, "type", $$v)},expression:"field.type"}},_vm._l((_vm.marketingButtonTypes),function(v,i){return _c('el-option',{key:i,attrs:{"label":v.label,"value":v.name,"disabled":v.name === 'MARKETING_OPT_OUT' &&
                  _vm.buttons.some(function (b) { return b.type === 'MARKETING_OPT_OUT' && b.text === _vm.optOutText; })}})}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"prop":'buttons.items.' + index + '.text',"rules":[
              {
                required: true,
                message: _vm.$t('validations.required', { value: _vm.$t('column_labels.button_text') }),
                trigger: ['change', 'blur'],
              },
              {
                validator: _vm.checkDuplicateButtonText,
                trigger: ['change', 'blur'],
              } ]}},[_c('el-input',{staticClass:"w-64",attrs:{"disabled":Boolean(_vm.context.category === 'AUTHENTICATION'),"size":"small","type":"text","placeholder":_vm.$t('column_labels.button_text'),"maxlength":"20","show-word-limit":""},model:{value:(field.text),callback:function ($$v) {_vm.$set(field, "text", $$v)},expression:"field.text"}}),(_vm.buttons.length > 1)?_c('a',{staticClass:"text-sm ml-2",on:{"click":function($event){$event.preventDefault();return _vm.removeField(index)}}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e()],1)],1)]):_vm._e()])}),0),(_vm.buttons.length > 0 && _vm.buttons.length < _vm.buttonTypesJson[_vm.buttonType].max)?_c('div',{staticClass:"mt-5 mb-5"},[_c('el-button',{attrs:{"type":"default","icon":"el-icon-plus","size":"mini"},on:{"click":function($event){return _vm.addField()}}},[_vm._v("\n      "+_vm._s(_vm.$t('chat_apps.templates.custom_buttons[3]'))+"\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }