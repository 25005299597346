<template>
  <div class="mt-8">
    <p class="text-black mb-4">Facebook Business Manager</p>
    <p class="text-sm text-grey-darker">
      You'll need to log in to Facebook account, and have access to your company's
      <br />Facebook Business Manager.
    </p>

    <el-card class="box-card my-2 mx-2" shadow="never">
      <el-checkbox v-model="hasFacebook" class="my-1 mx-1">
        I have a Facebook account and understand above
      </el-checkbox>
    </el-card>

    <p class="text-sm text-grey-darker">
      Don't have one?
      <a href="https://www.facebook.com" target="_blank" class="text-blue no-underline">
        Create a new account</a
      >, but note that some restrictions apply in the first 24 hours.
    </p>
    <p class="text-sm text-grey-darker py-2">
      If your company doesn't have a Facebook Business Account, you can create one in the next step.
    </p>

    <p class="text-black mb-4 mt-6">Phone Number for WhatsApp</p>
    <p class="text-sm text-grey-darker">
      You will need a phone number for your WhatsApp channel with the following specifications:
    </p>
    <ul>
      <li class="text-sm text-grey-darker py-1">
        This phone number needs to be
        <strong>owned by your company</strong>
        you should not use your
        <br />personal phone number
      </li>
      <li class="text-sm text-grey-darker">
        This number may <strong>not have been linked</strong> with WhatsApp before
      </li>
      <li class="text-sm text-grey-darker py-1">
        You must be able to receive <strong>phone calls</strong> or <strong>SMS</strong>
        to the phone number when proceeding
      </li>
    </ul>

    <el-card class="box-card my-2 mx-2" shadow="never">
      <el-checkbox v-model="hasCleanNumber" class="my-1 mx-1">
        I have a number with the above requirements
      </el-checkbox>
    </el-card>
  </div>
</template>
<script>
export default {
  name: 'Start',

  props: {
    setupData: {
      type: Object,
      default: () => {},
    },

    update: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      hasFacebook: false,
      hasCleanNumber: false,
    };
  },

  watch: {
    setupData: {
      immediate: true,
      handler() {
        const { hasFacebook, hasCleanNumber } = this.setupData;
        if (this.hasFacebook !== hasFacebook) {
          this.hasFacebook = this.setupData.hasFacebook;
        }

        if (this.hasCleanNumber !== hasCleanNumber) {
          this.hasCleanNumber = this.setupData.hasCleanNumber;
        }
      },
    },

    hasFacebook: {
      immediate: true,
      handler(val) {
        if (val !== this.setupData.hasFacebook) {
          this.updateData();
        }
      },
    },

    hasCleanNumber: {
      immediate: true,
      handler(val) {
        if (val !== this.setupData.hasCleanNumber) {
          this.updateData();
        }
      },
    },
  },

  methods: {
    updateData() {
      this.$emit('update', {
        hasFacebook: this.hasFacebook,
        hasCleanNumber: this.hasCleanNumber,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  border: solid 1px tomato;
  max-width: 500px;
}
</style>
