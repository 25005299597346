import Moment from 'moment';
import http from '../../../utils/http';

export default {
  // Fetch reports data
  getReportsData: async ({ commit }, payload = {}) => {
    const { daterange, ...f } = payload;
    const daterangeFormatted = daterange.map((dt) => Moment(dt).format('YYYY-MM-DD'));
    const dates = daterangeFormatted.map((dt) => Moment.utc(dt).unix());
    const p = { daterange: dates, ...f };
    const query = Object.keys(p)
      .map((key) => `${key}=${p[key]}`)
      .join('&');
    commit({ type: 'SET_LOADING', bool: true });
    try {
      const { data } = await http.get(`/chats/reports?${query}`);
      commit({
        type: 'SET_REPORTS',
        reports: data && data.conversations ? data.conversations : data,
      });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', bool: false });
    }
  },

  // Export reports data
  exportReportsData: async ({ commit }, payload) => {
    // Convert date to unix
    const { daterange, ...q } = payload;
    const daterangeFormatted = daterange.map((dt) => Moment(dt).format('YYYY-MM-DD'));
    const dr = daterangeFormatted.map((d) => Moment.utc(d).unix());
    const p = { daterange: dr, ...q };
    const query = Object.keys(p)
      .map((key) => `${key}=${p[key]}`)
      .join('&');

    try {
      await http.get(`/chats/reports/export?${query}`);
      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
