// eslint-disable-next-line import/prefer-default-export
export function fixBreakLines(text = '') {
  const br2nl = (str, replaceMode) => {
    const replaceStr = replaceMode ? '\n' : '';
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[/]?>/gi, replaceStr); // disable-eslint-line
  };

  let t = text.trim();

  t = br2nl(t, true);

  t = t.replace(/&nbsp;/g, '');

  return t;
}

export function hasUrlLink(content = '') {
  if (!content) return false;

  // eslint-disable-next-line no-useless-escape
  return content.match(
    /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/g
  );
}

export function transformToWAValidTemplate(content = '') {
  let c = content.trim();

  if (!c) return '';

  if (c.match(/<[^>]*>/g)) {
    c = c.replace(/<br\s*(\s+[^>]+)*[/]?>/gi, '<p></p>');
    c = c.replace(/<\/p>/g, '\n').replace(/<p>/g, '');

    // for bold element
    c = c.replace(/<strong>|<\/strong>/g, '*');
    c = c.replace(/<b>|<\/b>/g, '*');

    // for italic element
    c = c.replace(/<em>|<\/em>/g, '_');
    c = c.replace(/<i>|<\/i>/g, '_');

    // for strikethrough
    c = c.replace(/<s>|<\/s>/g, '~');
    c = c.replace(/<strike>|<\/strike>/g, '~');
    c = c.replace(/<del>|<\/del>/g, '~');
  }

  return c;
}

export function transformToHTML(content = '') {
  let c = content.trim();

  if (!c) return '';

  c = c.replace(/\*(\S[^*]+\S)\*/g, '<strong>$1</strong>');
  if (!hasUrlLink(c)) {
    c = c.replace(/_(\S[^_]+\S)_/g, '<em>$1</em>');
  }
  c = c.replace(/~(\S[^~]+\S)~/g, '<s>$1</s>');

  return c;
}

export function insertAtCursor(el, value) {
  // IE support
  if (document.selection) {
    el.focus();
    const sel = document.selection.createRange();
    sel.text = value;
  } else if (window.navigator.userAgent.indexOf('Edge') > -1) {
    const startPos = el.selectionStart;
    const endPos = el.selectionEnd;

    // eslint-disable-next-line no-param-reassign
    el.value =
      el.value.substring(0, startPos) + value + el.value.substring(endPos, el.value.length);

    const pos = startPos + value.length;
    el.focus();
    el.setSelectionRange(pos, pos);
  } else if (el.selectionStart || el.selectionStart === '0') {
    const startPos = el.selectionStart;
    const endPos = el.selectionEnd;
    // eslint-disable-next-line no-param-reassign
    el.value =
      el.value.substring(0, startPos) + value + el.value.substring(endPos, el.value.length);
  } else {
    // eslint-disable-next-line no-param-reassign
    el.value += value;
  }
}

export function urlResolver(url, imgUrl) {
  if (imgUrl.indexOf(url) !== -1) {
    return imgUrl;
  }

  return `${url}${imgUrl}`;
}

export function removeUnicodeChars(str) {
  if (!str) return '';

  const s = str.replace(
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
    ''
  );

  return s;
}

// Format buttons api format to the format being used in front-end
export function formatReadableButtonValues(buttonVal) {
  if (Object.keys(buttonVal).length <= 0) return {};

  const [firstButton] = buttonVal.buttons;

  let buttonValues = '';

  if (firstButton.type === 'QUICK_REPLY') {
    const items = buttonVal.buttons.map((button) => ({ text: button.text }));

    buttonValues = {
      type: 'QUICK_REPLY',
      items,
    };
  } else if (firstButton.type === 'OTP') {
    const items = buttonVal.buttons.map((button) => ({ text: button.text }));

    buttonValues = {
      type: 'OTP',
      items,
    };
  } else if (firstButton.type === 'URL' || firstButton.type === 'PHONE_NUMBER') {
    const items = buttonVal.buttons.map((button) => {
      const { type, text, url, phoneNumber, country } = button;

      let newUrl = '';
      let urlType = '';

      if (type === 'URL') {
        urlType = unescape(url).match(/\/\{.*?\}$/g) ? 'DYNAMIC' : 'STATIC';
        newUrl = unescape(url).replace(/\/\{.*?\}$/g, '');
      } else if (type === 'PHONE_NUMBER') {
        // If type is phone number
      }

      return {
        type,
        text,
        urlType,
        url: newUrl,
        phone: phoneNumber || '',
        country: country || '',
      };
    });

    buttonValues = {
      type: 'CALL_TO_ACTION',
      items,
    };
  }

  return buttonValues;
}

export function getCommonChartConfig(logarithmic = false) {
  return {
    legend: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: '#ECECEC',
          },
          ticks: {
            min: 0,
            autoskip: true,
            autoSkipPadding: 10,
          },
          stacked: false,
          display: true,
          beginAtZero: false,
          scaleLabel: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          type: logarithmic ? 'logarithmic' : 'linear',
          stacked: false,
          gridLines: {
            color: '#ECECEC',
          },
          ticks: {
            suggestedMax: 5,
            beginAtZero: true,
            precision: 0,
            callback(value) {
              if (logarithmic) {
                if (value === 10000000) return '10M';
                if (value === 1000000) return '1M';
                if (value === 100000) return '100K';
                if (value === 10000) return '10K';
                if (value === 1000) return '1K';
                if (value === 100) return '100';
                if (value === 10) return '10';
                if (value === 1) return '1';
                return null;
              }

              return new Intl.NumberFormat('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              }).format(parseFloat(value));
            },
          },
        },
      ],
    },
    // tooltips: {
    //   mode: 'index',
    //   intersect: false,
    // },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
  };
}

export function formatLocalizedNumber(number = 0, option = {}) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    ...option,
  }).format(parseFloat(number));
}

export function getBrackets(content = '') {
  return content.match(/\{\{([1-9]\d*)\}\}/g);
}

export function replaceCustomFields(content, field, value = '') {
  if (content && field && value && content.indexOf(field) !== -1) {
    return content.replace(field, value);
  }

  return content;
}

export function formatBytes(bytes) {
  if (bytes === 0) {
    return {
      size: '0',
      label: 'B',
    };
  }
  const k = 1024; // Or 1 kilo = 1000
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    // eslint-disable-next-line no-restricted-properties
    size: parseFloat(bytes / Math.pow(k, i)),
    label: sizes[i],
  };
}
