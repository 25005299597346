<template>
  <el-dialog
    :title="modalTitle()"
    :visible.sync="ctVisible"
    width="80%"
    top="20px"
    destroy-on-close
    custom-class="px-20 template-modal"
    @close="closeModal"
    @open="resetForm"
  >
    <div class="create-template">
      <el-form ref="form" :model="form">
        <div class="template">
          <p class="mb-1 text-sm font-medium">
            {{ $t('chat_apps.templates.create[0]') }}
          </p>
          <p class="text-grey-dark mb-2 text-xs">
            {{ $t('chat_apps.templates.create[1]') }}
          </p>
          <el-form-item
            prop="name"
            :rules="[
              {
                required: true,
                message: $t('validations.required', { value: $t('column_labels.template_name') }),
                trigger: 'blur',
              },
              {
                pattern: /^([a-z0-9_]+)$/,
                message: $t('chat_apps.templates.create[2]'),
                trigger: 'blur',
              },
              {
                validator: checkName,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.name"
              :placeholder="$t('chat_apps.templates.create[2]')"
              size="small"
              :style="{ width: '40%' }"
            />
          </el-form-item>
        </div>
        <div class="categories">
          <p class="mb-1 text-sm font-medium">{{ $t('column_labels.category') }}</p>
          <p class="text-grey-dark mb-2 text-xs">
            {{ $t('chat_apps.templates.create[3]') }}
            <a
              href="https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines"
              target="_blank"
              rel="noopener noreferrer"
              class="no-underline"
            >
              {{ $t('app_labels.click_here') }}
            </a>
            {{ $t('chat_apps.templates.to_view_samples') }}.
          </p>
          <el-form-item
            prop="category"
            :rules="{
              required: true,
              message: $t('validations.select', { value: $t('column_labels.category') }),
              trigger: 'change',
            }"
          >
            <div class="flex mt-1">
              <div
                v-for="(v, n) in fixedCategories"
                :key="n"
                :class="`bg-grey-custom py-1 px-2 rounded-lg mr-5 mb-5 w-64 border
                ${form.category === v.name ? 'border-green' : 'border-grey-lighter'}`"
              >
                <el-popover placement="top-start" width="350" trigger="hover">
                  <p class="text-xs break-words cat-desc">{{ $t(`${v.description}`) }}</p>
                  <div slot="reference">
                    <el-radio
                      v-model="form.category"
                      :label="v.name"
                      :disabled="!Boolean(v.supported)"
                    >
                      <span class="text-xs ct-label">{{ $t(`${v.label}`) }}</span>
                    </el-radio>
                  </div>
                </el-popover>
              </div>
            </div>
          </el-form-item>
          <el-form-item :style="{ marginBottom: '20px', marginTop: '-20px' }">
            <div>
              <el-switch v-model="form.allowCategoryChange" active-color="#13ce66" />
              <span class="text-xs ct-label ml-3">
                {{ $t('chat_apps.templates.allow_template_category_change') }}
                <el-popover placement="top-start" width="300" trigger="hover">
                  <div class="sample-reminder text-xs">
                    {{ $t('chat_apps.templates.allow_meta') }}
                  </div>
                  <i slot="reference" class="el-icon-question" />
                </el-popover>
              </span>
            </div>
          </el-form-item>
        </div>
        <div class="languages">
          <p class="mb-1 text-sm font-medium">{{ $tc('column_labels.language', 1) }}</p>
          <p class="text-grey-dark mb-2 text-xs">
            {{ $t('chat_apps.templates.create[4]') }}
          </p>
          <el-form-item
            prop="language"
            :rules="{
              required: true,
              message: $t('validations.select', { value: $tc('column_labels.language', 1) }),
              trigger: 'change',
            }"
          >
            <el-select
              v-model="form.language"
              :placeholder="$t('validations.select', { value: $tc('column_labels.language', 1) })"
              size="small"
              class="w-2/5"
              filterable
            >
              <el-option v-for="(v, n) in lang" :key="n" :label="v" :value="n" />
            </el-select>
          </el-form-item>
        </div>
        <div class="channels">
          <p class="mb-1 text-sm font-medium">{{ $t('column_labels.channel_id') }}</p>
          <p class="text-grey-dark mb-2 text-xs">
            {{ $t('chat_apps.templates.create[5]') }}
          </p>
          <el-form-item
            prop="channelId"
            :rules="{
              required: true,
              message: $t('validations.select', { value: $t('column_labels.channel_id') }),
              trigger: 'change',
            }"
          >
            <el-select
              v-model="form.channelId"
              :placeholder="$t('validations.select', { value: $t('column_labels.channel_id') })"
              size="small"
              class="w-2/5"
            >
              <el-option
                v-for="channel in waChannels"
                :key="channel.id"
                :label="channel.name"
                :value="channel.id"
              />
            </el-select>
          </el-form-item>
        </div>
        <hr class="mx-0 my-10 h-px bg-grey-dark w-48" />
        <el-row :gutter="100">
          <el-col :span="16">
            <div class="msg-title" :class="{ 'mb-8': form.category !== 'AUTHENTICATION' }">
              <p class="mb-1 text-lg font-medium">{{ $t('column_labels.message') }}</p>
              <p v-if="form.category !== 'AUTHENTICATION'" class="text-grey-dark text-xs">
                {{ $t('chat_apps.templates.create[6]') }}
              </p>
            </div>
            <div v-if="form.category === 'AUTHENTICATION'" class="mb-10">
              <el-form-item class="form-extended">
                <div>
                  <el-switch v-model="form.securityRecommendation" active-color="#13ce66" />
                  <span class="text-xs ct-label ml-3">{{
                    $t('chat_apps.templates.add_security_disclaimer')
                  }}</span>
                </div>
              </el-form-item>
              <el-form-item class="form-extended">
                <div>
                  <el-switch v-model="form.footerExpiry" active-color="#13ce66" />
                  <span class="text-xs ct-label ml-3">{{
                    $t('chat_apps.templates.add_expiry_warning')
                  }}</span>
                </div>
              </el-form-item>
              <el-form-item v-if="form.footerExpiry" class="form-extended">
                <div>
                  <el-input-number
                    v-model="form.footerExpiryInMins"
                    :style="{ width: '50px' }"
                    size="small"
                    min="1"
                    max="90"
                    :controls="Boolean(false)"
                    @change="onChangeFooterExpiry"
                  />
                  <span class="text-xs ct-label ml-3">
                    {{ $t('chat_apps.templates.enter_number_1_to_90_mins') }}</span
                  >
                </div>
              </el-form-item>
            </div>
            <div v-else class="msg-head">
              <p class="mb-2">
                <span class="text-sm font-medium">{{ $t('column_labels.header') }}</span>
                <span class="bg-grey text-white text-xs py-1 px-3 ml-2 rounded font-9px">
                  {{ $t('validations.optional').toUpperCase() }}
                </span>
              </p>
              <p class="text-grey-dark mb-2 text-xs">
                {{ $t('chat_apps.templates.create[7]') }}
              </p>
              <el-form-item class="form-extended">
                <el-radio
                  v-for="(v, i) in headers"
                  :key="i"
                  v-model="form.header"
                  :label="v"
                  @change="selectHeader"
                >
                  <span class="capitalize text-xs">{{ v }}</span>
                </el-radio>
              </el-form-item>
              <el-form-item v-if="form.header === 'text'" class="text-item mb-3">
                <el-input
                  ref="headerInput"
                  v-model="form.headerValue"
                  :placeholder="$t('chat_apps.templates.create[8]')"
                  size="small"
                  :style="{ width: '60%' }"
                  class="mr-2"
                />

                <el-button type="text" :disabled="hasVariable()" @click="addHeaderVariable">
                  <i class="el-icon-plus font-black"></i> {{ $t('chat_apps.templates.create[9]') }}
                </el-button>

                <p
                  v-if="
                    (!form.headerValue.trim() || form.headerValue.trim().length > 60) && xContent
                  "
                  class="text-xs text-red"
                  :style="{ lineHeight: '20px' }"
                >
                  {{ $t('chat_apps.templates.create[10]') }}
                </p>
              </el-form-item>
              <el-form-item v-if="form.header === 'media'" class="media-item">
                <div class="flex w-3/4">
                  <div
                    v-for="(v, i) in headerMedia"
                    :key="i"
                    :class="`text-center rounded-lg mr-3 flex-1 bg-grey-custom py-1 px-2 border
                    ${form.headerValue === v ? 'border-green' : 'border-grey-lighter'}`"
                  >
                    <div class="text-center w-full">
                      <el-radio v-model="form.headerValue" :label="v" />
                    </div>
                    <div class="text-center w-full">
                      <img
                        :src="
                          urlResolver(
                            bgUrl,
                            require(`@/assets/media/${getMediaIcons(
                              v,
                              form.headerValue === v
                            )}.svg`)
                          )
                        "
                        class="align-middle"
                        :alt="v"
                        :style="{ width: '20px', height: '20px' }"
                      />
                    </div>
                    <div class="text-xs text-center w-full uppercase">
                      <span
                        :class="`${form.headerValue === v ? 'text-green-dark' : 'text-grey-dark'}`"
                      >
                        {{ v }}
                      </span>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="msg-box mb-3">
              <div class="">
                <p class="mb-1 text-sm font-medium">{{ $t('column_labels.body') }}</p>
                <div
                  v-if="['utility', 'marketing'].includes(form.category.toLowerCase())"
                  class="mt-3 text-black text-xs p-3 bg-yellow-lightest border border-solid border-yellow rounded mb-5 w-full leading-normal break-words-p"
                >
                  {{ $t('chat_apps.templates.util_market_info') }}
                </div>
                <div class="flex justify-end items-center">
                  <div class="flex-auto">
                    <p class="text-grey-dark mb-2 text-xs">
                      {{ $t('chat_apps.templates.create[11]') }}
                    </p>
                  </div>
                  <div class="flex-shrink py-1">
                    <div class="bg-blue-lightest rounded overflow-hidden">
                      <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="Bold"
                            placement="top"
                            :enterable="false"
                            open-delay="500"
                          >
                            <span
                              class="inline-block cursor-pointer py-1 px-2"
                              :class="{ 'bg-blue-lighter shadow-inner': isActive.bold() }"
                              @click="commands.bold"
                            >
                              <strong>B</strong>
                            </span>
                          </el-tooltip>

                          <el-tooltip
                            class="item mx-px"
                            effect="dark"
                            content="Italic"
                            placement="top"
                            :enterable="false"
                            open-delay="500"
                          >
                            <span
                              class="inline-block cursor-pointer py-1 px-2"
                              :class="{ 'bg-blue-lighter shadow-inner': isActive.italic() }"
                              @click="commands.italic"
                            >
                              <i>I</i>
                            </span>
                          </el-tooltip>

                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="Strikethrough"
                            placement="top"
                            :enterable="false"
                            open-delay="500"
                          >
                            <span
                              class="inline-block cursor-pointer py-1 px-2"
                              :class="{ 'bg-blue-lighter shadow-inner': isActive.strike() }"
                              @click="commands.strike"
                            >
                              <strike>S</strike>
                            </span>
                          </el-tooltip>
                        </div>
                      </editor-menu-bar>
                    </div>
                  </div>
                  <div class="flex-shrink pl-5">
                    <el-button
                      :disabled="form.category === 'AUTHENTICATION'"
                      type="text"
                      @click="addVariable"
                    >
                      <i class="el-icon-plus font-black"></i>&nbsp;
                      {{ $t('chat_apps.templates.create[9]') }}
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="editor">
                <editor-content
                  ref="editor"
                  :style="
                    form.category === 'AUTHENTICATION'
                      ? { backgroundColor: '#f5f7fa', color: '#76787b' }
                      : {}
                  "
                  class="editor__content p-2 border-grey-light border rounded-sm"
                  :editor="editor"
                />
              </div>
              <div class="flex mt-1">
                <div class="flex-auto">
                  <p v-if="!form.messageContent.trim().length && xContent" class="text-xs text-red">
                    {{ $t('chat_apps.templates.create[12]') }}
                  </p>
                  <p v-if="isCharMaxLength(form.messageContent, 1024)" class="text-xs text-red">
                    {{ $t('chat_apps.templates.create[13]') }}
                  </p>
                  <p v-if="hasConsecutiveNewLines(form.messageContent)" class="text-xs text-red">
                    {{ $t('chat_apps.templates.create[27]') }}
                  </p>
                  <p
                    v-if="getVariablesNotInOrder(form.messageContent).length"
                    class="text-xs text-red"
                  >
                    {{ $t('chat_apps.templates.create[14]') }}
                    &nbsp;&nbsp;&nbsp;
                    {{ getVariablesNotInOrder(form.messageContent).join(', ') }}
                  </p>
                </div>
                <div class="flex-shrink">
                  <p class="text-grey-dark text-xs">
                    {{ getCharsLength(form.messageContent) }} / 1024
                  </p>
                </div>
              </div>
            </div>
            <div class="msg-footer">
              <p class="mb-2">
                <span class="text-sm font-medium">{{ $t('column_labels.footer') }}</span>
                <span class="bg-grey text-white text-xs py-1 px-3 ml-2 rounded font-9px">
                  {{ $t('validations.optional').toUpperCase() }}
                </span>
              </p>
              <p class="text-grey-dark mb-2 text-xs">
                {{ $t('chat_apps.templates.create[15]') }}
              </p>
              <el-form-item>
                <el-input
                  v-model="form.footer"
                  :disabled="Boolean(form.category === 'AUTHENTICATION')"
                  :placeholder="`${$t('chat_apps.templates.create[16]')} ${
                    lang[form.language]
                      ? $t('chat_apps.templates.create[17]', { language: lang[form.language] })
                      : ''
                  }`"
                  size="small"
                  :style="{ width: '60%' }"
                />

                <p
                  v-if="form.footer.trim() && form.footer.trim().length > 60 && xContent"
                  class="text-xs text-red"
                  :style="{ lineHeight: '20px' }"
                >
                  {{ $t('chat_apps.templates.create[25]') }}
                </p>
              </el-form-item>
            </div>
            <div class="msg-buttons">
              <p class="mb-2">
                <span class="text-sm font-medium">{{ $t('column_labels.buttons') }}</span>
                <span
                  v-if="form.category !== 'AUTHENTICATION'"
                  class="bg-grey text-white text-xs py-1 px-3 ml-2 rounded font-9px"
                >
                  {{ $t('validations.optional').toUpperCase() }}
                </span>
              </p>
              <p
                v-if="!isEmpty(form.buttons) && form.category !== 'AUTHENTICATION'"
                class="text-grey-dark mb-2 text-xs"
              >
                {{ $t('actions.create') }}&nbsp;
                {{
                  buttonTypesJson[form.buttons.type] && buttonTypesJson[form.buttons.type].max
                    ? `${$t('chat_apps.templates.create[18]')}
                ${buttonTypesJson[form.buttons.type].max}`
                    : ''
                }}
                &nbsp;{{ $t('chat_apps.templates.create[19]') }}
              </p>
              <custom-buttons
                v-if="ctVisible"
                v-model="form.buttons"
                :button-types-json="buttonTypesJson"
                :context="form"
                @change-footer-text="changeFooterText"
              />
            </div>
            <template-samples-form
              v-if="Boolean(form.category !== 'AUTHENTICATION')"
              :samples="form.samples"
              @apply-samples-data="updateSamples"
            />
          </el-col>
          <el-col :span="8" :style="{ marginTop: '50px' }">
            <p class="text-center text-xs mb-2 text-grey">
              {{ $t('mcs.message_preview') }}
            </p>
            <div
              class="preview-content rounded-lg p-2"
              :style="{ 'background-image': `url('${bgUrl}${bgImage}')` }"
            >
              <div
                v-if="form.messageContent || form.headerValue || form.footer"
                class="min-w-full bg-white shadow rounded-bl-lg rounded-r-lg p-2 text-xs max-h-full overflow-auto"
              >
                <div
                  v-if="form.header === 'text'"
                  class="break-words mb-3 text-black font-medium text-sm"
                >
                  {{ replaceCustomFieldsWithSamples(form.headerValue, 'header') }}
                </div>
                <div
                  v-if="form.header === 'media'"
                  class="break-words bg-grey-darker-custom text-center mb-3 rounded"
                >
                  <div
                    v-if="form.samples.length && form.samples.some((v) => v && v.media && v.value)"
                  >
                    <img
                      v-if="form.samples.find((v) => v && v.name === 'image')"
                      :src="form.samples.find((v) => v && v.name === 'image').value"
                      class="align-middle"
                      width="218"
                      height="150"
                    />

                    <video
                      v-if="form.samples.find((v) => v && v.name === 'video')"
                      :src="form.samples.find((v) => v && v.name === 'video').value"
                      class="align-middle"
                      width="218"
                      height="150"
                      controls="true"
                      muted="true"
                    />

                    <div
                      v-if="form.samples.find((v) => v && v.name === 'document' && v.value)"
                      :data="form.samples.find((v) => v && v.name === 'document').value"
                      class="flex justify-center items-center"
                    >
                      <div class="relative bg-white">
                        <img
                          :src="urlResolver(bgUrl, require('@/assets/misc/pdf.png'))"
                          :style="{ width: '218px', height: '160px' }"
                        />
                        <a
                          :href="form.samples.find((v) => v && v.name === 'document').value"
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                          :style="{ bottom: '0px' }"
                          class="text-left no-underline absolute block text-xs text-white px-1 py-2 bg-black opacity-75 w-full truncate"
                        >
                          {{
                            getFileNameInUrl(
                              form.samples.find((v) => v && v.name === 'document').value
                            )
                          }}
                        </a>
                      </div>
                    </div>
                    <div v-if="form.samples.find((v) => v && v.name === 'location')" class="mb-2">
                      <div :style="{ width: '100%', height: '150px' }">
                        <google-map
                          :lat="getLatLng(form.samples).lat"
                          :long="getLatLng(form.samples).lng"
                          :options="{ disableDefaultUI: true }"
                        />
                      </div>
                      <div class="block bg-grey-shade-custom text-xs text-left pl-2 pt-2 pb-1">
                        {{
                          form.samples.find((v) => v && v.name === 'location' && v.field === 'name')
                            .value
                        }}
                      </div>
                      <div
                        class="block bg-grey-shade-custom text-xs text-left text-grey-darker pl-2 pb-2"
                        :style="{ fontSize: '10px' }"
                      >
                        {{
                          form.samples.find(
                            (v) => v && v.name === 'location' && v.field === 'address'
                          ).value
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-6">
                    <img
                      :src="
                        urlResolver(
                          bgUrl,
                          require(`@/assets/media/${getPreviewMediaIcons(form.headerValue)}.svg`)
                        )
                      "
                      :alt="form.headerValue"
                      class="align-middle"
                      :style="{ width: '40px', height: '40px' }"
                    />
                  </div>
                </div>
                <div
                  class="inline-block whitespace-pre-wrap break-words"
                  :style="{ wordBreak: 'normal' }"
                  v-html="replaceCustomFieldsWithSamples(form.messageContent, 'body')"
                />
                <div
                  v-if="form.footer"
                  class="break-words mt-5 text-grey-darker"
                  :style="{ fontSize: '10px' }"
                >
                  {{ form.footer }}
                </div>
              </div>
              <div
                v-if="!isEmpty(form.buttons) && Object.keys(form.buttons).length"
                class="button-grid -mx-1"
              >
                <template v-if="['QUICK_REPLY', 'OTP'].includes(form.buttons.type)">
                  <div
                    v-for="(button, index) in form.buttons.items"
                    :key="index"
                    class="button-grid__item px-1 mt-2"
                  >
                    <div
                      class="text-xs rounded p-2 bg-white shadow text-grey text-center"
                      :class="button.text.length && 'text-blue'"
                    >
                      {{ button.text || $t('chat_apps.templates.create[20]') }}
                    </div>
                  </div>
                </template>
                <template v-else-if="form.buttons.type === 'CALL_TO_ACTION'">
                  <div
                    v-for="(button, index) in form.buttons.items"
                    :key="index"
                    class="button-grid__item button-grid__item--block px-1 mt-2"
                  >
                    <div
                      class="text-xs rounded p-2 bg-white shadow text-grey text-center"
                      :class="button.text.length && 'text-blue'"
                    >
                      {{ button.text || $t('chat_apps.templates.create[20]') }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="mt-5">
        <div>
          <el-button type="default" size="small" @click="closeModal">
            {{ $t('actions.cancel') }}
          </el-button>
          <el-button type="primary" :loading="loading" size="small" @click="submit">
            {{ $t('chat_apps.templates.create[21]') }}
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash'; // eslint-disable-line
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import bgImage from '@/assets/whatsapp-chat-bg.png';
import buttonTypesJson from '@/json/custom-button-types.json';
import fixedCategories from '@/json/fixed-categories.json';

import { Bold, Italic, Strike, Placeholder, HardBreak } from 'tiptap-extensions';

import GoogleMap from '@/components/common/GoogleMap.vue';

import MediaMixin from '@/mixins/media';

import {
  transformToWAValidTemplate,
  transformToHTML,
  insertAtCursor,
  urlResolver,
  removeUnicodeChars,
  formatReadableButtonValues,
  getBrackets,
  replaceCustomFields,
} from '@/utils/common';
import CustomButtons from './CustomButtons.vue';
import TemplateSamplesForm from './TemplateSamplesForm.vue';

export default {
  name: 'CreateTemplate',

  components: {
    EditorContent,
    EditorMenuBar,
    CustomButtons,
    TemplateSamplesForm,
    GoogleMap,
  },

  mixins: [MediaMixin],

  props: {
    filters: {
      default: () => ({}),
      type: Object,
    },

    enums: {
      default: () => ({}),
      type: Object,
    },

    waChannels: {
      default: () => [],
      type: Array,
    },

    ctVisible: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    createTemplate: {
      default: () => {},
      type: Function,
    },

    defaultFormValue: {
      default: () => {},
      type: Object,
    },

    validateName: {
      default: false,
      type: Boolean,
    },

    getDuplicateName: {
      default: () => {},
      type: Function,
    },
  },

  data() {
    const self = this;

    // eslint-disable-next-line no-unused-vars
    const checkName = (rule, value, callback) => {
      const isValid = this.validateName(value);
      if (!isValid) {
        callback(
          new Error(this.$t('validations.taken', { value: this.$t('column_labels.template_name') }))
        );
      } else {
        callback();
      }
    };

    return {
      bgImage,
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
      lang: {},
      category: {},
      delay: null,
      xContent: false,
      maxButtons: 3,
      buttonTypesJson,
      isEmpty: _.isEmpty,
      fixedCategories,
      footerText: 'Not interested? Tap Stop promotions',
      otpBodyText: '{{1}} is your verification code.',
      securityText: 'For your security, do not share this code.',

      form: {
        name: '',
        language: 'en_US',
        category: '',
        channelId: '',
        messageContent: '',
        securityRecommendation: false,
        header: '',
        headerValue: '',
        footer: '',
        footerExpiryInMins: 0,
        footerExpiry: false,
        buttons: {},
        samples: [],
        allowCategoryChange: true,
      },

      headers: ['none', 'text', 'media'],
      headerMedia: ['image', 'video', 'document', 'location'],

      editor: new Editor({
        extensions: [
          new HardBreak(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: self.$t('chat_apps.templates.create[22]'),
            showOnlyWhenEditable: true,
          }),
        ],
        content: '',
        onUpdate() {
          self.previewContent();
        },
      }),
    };
  },

  watch: {
    'form.headerValue': {
      handler(headerValue) {
        if (this.form.header === 'text' && headerValue) {
          this.form.headerValue = removeUnicodeChars(headerValue);
        }

        // reset first
        if (this.form.samples.length) {
          this.form.samples = this.form.samples.filter((v) => v.type !== 'header');
        }

        if (this.form.header && headerValue) {
          const { header } = this.form;
          if (header && headerValue) {
            if (header === 'text') {
              const brackets = getBrackets(headerValue);

              if (brackets && brackets.length) {
                brackets.forEach((v) => {
                  this.form.samples.push({
                    label: `[Header] ${header.toUpperCase()} ${v}`,
                    value: '',
                    name: 'text',
                    field: v,
                    type: 'header',
                    media: false,
                  });
                });
              }
            } else {
              if (headerValue === 'location') {
                this.form.samples.push(
                  {
                    label: `[Header] ${headerValue.toUpperCase()}  (${this.$t(
                      'fields.latitude'
                    )}, ${this.$t('fields.longitude')})`,
                    value: '',
                    name: headerValue,
                    field: 'latlong',
                    type: 'header',
                    media: true,
                  },
                  {
                    label: `[Header] ${headerValue.toUpperCase()}  ${this.$t(
                      'column_labels.name'
                    )}`,
                    value: '',
                    name: headerValue,
                    field: 'name',
                    type: 'header',
                    media: true,
                  },
                  {
                    label: `[Header] ${headerValue.toUpperCase()}  ${this.$t(
                      'column_labels.address'
                    )}`,
                    value: '',
                    name: headerValue,
                    field: 'address',
                    type: 'header',
                    media: true,
                  }
                );
              } else {
                this.form.samples.push({
                  label: `[Header] ${headerValue.toUpperCase()}`,
                  value: '',
                  name: headerValue,
                  field: '',
                  type: 'header',
                  media: true,
                });
              }
            }
          }
        }
      },
    },

    'form.header': {
      handler() {
        // if header changes, remove all header samples

        if (this.form.samples.length) {
          this.form.samples = this.form.samples.filter((v) => v.type !== 'header');
        }
      },
    },

    'form.footer': {
      handler(n) {
        if (n) {
          this.form.footer = removeUnicodeChars(n);
        }
      },
    },

    'form.messageContent': {
      handler(messageContent) {
        // reset first
        if (this.form.samples.length) {
          this.form.samples = this.form.samples.filter((v) => v.type !== 'body');
        }

        if (messageContent && getBrackets(messageContent) && getBrackets(messageContent).length) {
          const brackets = getBrackets(messageContent);
          if (brackets && brackets.length) {
            const variables = brackets.map((v) =>
              parseInt(v.replace('{{', '').replace('}}', ''), 10)
            );
            variables.forEach((v) => {
              this.form.samples.push({
                label: `[Body] {{${v}}}`,
                value: '',
                name: 'body',
                field: `{{${v}}}`,
                type: 'body',
              });
            });
          }
        }
      },
    },

    'form.buttons': {
      handler(buttons) {
        // reset
        if (this.form.samples.length) {
          this.form.samples = this.form.samples.filter((v) => v && v.type !== 'buttons');
        }

        if (buttons && !_.isEmpty(buttons) && Object.keys(buttons).length) {
          if (buttons.type === 'CALL_TO_ACTION' && buttons.items && buttons.items.length) {
            const hasDynamicUrl = buttons.items.find(
              (v) => v && v.type === 'URL' && v.urlType === 'DYNAMIC'
            );
            // automatic field is {{1}}

            if (hasDynamicUrl) {
              this.form.samples.push({
                label: '[Button - DYNAMIC URL]',
                value: '',
                name: 'url',
                field: '{{1}}',
                type: 'buttons',
                url: hasDynamicUrl.url,
              });
            }
          }
        }
      },
      deep: true,
    },

    'form.securityRecommendation': {
      handler(bool) {
        let text = `${this.otpBodyText} ${this.securityText}`;
        if (!bool) {
          text = this.otpBodyText;
        }

        // replace content
        this.form.messageContent = text;
        this.editor.setContent(text);
      },
    },

    'form.category': {
      handler(val) {
        this.form.messageContent = '';
        this.form.footer = '';
        this.form.headerValue = '';
        this.form.header = 'none';
        this.form.buttons = {};
        this.form.securityRecommendation = false;
        this.form.footerExpiryInMins = 0;
        this.form.footerExpiry = false;
        this.form.footer = '';
        if (val !== 'AUTHENTICATION') {
          this.editor.setContent('');

          this.editor.setOptions({ editable: true });
        } else {
          this.form.messageContent = this.otpBodyText;
          this.editor.setContent(this.otpBodyText);
          this.editor.setOptions({ editable: false });
        }
      },
    },

    'form.footerExpiry': {
      handler(bool) {
        if (bool) {
          this.form.footerExpiryInMins = 5;
          this.form.footer = `This code expires in ${this.form.footerExpiryInMins} minutes`;
        } else {
          this.form.footerExpiryInMins = 0;
          this.form.footer = '';
        }
      },
    },
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    modalTitle() {
      let s = this.$t('chat_apps.templates.create[23]');
      const { type } = this.defaultFormValue;
      if (type === 'DUPLICATE') {
        s = this.$t('chat_apps.templates.create[24]');
      }
      return s;
    },

    onChangeFooterExpiry(v) {
      this.form.footer = `This code expires in ${v} minutes`;
    },

    changeFooterText(text = '') {
      if (text && text === this.footerText) {
        this.form.footer = this.footerText;
      } else if (this.form.footer === this.footerText && text === '') {
        this.form.footer = '';
      }
    },

    getLatLng(samples) {
      let latlng = {
        lat: '',
        lng: '',
      };

      const location = samples.find(
        (v) =>
          v &&
          v.field === 'latlong' &&
          v.value &&
          v.value.match(/^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/)
      );

      if (location) {
        const ll = location.value.split(',');

        latlng = {
          lat: ll[0],
          lng: ll[1],
        };
      }

      return latlng;
    },

    replaceCustomFieldsWithSamples(content, type) {
      let text = content;
      let samples = [];

      if (this.form.samples.length) {
        if (type === 'header') {
          samples = this.form.samples.filter((v) => v && v.name === 'text');
        }

        if (type === 'body') {
          samples = this.form.samples.filter((v) => v && v.name === 'body');
        }

        if (samples.length) {
          samples.forEach((v) => {
            text = replaceCustomFields(text, v.field, this.$sanitize(v.value, { allowedTags: [] }));
          });
        }
      }

      return text;
    },

    urlResolver(url, imgUrl) {
      return urlResolver(url, imgUrl);
    },

    selectHeader(val) {
      switch (val) {
        case 'media': {
          this.form.headerValue = 'image';
          break;
        }

        case 'text': {
          this.form.headerValue = '';
          break;
        }
        default: {
          this.form.headerValue = '';
        }
      }
    },

    getCategoryIcons(category, isActive) {
      const activeColor = isActive ? 'black' : 'gray';

      let icon = `auto-reply-${activeColor}`;

      let ct = category.toLowerCase().replace(/_/g, '-');

      if (ct === 'transportation-update') {
        ct = 'shipping-update';
      }

      const isAvailable = Object.keys(this.category).some((v) => {
        const cty = v.toLowerCase().replace('_', '-');

        return cty === ct;
      });

      if (isAvailable) {
        icon = `${ct}-${activeColor}`;
      }

      return icon;
    },

    getMediaIcons(type, isActive) {
      const activeColor = isActive ? 'green' : 'dark';
      let icon = `image-${activeColor}`;

      if (this.headerMedia.includes(type)) {
        icon = `${type}-${activeColor}`;
      }

      return icon;
    },

    getPreviewMediaIcons(type) {
      const activeColor = 'light';
      let icon = `image-${activeColor}`;

      if (this.headerMedia.includes(type)) {
        icon = `${type}-${activeColor}`;
      }

      return icon;
    },

    addHeaderVariable() {
      const vars = '{{1}}';

      const el = this.$refs.headerInput;

      insertAtCursor(el, vars);

      this.form.headerValue = el.value;
    },

    hasVariable() {
      return this.form.headerValue.match(/\{\{1\}\}/g);
    },

    previewContent() {
      // replace empty paragraph with new line
      const c = this.editor
        .getHTML()
        .replace(/<br\s*(\s+[^>]+)*[/]?>/gi, '<p></p>')
        .replace(/<\/p>/g, '\n')
        .replace(/<p>/g, '');

      this.xContent = true;

      this.form.messageContent = this.$sanitize(c.trim());
    },

    addVariable() {
      let vars = '{{1}}';

      const content = this.form.messageContent;
      const matchBrackets = getBrackets(content);

      if (matchBrackets) {
        const variables = matchBrackets.map((v) =>
          parseInt(v.replace('{{', '').replace('}}', ''), 10)
        );

        const largest = Math.max.apply(null, variables);

        const seq = largest + 1;

        vars = `{{${seq}}}`;
      }

      const transaction = this.editor.state.tr.insertText(vars);
      this.editor.view.dispatch(transaction);
    },

    getVariablesNotInOrder(content) {
      const matchBrackets = getBrackets(content);

      if (matchBrackets) {
        const variables = matchBrackets.map((v) =>
          parseInt(v.replace('{{', '').replace('}}', ''), 10)
        );

        const largest = Math.max.apply(null, variables);

        const basis = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= largest; i++) {
          basis.push(i);
        }

        const diff = _.difference(basis, variables).map((v) => `{{${v}}}`);

        return diff;
      }

      return [];
    },

    sortCategories(category = {}) {
      const sorted = Object.keys(category).sort();

      const sortedCategory = sorted.reduce((a, b) => {
        // eslint-disable-next-line no-param-reassign
        a[b] = category[b];
        return a;
      }, {});

      return {
        sorted: sortedCategory,
        initValue: sorted[0],
      };
    },

    getFormValues(components = []) {
      const form = components.reduce(
        (a, b) => {
          if (b.type === 'BODY') {
            // eslint-disable-next-line no-param-reassign
            a.body = b.text;
            a.securityRecommendation = b.add_security_recommendation;
          }

          if (b.type === 'FOOTER') {
            // eslint-disable-next-line no-param-reassign
            a.footer = b.text;
            a.footerExpiryInMins = b.code_expiration_minutes
              ? parseInt(b.code_expiration_minutes, 10)
              : 0;
            a.footerExpiry = Boolean(b.code_expiration_minutes);
          }

          if (b.type === 'HEADER') {
            // eslint-disable-next-line no-param-reassign
            if (b.format.toLowerCase() === 'text') {
              // eslint-disable-next-line no-param-reassign
              a.header = b.text ? 'text' : 'none';
              // eslint-disable-next-line no-param-reassign
              a.headerValue = b.text;
            } else {
              // eslint-disable-next-line no-param-reassign
              a.header = 'media';
              // eslint-disable-next-line no-param-reassign
              a.headerValue = b.format.toLowerCase();
            }
          }

          if (b.type === 'BUTTONS') {
            const buttonValue = formatReadableButtonValues(b);
            // eslint-disable-next-line no-param-reassign
            a.buttons = { ...buttonValue };
          }

          return a;
        },
        {
          header: 'none',
          headerValue: '',
          footer: '',
          body: '',
          securityRecommendation: false,
          footerExpiryInMins: 0,
          footerExpiry: false,
          buttons: {},
        }
      );

      return form;
    },

    resetForm() {
      this.lang = this.enums.language || {};
      const { sorted } = this.sortCategories(this.enums.category);
      this.category = sorted || {};
      this.xContent = false;

      if (Object.keys(this.defaultFormValue).length) {
        const { templateName, category, language, type, components } = this.defaultFormValue;

        const {
          header,
          headerValue,
          footer,
          body,
          buttons,
          securityRecommendation,
          footerExpiryInMins,
          footerExpiry,
          allowCategoryChange,
        } = this.getFormValues(components);

        this.form.name = type === 'DUPLICATE' ? this.getDuplicateName(templateName) : templateName;
        this.form.category = category || '';
        this.form.language = language || '';
        this.form.channelId = this.filters.channelId || '';
        this.editor.setContent(this.setDisplayContent(body));
        this.form.messageContent = this.setDisplayContent(body).replace(/<p><\/p>/g, '\n');
        this.form.footer = _.unescape(this.$sanitize(footer));
        this.form.header = _.unescape(this.$sanitize(header));
        this.form.headerValue = headerValue;
        this.form.buttons = buttons;
        this.form.securityRecommendation = securityRecommendation;
        this.form.footerExpiryInMins = footerExpiryInMins;
        this.footerExpiry = footerExpiry;
        this.form.allowCategoryChange = allowCategoryChange;
      } else {
        this.form.name = '';
        this.form.category = '';
        this.form.language = '';
        this.form.channelId = '';
        this.form.messageContent = '';
        this.form.footer = '';
        this.form.headerValue = '';
        this.form.header = 'none';
        this.form.buttons = {};
        this.form.samples = [];
        this.form.securityRecommendation = false;
        this.form.footerExpiry = false;
        this.form.footerExpiryInMins = 0;
        this.form.allowCategoryChange = true;
      }
    },

    setDisplayContent(c) {
      let t = _.unescape(this.$sanitize(c.trim().replace(/\n/g, '<p></p>')));

      t = transformToHTML(t);

      return t;
    },

    closeModal() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.form.messageContent = '';
      this.editor.clearContent();
      this.$emit('close');

      if (this.delay) clearTimeout(this.delay);
    },

    submit() {
      this.xContent = true;

      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (
            valid &&
            this.form.messageContent &&
            !this.getVariablesNotInOrder(this.form.messageContent).length &&
            // eslint-disable-next-line max-len
            !this.isCharMaxLength(this.form.messageContent, 1024) &&
            !this.hasConsecutiveNewLines(this.form.messageContent)
          ) {
            const {
              name,
              category,
              language,
              channelId,
              headerValue,
              header,
              footer,
              buttons,
              samples,
              securityRecommendation,
              footerExpiryInMins,
              footerExpiry,
              allowCategoryChange,
            } = this.form;

            if (footer && footer.trim() && footer.trim().length > 60) {
              this.$message.error(this.$t('validations.required_fields'));
              return false;
            }

            let hVal;

            if (header === 'text') {
              if (
                !headerValue ||
                (headerValue && headerValue.trim() && headerValue.trim().length > 60)
              ) {
                this.$message.error(this.$t('validations.required_fields'));
                return false;
              }
              hVal = {
                type: header,
                value: _.unescape(this.$sanitize(headerValue.trim(), { allowedTags: [] })),
              };
            }

            if (this.headerMedia.includes(headerValue) && header === 'media') {
              hVal = {
                type: headerValue,
                // no value for media types
                value: '',
              };
            }

            if (
              hVal &&
              samples.length &&
              samples.some((v) => v && v.value && v.type === 'header')
            ) {
              hVal.examples = samples
                .slice(0)
                .filter((v) => v.type === 'header')
                .map((v) => v.value);
            }

            // Format buttons values to fit payload
            let buttonsVal;

            if (['QUICK_REPLY', 'OTP'].includes(buttons.type)) {
              const buttonTexts = buttons.items.reduce((acc, item) => {
                acc.push(item.text);
                return acc;
              }, []);

              buttonsVal = {
                type: buttons.type,
                items: [...buttonTexts],
              };
            } else if (buttons.type === 'CALL_TO_ACTION') {
              const buttonItems = buttons.items.reduce((acc, item) => {
                const { type, text, urlType, url, phone, country } = item;

                let ctaButton = {};

                if (type === 'URL') {
                  const dynamicVariable = '/{{1}}';
                  ctaButton = {
                    type,
                    text,
                    value: `${url}${urlType === 'DYNAMIC' ? dynamicVariable : ''}`,
                  };

                  if (samples.length && samples.some((v) => v && v.value && v.name === 'url')) {
                    ctaButton.examples = samples
                      .slice(0)
                      .filter((v) => v.name === 'url')
                      .map((v) => v.value);
                  }
                } else if (type === 'PHONE_NUMBER') {
                  ctaButton = {
                    type,
                    text,
                    value: phone,
                    country,
                  };
                }

                acc.push(ctaButton);
                return acc;
              }, []);

              buttonsVal = {
                type: buttons.type,
                items: [...buttonItems],
              };
            }

            const payload = {
              templateName: name,
              category,
              allowCategoryChange,
              language,
              channelId,
              header: hVal,
              footer: _.unescape(this.$sanitize(footer.trim(), { allowedTags: [] })),
              // sanitize first, before unescaping special chars
              body: _.unescape(
                this.$sanitize(transformToWAValidTemplate(this.form.messageContent), {
                  allowedTags: [],
                })
              ),
              addSecurityRecommendation: securityRecommendation,
              buttons: buttonsVal ? { ...buttonsVal } : undefined,
            };

            if (footerExpiryInMins && footerExpiry) {
              payload.otpExpiryInMins = parseInt(footerExpiryInMins, 10);
            }

            if (samples.length && samples.some((v) => v && v.value && v.name === 'body')) {
              payload.examples = samples
                .slice(0)
                .filter((v) => v.name === 'body')
                .map((v) => v.value);
            }

            this.$emit('create-template', payload);

            if (this.delay) {
              clearTimeout(this.delay);
            }

            // this.delay = setTimeout(() => {
            //   this.closeModal();
            // }, 1000);
          } else {
            this.$message.error(this.$t('validations.required_fields'));
            return false;
          }
          return false;
        });
      }
    },

    getCharsLength(content) {
      if (!content) return 0;
      if (content.trim() && !content.trim().length) return 0;

      const c = _.unescape(
        this.$sanitize(transformToWAValidTemplate(content.trim()), { allowedTags: [] })
      );

      return c.length;
    },

    isCharMaxLength(content, maxNo) {
      if (!content) return false;
      if (content.trim() && !content.trim().length) return false;

      return this.getCharsLength(content) > maxNo;
    },

    hasConsecutiveNewLines(content) {
      if (!content) return false;
      if (content.trim() && !content.trim().length) return false;

      const c = _.unescape(
        this.$sanitize(transformToWAValidTemplate(content.trim()), { allowedTags: [] })
      );

      return _.includes(c, '\n\n\n\n');
    },

    updateSamples(rows) {
      this.form.samples = rows;
    },
  },
};
</script>

<style lang="scss">
.button-grid {
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex-grow: 1;
    min-width: 50%;

    &--block {
      width: 100%;
      min-width: 0;
    }
  }
}

.template-modal {
  .el-dialog__headerbtn {
    top: 20px;
    right: 95px;
    font-size: 24px;
  }
}

.create-template {
  &__info-icon {
    margin-left: 3px;
    font-size: 10px;
    cursor: pointer;
  }

  .font-9px {
    font-size: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .el-form--label-top .el-form-item__label {
    padding-bottom: 0;
    font-size: 0.75rem;
    line-height: 30px;
  }

  .sel {
    width: 60%;
  }

  .editor__content {
    [contenteditable='true'] {
      height: 108px;
      outline: none;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 0.75rem;
      user-select: text;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: normal;
      -webkit-user-modify: read-write-plaintext-only;
    }
  }

  .editor__content {
    [contenteditable='false'] {
      min-height: 120px;
    }
  }

  .editor p.is-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    color: #dcdfe6;
    pointer-events: none;
    height: 0;
    font-size: 0.75rem;
  }

  .el-button {
    outline: none;
    font-size: 0.75rem;
  }

  .preview-content {
    background-color: #ede7df;
    background-position: center;
    background-size: cover;
    height: 400px;

    p {
      max-width: 15rem;
      word-break: normal;
    }
  }

  .mc-label {
    &:before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #67c23a;
    background-color: #38c172;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #333333;
  }

  .form-extended {
    margin-bottom: 5px;
  }

  .media-item {
    .el-radio__label {
      display: none;
    }

    .el-form-item__content {
      line-height: 30px;
    }
  }

  .bg-grey-custom {
    background-color: #f6f6f6;
  }

  .bg-grey-shade-custom {
    background-color: #f5f5f5;
  }

  .bg-grey-darker-custom {
    background-color: #9d9d9d;
  }
}
.cat-desc,
.break-words-p {
  word-break: break-word !important;
}
</style>
