<template>
  <el-dialog
    v-loading="loading"
    :visible.sync="ciVisible"
    :show-close="false"
    destroy-on-close
    fullscreen
    center
    @close="closeModal"
  >
    <div v-if="!done" class="channel-form">
      <scope name="title">
        <div class="flex justify-between items-center mb-12 sticky pin-t bg-white z-50">
          <h1 class="text-xl text-black font-normal">
            <span v-if="selectedChannel.code === 'WA'">
              {{ $t('chat_apps.channels.form[6]') }}
            </span>
            <!-- Request for {{ getChannelName(selectedChannel.code) }} access -->
            <span v-if="selectedChannel.code !== 'WA'">
              {{
                $t('chat_apps.channels.form[0]',
                { code: getChannelName(selectedChannel.code)})
              }}
            </span>
          </h1>
          <i
            class="text-xl el-icon-close cursor-pointer hover:text-blue"
            @click="closeModal"
          ></i>
        </div>
      </scope>
      <div class="channel-instance mx-5">
        <component
          :is="formComponent"
          :channel="selectedChannel"
          :subaccount-list="subaccountList"
          @submit="submitForm"
        ></component>
      </div>
    </div>

    <div v-else class="channel-message">
      <div class="channel-message__body h-full">
        <h2 class="text-xl text-black font-normal mb-8">
          {{ $t('chat_apps.channels.form[1]') }}
        </h2>
        <p class="word-break text-sm text-grey-darkest mb-5">
          <!-- We've received your request to access
          {{ selectedChannel.name }} through our Chat Apps
          Api. -->
          {{ $t('chat_apps.channels.form[2]', { name: selectedChannel.name }) }}
        </p>
        <p class="word-break text-sm text-grey-darkest mb-5">
          {{ $t('chat_apps.channels.form[3]') }}
        </p>
        <p class="word-break text-sm text-grey-darkest mb-5">
           {{ $t('chat_apps.channels.form[4]') }}
        </p>
        <div class="mt-16 block">
          <el-button
            type="primary"
            size="small"
            @click="closeModal"
            >
             {{ $t('chat_apps.channels.form[5]') }}
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import channelsJson from '@/json/channels.json';

import LineForm from './forms/Line.vue';
import MessengerForm from './forms/Messenger.vue';
import ViberForm from './forms/Viber.vue';
import WeChatForm from './forms/WeChat.vue';
import WhatsAppForm from './forms/WhatsApp.vue';
import ZaloForm from './forms/Zalo.vue';

export default {
  name: 'channel-instance',

  props: {
    selectedChannel: {
      default: () => {},
      type: Object,
    },
    ciVisible: {
      default: false,
      type: Boolean,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
    submit: {
      default: () => {},
      type: Function,
    },
  },

  computed: {
    formComponent() {
      const { code } = this.selectedChannel;
      let form = null;
      switch (code) {
        case 'ZN':
          form = ZaloForm;
          break;
        case 'LN':
          form = LineForm;
          break;
        case 'WC':
          form = WeChatForm;
          break;
        case 'FB':
          form = MessengerForm;
          break;
        case 'VB':
          form = ViberForm;
          break;
        case 'WA':
          form = WhatsAppForm;
          break;
        default:
          break;
      }
      return form;
    },
  },

  data() {
    return {
      channelsJson,
      done: false,
      loading: false,
    };
  },

  created() {
    this.done = false;
    this.loading = false;
  },

  methods: {
    getChannelName(code) {
      return this.channelsJson.find(c => c.code === code).name;
    },

    closeModal() {
      this.$emit('close');
    },

    async submitForm(form) {
      this.loading = true;
      try {
        await this.submit(form);

        if (this.selectedChannel && this.selectedChannel.code === 'WA') {
          this.closeModal();
        } else {
          this.done = true;
        }
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error('Failed to submit for approval. Please try again.');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.word-break {
  word-break: break-word;
}

.channel-form {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}

.channel-message {
  position: relative;
  margin: 0 auto;
  width: 100%;

  &__body {
    margin: 0 auto;
    max-width: 500px;
  }
}
</style>
