<template>
  <div class="relative leading-none">
    <el-select
      v-model="buttonType"
      :disabled="Boolean(context.category === 'AUTHENTICATION')"
      :placeholder="$t('chat_apps.templates.custom_buttons[0]')"
      size="small"
      filterable
      @change="resetField()"
    >
      <el-option
        v-for="button in buttonTypeOptions"
        :key="button"
        :disabled="Boolean(button === 'OTP')"
        :label="
          button === 'OTP'
            ? $t('chat_apps.categories.OTP')
            : $t(`chat_apps.button_types.${buttonTypesJson[button].label}`)
        "
        :value="button"
      ></el-option>
    </el-select>

    <div class="mt-5">
      <div v-for="(field, index) in buttons" :key="index" class="relative">
        <!-- CALL TO ACTION INPUT FIELD -->
        <div
          v-if="buttonType === 'CALL_TO_ACTION'"
          class="relative mb-5 flex items-center"
          draggable
          @dragstart="onDrag($event, index)"
          @drop="onDrop($event, index)"
          @dragover.prevent
          @dragenter.prevent
        >
          <i v-if="buttons.length > 1" class="el-icon-more drag-icon text-grey"></i>
          <div class="p-4 border rounded bg-grey-lightest w-full">
            <div class="flex -m-2">
              <div class="relative w-full p-2">
                <span class="text-xs font-bold mb-1 block">
                  {{ $t('column_labels.type_of_action') }}&nbsp;
                </span>
                <el-form-item
                  :prop="'buttons.items.' + index + '.type'"
                  :rules="{
                    required: true,
                    message: $t('validations.select', {
                      value: $t('column_labels.type_of_action'),
                    }),
                    trigger: ['change', 'blur'],
                  }"
                >
                  <el-select
                    v-model="field.type"
                    :disabled="buttons.length >= buttonTypesJson[buttonType].max"
                    :placeholder="$t('chat_apps.templates.custom_buttons[1]')"
                    size="small"
                    class="w-full"
                    @change="resetCallToActionFields(index)"
                  >
                    <el-option
                      v-for="button in buttonActionOptions"
                      :key="button"
                      :label="$t(`chat_apps.button_actions.${buttonActionsJson[button].label}`)"
                      :value="button"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="relative w-full p-2">
                <span class="text-xs font-bold mb-1 block"
                  >&nbsp; {{ $t('column_labels.button_text') }}&nbsp;
                </span>
                <el-form-item
                  :prop="'buttons.items.' + index + '.text'"
                  :rules="[
                    {
                      required: true,
                      message: $t('validations.required', {
                        value: $t('column_labels.button_text'),
                      }),
                      trigger: ['change', 'blur'],
                    },
                    {
                      validator: checkDuplicateButtonText,
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-input
                    v-model="field.text"
                    size="small"
                    type="text"
                    :placeholder="$t('column_labels.button_text')"
                    maxlength="20"
                    class="w-full"
                    show-word-limit
                  />
                </el-form-item>
              </div>
              <div v-if="field.type === 'URL'" class="relative flex-no-shrink w-32 p-2">
                <span class="text-xs font-bold mb-1 block">{{ $t('column_labels.url_type') }}</span>
                <el-form-item
                  :prop="'buttons.items.' + index + '.urlType'"
                  :rules="{
                    required: true,
                    message: $t('validations.required', { value: $t('column_labels.url_type') }),
                    trigger: ['change', 'blur'],
                  }"
                >
                  <el-select v-model="field.urlType" size="small" class="w-full" filterable>
                    <el-option
                      v-for="buttonType in Object.keys(buttonActionsJson['URL'].types)"
                      :key="buttonType"
                      :label="
                        $t(
                          `chat_apps.button_actions.${buttonActionsJson['URL'].types[buttonType].label}`
                        )
                      "
                      :value="buttonType"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                v-else-if="field.type === 'PHONE_NUMBER'"
                class="relative flex-no-shrink w-32 p-2"
              >
                <span class="text-xs font-bold mb-1 block">{{ $t('column_labels.country') }}</span>
                <el-form-item
                  :prop="'buttons.items.' + index + '.country'"
                  :rules="[
                    {
                      required: true,
                      message: $t('validations.required', { value: $t('column_labels.country') }),
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-select v-model="field.country" size="small" class="w-full" filterable>
                    <el-option
                      v-for="country in [
                        { name: $t('app_labels.none'), value: '' },
                        ...countriesJson,
                      ]"
                      :key="country.value"
                      :label="`${country.value || $t('app_labels.none')} ${
                        country.callingCode || ''
                      }`"
                      :value="country.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div v-if="field.type === 'URL'" class="relative w-full p-2">
                <span class="text-xs font-bold mb-1 block">
                  {{ $t('column_labels.website_url') }}
                </span>
                <el-form-item
                  :prop="'buttons.items.' + index + '.url'"
                  :rules="[
                    {
                      required: true,
                      message: $t('validations.required', {
                        value: $t('column_labels.website_url'),
                      }),
                      trigger: ['change', 'blur'],
                    },
                    {
                      type: 'url',
                      message: $t('validations.valid', { value: $t('column_labels.website_url') }),
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-input
                    v-model.trim="field.url"
                    size="small"
                    type="url"
                    placeholder="https://www.website-name.com"
                    maxlength="2000"
                    class="w-full"
                  >
                    <i v-if="field.urlType === 'DYNAMIC'" slot="suffix">
                      <el-tooltip
                        effect="dark"
                        :content="$t('chat_apps.templates.custom_buttons[2]')"
                        placement="top"
                      >
                        <span class="text-xs font-normal roman dynamic-text text-black">
                          {{ `/\{\{1\}\}` }}
                        </span>
                      </el-tooltip>
                    </i>
                  </el-input>
                </el-form-item>
              </div>
              <div v-else-if="field.type === 'PHONE_NUMBER'" class="relative w-full p-2">
                <span class="text-xs font-bold mb-1 block">{{ $t('column_labels.phone_no') }}</span>
                <el-form-item
                  :prop="'buttons.items.' + index + '.phone'"
                  :rules="[
                    {
                      required: true,
                      message: $t('validations.required', { value: $t('column_labels.phone_no') }),
                      trigger: ['change', 'blur'],
                    },
                    {
                      validator: validPhoneNumber,
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="field.phone"
                    size="small"
                    type="text"
                    :placeholder="$t('column_labels.phone_no')"
                    maxlength="20"
                    class="w-full"
                    show-word-limit
                    @change="(e) => setRegionViaPhone(e, index)"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <a v-if="buttons.length > 1" class="text-sm ml-2" @click.prevent="removeField(index)">
            <i class="el-icon-close"></i>
          </a>
        </div>
        <!-- QUICK REPLY INPUT FIELD -->
        <div v-else-if="buttonType === 'QUICK_REPLY' || buttonType === 'OTP'" class="relative mb-5">
          <span class="text-xs font-bold mb-1 block">{{ $t('column_labels.button_text') }}</span>
          <div class="flex">
            <el-form-item
              v-if="context.category === 'MARKETING'"
              :prop="'buttons.items.' + index + '.type'"
              class="mr-3"
            >
              <el-select
                v-model="field.type"
                size="small"
                type="text"
                @change="(ev) => changeQuickReplyTypes(ev, index)"
              >
                <el-option
                  v-for="(v, i) in marketingButtonTypes"
                  :key="i"
                  :label="v.label"
                  :value="v.name"
                  :disabled="
                    v.name === 'MARKETING_OPT_OUT' &&
                    buttons.some((b) => b.type === 'MARKETING_OPT_OUT' && b.text === optOutText)
                  "
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :prop="'buttons.items.' + index + '.text'"
              :rules="[
                {
                  required: true,
                  message: $t('validations.required', { value: $t('column_labels.button_text') }),
                  trigger: ['change', 'blur'],
                },
                {
                  validator: checkDuplicateButtonText,
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-input
                v-model="field.text"
                :disabled="Boolean(context.category === 'AUTHENTICATION')"
                size="small"
                type="text"
                :placeholder="$t('column_labels.button_text')"
                maxlength="20"
                class="w-64"
                show-word-limit
              />
              <a v-if="buttons.length > 1" class="text-sm ml-2" @click.prevent="removeField(index)">
                <i class="el-icon-close"></i>
              </a>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="buttons.length > 0 && buttons.length < buttonTypesJson[buttonType].max"
      class="mt-5 mb-5"
    >
      <el-button type="default" icon="el-icon-plus" size="mini" @click="addField()">
        {{ $t('chat_apps.templates.custom_buttons[3]') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import countriesJson from '@/json/countries.json';
import buttonActionsJson from '@/json/button-actions.json';
import { parsePhoneNumber } from 'awesome-phonenumber';

export default {
  name: 'CustomButtons',

  props: {
    value: {
      type: Object,
      required: true,
    },

    buttonTypesJson: {
      type: Object,
      required: true,
    },

    context: {
      type: Object,
      default: () => {},
    },

    validateButtons: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const buttonTypeOptions = Object.keys(this.buttonTypesJson);
    const buttonActionOptions = Object.keys(buttonActionsJson);

    // validate if button text doesnt have a duplicate
    const checkDuplicateButtonText = (rule, value, callback) => {
      if (!Object.keys(value).length) return 0;

      const buttonTextValues = this.buttons.map((button) => button.text);
      const hasDuplicateText = new Set(buttonTextValues).size !== buttonTextValues.length;

      if (hasDuplicateText) {
        callback(
          new Error(this.$t('validations.taken', { value: this.$t('column_labels.button_name') }))
        );
      } else {
        callback();
      }

      return 0;
    };

    const validPhoneNumber = (rules, value, callback) => {
      const index = rules.field.split('.')[2]; // this is where index is stored
      const { country } = this.buttons[index];

      const pn = parsePhoneNumber(value, { regionCode: country });

      if (!pn.valid) {
        callback(
          new Error(this.$t('validations.valid', { value: this.$t('column_labels.phone_no') }))
        );
      } else {
        callback();
      }

      return 0;
    };

    const self = this;

    return {
      countriesJson,
      buttonActionsJson,
      buttonActionOptions,

      buttonTypeOptions,
      buttonType: '',

      checkDuplicateButtonText,
      validPhoneNumber,

      optOutText: 'Stop promotions',
      footerText: 'Not interested? Tap Stop promotions',

      marketingButtonTypes: [
        {
          name: 'MARKETING_OPT_OUT',
          label: `${self.$t('column_labels.marketing_opt_out')} (${self.$t(
            'app_labels.recommended'
          )})`,
        },
        {
          name: 'CUSTOM',
          label: self.$t('column_labels.custom'),
        },
      ],

      callToActionTemplate: {
        type: buttonActionOptions[0],
        text: '',
        urlType: 'STATIC', // static is default url type
        url: '',
        phone: '',
        country: '',
      },

      quickReplyTemplate: {
        type: '',
        text: '',
      },

      otpTemplate: {
        type: '',
        text: 'Copy Code',
      },
      buttons: [],
    };
  },

  computed: {
    // get all button actions that aren't used yet
    availableButtonActions() {
      const takenTypes = this.buttons.map(({ type }) => type);
      return this.buttonActionOptions.filter((type) => !takenTypes.includes(type));
    },
  },

  watch: {
    // Format the ideal value to be passed to the parent
    buttons: {
      deep: true,
      handler(val) {
        const buttonValues = this.formattedButtonData(val);
        this.$emit('input', buttonValues);

        if (
          this.buttons.some((v) => v.type === 'MARKETING_OPT_OUT' && v.text === this.optOutText)
        ) {
          this.$emit('change-footer-text', this.footerText);
        } else {
          this.$emit('change-footer-text', '');
        }
      },
    },

    'context.category': {
      handler(val) {
        if (val === 'AUTHENTICATION') {
          this.buttonType = 'OTP';
          this.buttons = [{ ...this.otpTemplate }];
        } else {
          this.buttonType = 'NONE';
          this.buttons = [];
        }
      },
    },
  },

  created() {
    // If duplicate data
    if (this.value.type) {
      const populatedForm = this.initalizeDuplicateValues(this.value.items);

      this.buttons = [...populatedForm];
      this.buttonType = this.value.type;
    } else {
      // Set default button type to the first of item in buttonTypesJson

      if (this.value && Object.keys(this.value).length) {
        this.buttons = [...this.value];
      }
      const [first] = this.buttonTypeOptions;
      this.buttonType = first;

      this.resetField();
    }
  },

  methods: {
    onDrag(event, index) {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = 'move';
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.effectAllowed = 'move';
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.setData('buttonItem', index);
    },

    onDrop(event, targetIndex) {
      const index = event.dataTransfer.getData('buttonItem');

      // Ignore if drag and dropped in the same item
      if (index === targetIndex) return 0;

      // Swap both dragged and dropped items
      const buttonList = [...this.buttons];
      const temp = buttonList[index];
      buttonList[index] = buttonList[targetIndex];
      buttonList[targetIndex] = temp;

      this.buttons = [...buttonList];

      return 0;
    },

    getRegionViaPhone(phoneNumber) {
      const region = parsePhoneNumber(phoneNumber).regionCode;
      return region || '';
    },

    setRegionViaPhone(phoneNumber, index) {
      if (this.buttons[index].country) return 0;

      const region = parsePhoneNumber(phoneNumber).regionCode;
      this.buttons[index].country = region || '';

      return 0;
    },

    changeQuickReplyTypes(ev, index) {
      if (ev === 'MARKETING_OPT_OUT') {
        this.buttons.map((v, i) => {
          if (i === index) {
            v.text = this.optOutText;
          }

          return v;
        });
      }
    },

    // set some values that are missing from the duplicate data; e.g 'country'
    initalizeDuplicateValues(data) {
      const newData = data.map((button) => {
        let { country } = button;

        // if phone has value, detect and set country based on phone value
        if (button.phone) {
          country = this.getRegionViaPhone(button.phone);
        }

        if (this.context.category === 'MARKETING' && !button.type) {
          if (this.optOutText === button.text) {
            button.type = 'MARKETING_OPT_OUT';
          } else {
            button.type = 'CUSTOM';
          }
        }

        return {
          ...button,
          country,
        };
      });

      return newData;
    },

    // empty value before inserting new button template
    resetField() {
      this.buttons = [];
      this.addField();
    },

    // add a button template to the button array value
    addField() {
      // exit if reached maximum button count
      const maxButtons = this.buttonTypesJson[this.buttonType].max;
      if (this.buttons.length >= maxButtons) return 0;

      if (this.buttonType === 'CALL_TO_ACTION') {
        // Call to Action
        this.buttons = [
          ...this.buttons,
          {
            ...this.callToActionTemplate,
            type: this.availableButtonActions[0],
          },
        ];
      } else if (this.buttonType === 'QUICK_REPLY') {
        // Quick Reply

        if (this.context.category === 'MARKETING') {
          let quickReplyTemplate = { ...quickReplyTemplate, type: 'CUSTOM', text: '' };

          if (!this.buttons.some((v) => v.text === this.optOutText)) {
            quickReplyTemplate = {
              ...this.quickReplyTemplate,
              type: 'MARKETING_OPT_OUT',
              text: this.optOutText,
            };
          }
          this.buttons = [...this.buttons, { ...quickReplyTemplate }];
        } else {
          this.buttons = [...this.buttons, { ...this.quickReplyTemplate }];
        }
      } else if (this.buttonType === 'OTP') {
        this.buttons = [{ ...this.otpTemplate }];
      }

      return 0;
    },

    formattedButtonData(val) {
      let buttonValues = {};

      // if button type is None then pass empty object
      if (this.buttonType === 'NONE') return buttonValues;

      buttonValues = {
        type: this.buttonType,
        items: [...val],
      };

      return buttonValues;
    },

    // remove button item in array
    removeField(index) {
      this.buttons = [...this.buttons.slice(0, index), ...this.buttons.slice(index + 1)];
    },

    // reset the input values when action type is changed
    resetCallToActionFields(index) {
      // extract all values of default template except 'type'
      const { type, ...noTypeTemplate } = this.callToActionTemplate;

      // insert the new fields in same position of the item
      this.buttons = Object.assign([], this.buttons, {
        [index]: {
          type: this.buttons[index].type, // add 'type' value based on previous input state
          ...noTypeTemplate,
        },
      });
    },
  },
};
</script>

<styles lang="scss" scoped>
.drag-icon {
  transform: rotate(90deg);
}
.el-form-item__error {
  position: relative !important;
}

.dynamic-text {
  white-space: nowrap;
  display: inline;
  line-height: 2.7;
  vertical-align: middle;
  cursor: help;
}
</styles>
