<template>
  <div class="flex">
    <!-- subaccount Select -->
    <el-select
      :value="filters.subAccountId"
      :disabled="loading"
      size="small"
      class="ml-3"
      :loading="false"
      @change="(v) => updateFilter({ subAccountId: v })"
    >
      <el-option
        v-for="subaccount in [
          { name: $t('chat_apps.dashboard.filters[0]'), value: 'all' },
          ...subaccountList,
        ]"
        :key="subaccount.value"
        :label="subaccount.name"
        :value="subaccount.value"
      >
      </el-option>
    </el-select>

    <!-- channels Select -->
    <el-select
      :value="filters.channel"
      :disabled="loading"
      size="small"
      class="ml-3"
      :loading="false"
      @change="(v) => updateFilter({ channel: v })"
    >
      <el-option
        v-for="channel in [{ name: $t('chat_apps.logs.filters[0]'), value: '' }, ...channelList]"
        :key="channel.value"
        :label="channel.name"
        :value="channel.value"
      >
      </el-option>
    </el-select>

    <!-- date range Select -->
    <el-select
      :value="filters.daterange"
      :disabled="loading"
      size="small"
      class="ml-3"
      :loading="false"
      @change="(v) => updateFilter({ daterange: v })"
    >
      <el-option
        v-for="daterange in daterangeOptions"
        :key="daterange.value"
        :label="`${daterange.name} (UTC-00)`"
        :value="daterange.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Filters',

  props: {
    filters: {
      default: () => {},
      type: Object,
    },
    loading: {
      default: true,
      type: Boolean,
    },
    channelList: {
      default: () => [],
      type: Array,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    const self = this;

    return {
      daterangeOptions: [
        { name: self.$t('date_labels.today'), value: -1 }, // Today
        { name: self.$t('date_labels.last_7_days'), value: 7 }, // Last 7 days
        { name: self.$t('date_labels.last_14_days'), value: 14 }, // Last 14 days
        { name: self.$t('date_labels.last_30_days'), value: 30 }, // Last 30 days
      ],
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },
  },
};
</script>
