<!-- eslint-disable max-len -->
<template>
  <div class="flex relative h-full w-full">
    <div class="w-full h-full">
      <div class="mb-5 ml-5 mt-5">
        <ToggleChartType @toggle-chart-type="toggleChartType" />
      </div>
      <div>
        <div class="chart-container chart-container--full w-full">
          <LineChart
            :chart-data="getGraphData()"
            :options="chartOption"
            class="w-full h-full absolute pin-t pin-l"
          />
        </div>
        <!-- <div class="flex justify-between text-xs mt-2">
          <span>{{ formatDateLabel().start }}</span>
          <span>{{ formatDateLabel().end }}</span>
        </div> -->
      </div>
    </div>
    <div v-if="chartData" class="mx-10">
      <div v-for="(label, i) in labels" :key="i" class="flex items-center my-5">
        <svg class="trend">
          <polygon
            points="0,10 0,20 20,20 20,0"
            :style="{ fill: getLabelAttributes(label.name).color }"
          />
        </svg>
        <div class="block ml-3">
          <p class="text-sm text-grey-dark">{{ getLabelAttributes(label.name).name }}</p>
          <p class="mt-1 text-sm text-black font-semibold">
            {{ formatText(label.value) }}
          </p>
        </div>
      </div>
      <div v-if="Object.keys(totals).length">
        <WAFreeAndChargeable :totals="totals" />
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import LineChart from '@/components/common/LineChart.vue';
import ToggleChartType from '@/components/common/ToggleChartType.vue';
import WAFreeAndChargeable from './WAFreeAndChargeable.vue';

import { getCommonChartConfig } from '@/utils/common';

export default {
  name: 'SummaryChart',

  components: {
    LineChart,
    ToggleChartType,
    WAFreeAndChargeable,
  },

  props: {
    chartData: {
      default: () => [],
      type: Array,
    },
    labels: {
      default: () => [],
      type: Array,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    labelFormat: {
      default: () => {},
      type: Function,
    },
    labelSize: {
      default: '',
      type: String,
    },
    totals: {
      default: () => ({}),
      type: Object,
    },
  },

  data() {
    // const self = this;

    return {
      chartOption: {
        ...getCommonChartConfig(),
      },
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    toggleChartType(type) {
      if (type === 'linear') {
        this.chartOption = { ...getCommonChartConfig() };
      } else if (type === 'logic_scale') {
        this.chartOption = { ...getCommonChartConfig(true) };
      }
    },

    formatDateLabel() {
      const days = this.dateRange();
      const startDate = days[0];
      const endDate = days[days.length - 1];

      if (this.filters.daterange <= 0) {
        return {
          start: Moment(startDate).format(`MMMM DD, YYYY HH:mm ${this.timeZone}`),
          end: Moment(endDate).format(`MMMM DD, YYYY HH:mm ${this.timeZone}`),
        };
      }
      return {
        start: Moment(startDate).format(`MMMM DD, YYYY ${this.timeZone}`),
        end: Moment(endDate).format(`MMMM DD, YYYY ${this.timeZone}`),
      };
    },

    getUndeliveredPercent() {
      const { sent, undelivered } = this.chartData;
      return `${((undelivered.total / (sent.total + undelivered.total)) * 100).toFixed(2)}%`;
    },

    generateDays(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('day').format(format));
      } while (from.add(1, 'days').diff(to) < 0);
      return range;
    },

    generateHours(from, to, format = '') {
      const range = [];
      do {
        range.push(from.clone().startOf('hour').format(format));
      } while (from.add(1, 'hours').diff(to) < 0);
      return range;
    },

    anchorDays(dayOffset) {
      let from = null;
      let to = null;
      let format = 'MMM D (ddd)';

      switch (dayOffset) {
        // Today
        case -1:
          from = Moment().utc().startOf('day');
          to = Moment().utc().endOf('day');
          format = 'hA';
          break;
        // 7 days
        case 7:
          from = Moment().utc().subtract(1, 'week').startOf('day').endOf('day');
          to = Moment().utc().add(1, 'days');
          break;
        // 14 days
        case 14:
          from = Moment().utc().subtract(2, 'week').endOf('day');
          to = Moment().utc().add(1, 'days');
          break;
        // 30 days
        case 30:
          from = Moment().utc().subtract(30, 'days').startOf('day');
          to = Moment().utc().add(1, 'days');
          break;
        default:
          break;
      }
      return { from, to, format };
    },

    dateRangeWithTz() {
      const dr = this.dateRange();

      return dr.map((d) => `${d} ${this.timeZone}`);
    },

    dateRange() {
      const dayOffset = this.filters.daterange;
      const range = this.anchorDays(dayOffset);
      const isDay = Moment(range.from).utc().isSame(Moment(range.to).utc(), 'days');
      const days = isDay
        ? this.generateHours(range.from, range.to, range.format)
        : this.generateDays(range.from, range.to, range.format);
      return days;
    },

    populateData() {
      const cd = this.chartData;

      const dayOffset = this.filters.daterange;
      const range = this.anchorDays(dayOffset);
      const isDay = Moment(range.from).utc().isSame(Moment(range.to).utc(), 'days');
      const days = isDay
        ? this.generateHours(range.from, range.to)
        : this.generateDays(range.from, range.to);

      return Object.keys(cd).map((code) => {
        // Fill-in missing dates
        const d = [];

        if (isDay && cd[code]) {
          // If hourly
          cd[code].data.forEach((hourData) => {
            d.push(hourData.value);
          });
        } else if (!isDay && cd[code]) {
          // If daily
          days.forEach((day) => {
            // Check if day has data
            let dayData = null;

            if (cd[code] && cd[code].data) {
              dayData =
                cd[code].data.find((dd) => Moment.utc(dd.date).isSame(Moment(day).utc(), 'day')) ||
                null;
            }

            d.push(dayData ? dayData.value : 0);
          });
        }

        return {
          label: code.charAt(0).toUpperCase() + code.slice(1),
          data: d,
          borderWidth: 2,
          radius: 3,
          pointHoverRadius: 3,
          borderColor: this.getLabelAttributes(code).color,
          backgroundColor: this.getLabelAttributes(code, 0).color,
          pointBackgroundColor: this.getLabelAttributes(code).color,
          pointBorderColor: this.getLabelAttributes(code).color,
          spanGaps: true,
          lineTension: 0,
        };
      });
    },

    getLabelAttributes(code, opacity = 1) {
      const labelAttr = {
        name: '',
        color: 'rgba(75, 37, 143, 0.8)',
      };
      switch (code) {
        case 'paid':
          labelAttr.name = this.$t('column_labels.paid');
          labelAttr.color = `rgba(144, 157, 185, ${opacity})`;
          break;
        case 'free':
          labelAttr.name = this.$t('column_labels.free');
          labelAttr.color = `rgba(252, 214, 107, ${opacity})`;
          break;
        case 'marketing':
          labelAttr.name = this.$t('chat_apps.categories.Marketing');
          labelAttr.color = `rgba(106, 237, 199, ${opacity})`;
          break;
        case 'authentication':
          labelAttr.name = this.$t('chat_apps.categories.Authentication');
          labelAttr.color = `rgba(153, 139, 227, ${opacity})`;
          break;
        case 'utility':
          labelAttr.name = this.$t('chat_apps.categories.Utility');
          labelAttr.color = `rgba(247, 100, 185, ${opacity})`;
          break;
        case 'service':
          labelAttr.name = this.$t('column_labels.service');
          labelAttr.color = `rgba(254, 207, 2, ${opacity})`;
          break;
        case 'delivery':
          labelAttr.name = this.$t('chat_apps.dashboard.summary_chart[2]');
          labelAttr.color = `rgba(33, 97, 140, ${opacity})`;
          break;
        case 'received':
          labelAttr.name = this.$t('chat_apps.dashboard.summary_chart[3]');
          labelAttr.color = `rgba(52, 152, 219, ${opacity})`;
          break;
        default:
          break;
      }
      return labelAttr;
    },

    getGraphData() {
      return {
        labels: this.dateRange(),
        datasets: this.populateData(),
      };
    },

    formatText(n) {
      return this.labelFormat(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  height: 8px;
  width: 8px;
  display: inline-block;
}

.chart-container {
  width: 100%;
  flex-grow: 0;
  height: 300px;
  position: relative;

  &--large {
    height: 300px;
  }

  &--full {
    min-height: 300px;
    height: 100%;
  }

  &--small {
    height: 130px;
  }
}

.trend {
  width: 20px;
  height: 20px;
  display: block;
}
</style>
