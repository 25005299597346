<template>
  <el-dialog
    :title="template.templateName"
    :visible.sync="ptVisible"
    width="80%"
    top="20px"
    custom-class="px-20 preview-modal"
    @close="close"
    @open="open"
  >
    <div class="preview-template">
      <div class="details">
        <div class="flex">
          <div class="flex flex-1 mr-2 border border-grey-light bg-white rounded">
            <div class="flex-shrink p-4 text-center">
              <span class="text-lg text-grey-darker">$</span>
            </div>
            <div class="flex-auto p-4 border-l border-grey-light bg-grey-custom rounded-l-lg">
              <div class="mb-5">
                <span class="block text-xs text-grey-dark mb-2">
                  {{ $t('column_labels.category') }}
                </span>
                <p class="block text-xs text-black font-normal">{{ template.categoryName }}</p>
              </div>
              <div>
                <span class="block text-xs text-grey-dark mb-2">
                  {{ $tc('column_labels.language', 1) }}
                </span>
                <p class="block text-xs text-black mb-2 font-normal">{{ template.languageName }}</p>
              </div>
            </div>
            <div class="flex-shrink border-r border-transparent rounded-r-lg" />
          </div>
          <div class="flex-1 mr-2 border border-grey-light bg-grey-custom p-4 rounded">
            <div class="mb-5">
              <span class="block text-xs text-grey-dark mb-2">
                {{ $tc('column_labels.channel', 1) }}
              </span>
              <p
                class="preview-template__message-content block text-xs text-black mb-2 font-normal"
              >
                {{ template.channelName }}
              </p>
            </div>
            <div>
              <span class="block text-xs text-grey-dark mb-2">
                {{ getDateHeader(timeZone, $t('column_labels.submission_date')) }}
              </span>
              <p
                v-if="template.createdAt"
                class="preview-template__message-content block text-xs text-black mb-2 font-normal"
              >
                {{ getLocalDateTimeTz(template.createdAt, timeZoneOffset) }}
              </p>
              <span v-else class="block text-xs text-black mb-2">—</span>
            </div>
          </div>
          <div class="flex-1 border border-grey-light bg-grey-custom p-4 rounded">
            <div class="mb-5">
              <span class="block text-xs text-grey-dark mb-2">
                {{ $t('column_labels.status') }}
              </span>
              <p v-if="template.statusName" class="block text-xs text-black mb-2 font-normal">
                <span
                  :class="[
                    'square-bullet',
                    'bg-grey-darker',
                    'inline-block',
                    'mr-1',
                    { 'bg-green': template.status === 'APPROVED' },
                    { 'bg-red': template.status === 'REJECTED' },
                  ]"
                >
                </span>
                {{ template.statusName }}
              </p>
              <p v-else class="block text-xs text-black mb-2">—</p>
            </div>
            <div
              v-if="
                template.statusName && template.statusName.toLowerCase().match(/approved|rejected/g)
              "
            >
              <span class="block text-xs text-grey-dark mb-2">
                {{ getDateHeader(timeZone, $t('column_labels.approval_date')) }}
              </span>
              <p v-if="template.updatedAt" class="block text-xs text-black mb-2 font-normal">
                {{ getLocalDateTimeTz(template.updatedAt, timeZoneOffset) }}
              </p>
              <p v-else class="block text-xs text-black mb-2">—</p>
            </div>
          </div>
        </div>
      </div>

      <hr class="mx-0 my-10 h-px bg-grey-dark w-48" />

      <el-row :gutter="100" class="mt-10">
        <el-col :span="16">
          <div class="w-full mb-12">
            <div class="block text-sm mb-5 text-black">{{ $t('column_labels.message') }}</div>
            <div v-if="template.category !== 'AUTHENTICATION'" class="mb-4">
              <div class="block text-xs mb-2 text-black">{{ $t('column_labels.header') }}</div>
              <el-radio
                v-for="(v, i) in headers"
                :key="i"
                :label="v"
                :value="content.header"
                :disabled="Boolean(true)"
              >
                <span :class="['capitalize', 'text-xs', { 'text-green': content.header === v }]">
                  {{ v }}
                </span>
              </el-radio>

              <div v-if="content.header === 'text'" class="text-item mt-3">
                <div class="p-2 bg-grey-lighter border border-grey-light rounded text-xs">
                  {{ content.headerValue }}
                </div>
              </div>
              <div v-if="content.header === 'media'" class="media-item mt-3">
                <div class="flex w-2/3">
                  <div
                    v-for="(v, i) in headerMedia"
                    :key="i"
                    :class="[
                      'text-center',
                      'rounded-lg',
                      'mr-3',
                      'flex-1',
                      'bg-grey-custom p-2 border border-grey-lighter',
                      { 'border-green': content.headerValue === v },
                    ]"
                  >
                    <div class="text-center w-full mb-2 mt-2">
                      <img
                        :src="
                          urlResolver(
                            bgUrl,
                            require(`@/assets/media/${getMediaIcons(
                              v,
                              content.headerValue === v
                            )}.svg`)
                          )
                        "
                        class="align-middle"
                        :alt="content.headerValue"
                        :style="{ width: '20px', height: '20px' }"
                      />
                    </div>
                    <div class="text-xs text-center w-full uppercase">
                      <span
                        :class="['text-grey-dark', { 'text-green': content.headerValue === v }]"
                      >
                        {{ v }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="block text-xs mb-2 text-black">{{ $t('column_labels.body') }}</div>
              <div
                class="preview-template__text block text-xs mb-2 p-2 rounded leading-normal break-normal whitespace-pre-wrap break-words bg-grey-lighter border border-grey-light"
                :style="{ wordBreak: 'normal' }"
                v-html="content.body"
              />
            </div>
            <div class="mb-4">
              <div class="block text-xs mb-2 text-black">{{ $t('column_labels.footer') }}</div>
              <div
                v-if="content.footer"
                class="p-2 bg-grey-lighter border border-grey-light text-xs rounded"
              >
                {{ content.footer }}
              </div>
              <div v-else class="mt-2 text-xs text-grey">
                {{ $t('app_labels.none') }}
              </div>
            </div>
            <div class="mb-4">
              <div class="block text-xs mb-2 text-black">{{ $t('column_labels.buttons') }}</div>
              <ul v-if="content.buttons" class="list-reset">
                <li
                  v-for="(button, index) in content.buttons.items"
                  :key="index"
                  class="flex items-center mb-4"
                >
                  <div
                    class="text-xs rounded p-2 bg-white shadow text-blue text-center border w-64"
                  >
                    {{ button.text }}
                  </div>
                  <div v-if="content.buttons.type === 'CALL_TO_ACTION'" class="ml-5">
                    <div v-if="button.type === 'URL'" class="flex items-center">
                      <i class="el-icon-connection text-xl"></i>
                      <span class="text-grey-dark text-xs ml-2">
                        {{ $t('chat_apps.templates.preview[0]') }}
                        &nbsp;
                        <strong class="text-black">
                          {{
                            button.urlType === 'DYNAMIC' ? `${button.url}/\{\{1\}\}` : button.url
                          }}
                        </strong>
                      </span>
                    </div>
                    <div v-else class="flex items-center">
                      <i class="el-icon-mobile-phone text-xl"></i>
                      <span class="text-grey-dark text-xs ml-2">
                        {{ $t('chat_apps.templates.preview[1]') }}
                        &nbsp;
                        <strong class="text-black">
                          {{ formatPhoneNumber(button.phone) }}
                        </strong>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-else class="mt-2 text-xs text-grey">
                {{ $t('app_labels.none') }}
              </div>
            </div>
          </div>

          <div class="mt-10">
            <el-button type="danger" size="small" @click="deleteTemplate">
              {{ $t('actions.delete') }}
            </el-button>
            <el-button
              v-if="
                template.statusName && template.statusName.toLowerCase().match(/approved|rejected/g)
              "
              type="primary"
              size="small"
              class="ml-5"
              @click="duplicateTemplate"
            >
              {{ $t('actions.duplicate') }}
            </el-button>
          </div>
        </el-col>
        <el-col :span="8">
          <p class="text-center text-xs mb-2 text-grey">{{ $t('mcs.message_preview') }}</p>
          <div
            class="preview-content rounded-lg p-3"
            :style="{ 'background-image': `url('${bgUrl + bgImage}')` }"
          >
            <div
              v-if="Object.keys(content).length"
              class="min-w-full bg-white shadow rounded-tl-lg rounded-r-lg p-2 text-xs max-h-full overflow-auto"
            >
              <div v-if="content.header === 'text'" class="break-words mb-3 text-black font-medium">
                {{ content.headerValue }}
              </div>
              <div
                v-if="content.header === 'media'"
                class="break-words bg-grey-darker-custom rounded mb-5"
                :style="{ wordBreak: 'normal' }"
              >
                <div class="p-6 text-center">
                  <img
                    :src="
                      urlResolver(
                        bgUrl,
                        require(`@/assets/media/${getPreviewMediaIcons(content.headerValue)}.svg`)
                      )
                    "
                    :alt="content.headerValue"
                    class="align-middle"
                    :style="{ width: '40px', height: '40px' }"
                  />
                </div>
                <div
                  v-if="
                    content.headerValue === 'location' &&
                    content.locFields.name &&
                    content.locFields.address
                  "
                  class="pt-1 pb-2 bg-grey-shade-custom"
                >
                  <div class="break-words mt-2 ml-2" :style="{ fontSize: '11px' }">
                    {{ content.locFields.name }}
                  </div>
                  <div
                    class="break-words mt-1 ml-2 mb-1 text-grey-darker"
                    :style="{ fontSize: '10px' }"
                  >
                    {{ content.locFields.address }}
                  </div>
                </div>
              </div>
              <div
                class="inline-block whitespace-pre-wrap break-words"
                :style="{ wordBreak: 'normal' }"
                v-html="content.body"
              />
              <div
                v-if="content.footer"
                class="break-words mt-5 text-grey-darker"
                :style="{ fontSize: '10px' }"
              >
                {{ content.footer }}
              </div>
            </div>
            <div v-if="content.buttons" class="button-grid -mx-1">
              <template v-if="content.buttons.type === 'QUICK_REPLY'">
                <div
                  v-for="(button, index) in content.buttons.items"
                  :key="index"
                  class="button-grid__item px-1 mt-2"
                >
                  <div
                    class="text-xs rounded p-2 bg-white shadow text-grey text-center"
                    :class="button.text.length && 'text-blue'"
                  >
                    {{ button.text || $t('chat_apps.templates.create[20]') }}
                  </div>
                </div>
              </template>
              <template v-else-if="content.buttons.type === 'CALL_TO_ACTION'">
                <div
                  v-for="(button, index) in content.buttons.items"
                  :key="index"
                  class="button-grid__item button-grid__item--block px-1 mt-2"
                >
                  <div
                    class="text-xs rounded p-2 bg-white shadow text-grey text-center"
                    :class="button.text.length && 'text-blue'"
                  >
                    {{ button.text || $t('chat_apps.templates.create[20]') }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash'; // eslint-disable-line
import { mapState } from 'vuex';
import { parsePhoneNumber } from 'awesome-phonenumber';
import timeFormat from '@/mixins/timeFormat';
import bgImage from '@/assets/whatsapp-chat-bg.png';
import { transformToHTML, urlResolver, formatReadableButtonValues } from '@/utils/common';

export default {
  name: 'PreviewTemplate',

  mixins: [timeFormat],

  props: {
    template: {
      default: () => {},
      type: Object,
    },

    ptVisible: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      bgImage,
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
      content: {},
      headers: ['none', 'text', 'media'],
      headerMedia: ['image', 'video', 'document', 'location'],
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    formatPhoneNumber(phoneNumber) {
      // check if phoneNumber starts with a '+'; add it if it doesn't
      const phone = phoneNumber.match(/^\+/) ? phoneNumber : `+${phoneNumber}`;
      const pn = parsePhoneNumber(phone);
      const formattedPhoneNumber = pn.number.international;
      return formattedPhoneNumber || phone;
    },

    sanitizeText(text = '') {
      return transformToHTML(this.sanitize(text.trim()))
        .replace(/\n/g, '<p></p>')
        .replace(/<\/p>/g, '\n')
        .replace(/<p>/g, '');
    },

    urlResolver(url, imgUrl) {
      return urlResolver(url, imgUrl);
    },

    getFormValues(components = []) {
      const form = components.reduce(
        (a, b) => {
          if (b.type === 'BODY') {
            // eslint-disable-next-line no-param-reassign
            a.body = this.sanitizeText(b.text);
            a.securityRecommendation = b.add_security_recommendation;
          }

          if (b.type === 'FOOTER') {
            // eslint-disable-next-line no-param-reassign
            a.footer = this.sanitize(b.text);
            a.footerExpiryInMins = b.code_expiration_minutes
              ? parseInt(b.code_expiration_minutes, 10)
              : 0;
            a.footerExpiry = Boolean(b.code_expiration_minutes);
          }

          if (b.type === 'HEADER') {
            // eslint-disable-next-line no-param-reassign
            if (b.format.toLowerCase() === 'text') {
              // eslint-disable-next-line no-param-reassign
              a.header = b.text ? 'text' : 'none';
              // eslint-disable-next-line no-param-reassign
              a.headerValue = this.sanitize(b.text);
            } else {
              // eslint-disable-next-line no-param-reassign
              a.header = 'media';
              // eslint-disable-next-line no-param-reassign
              a.headerValue = b.format.toLowerCase();

              if (b.format.toLowerCase() === 'location') {
                a.locFields.latlong = b.example.headerHandle[0];
                a.locFields.name = b.example.headerHandle[1];
                a.locFields.address = b.example.headerHandle[2];
              }
            }
          }

          if (b.type === 'BUTTONS') {
            const buttonValue = formatReadableButtonValues(b);
            // eslint-disable-next-line no-param-reassign
            a.buttons = { ...buttonValue };
          }

          return a;
        },
        {
          header: 'none',
          headerValue: '',
          footer: '',
          body: '',
          buttons: '',
          locFields: {
            latlong: '',
            name: '',
            address: '',
          },
        }
      );

      return form;
    },

    sanitize(text) {
      return _.unescape(this.$sanitize(text));
    },

    open() {
      const form = this.getFormValues(this.template.components);

      this.content = { ...form };
    },

    close() {
      this.$emit('close');
    },

    getMediaIcons(type, isActive) {
      const activeColor = isActive ? 'green' : 'dark';
      let icon = `image-${activeColor}`;

      if (this.headerMedia.includes(type)) {
        icon = `${type}-${activeColor}`;
      }

      return icon;
    },

    getPreviewMediaIcons(type) {
      const activeColor = 'light';
      let icon = `image-${activeColor}`;

      if (this.headerMedia.includes(type)) {
        icon = `${type}-${activeColor}`;
      }

      return icon;
    },

    duplicateTemplate() {
      this.$emit('duplicate-template', this.template);
    },

    deleteTemplate() {
      this.$emit('delete-template', this.template);
    },

    // For parsing lat and long if ever the GoogleMap component will be required here
    getLatLng(samples) {
      let latlng = {
        lat: '',
        lng: '',
      };

      const location = samples.latlong ? samples.latlong : '0,0';

      if (location) {
        const ll = location.split(',');
        latlng = {
          lat: ll[0],
          lng: ll[1],
        };
      }

      return latlng;
    },
  },
};
</script>

<style lang="scss">
.button-grid {
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex-grow: 1;
    min-width: 50%;

    &--block {
      width: 100%;
      min-width: 0;
    }
  }
}

.preview-modal {
  .el-dialog__headerbtn {
    top: 20px;
    right: 95px;
    font-size: 24px;
  }
}
.preview-template {
  &__text {
    min-height: 50px;
    overflow: auto;
  }

  .el-form--label-top .el-form-item__label {
    padding-bottom: 0;
    font-size: 0.75rem;
    line-height: 30px;
  }

  .sel {
    width: 60%;
  }

  .el-button {
    outline: none;
    font-size: 0.75rem;
  }

  .preview-content {
    background-color: #ede7df;
    background-position: center;
    background-size: cover;
    height: 400px;

    div {
      word-break: normal;
    }

    p {
      max-width: 15rem;
      word-break: normal;
    }
  }

  .el-form-item {
    margin-bottom: 15px;
  }

  .mc-label {
    &:before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }
  }

  .media-item {
    .el-radio__label {
      display: none;
    }

    .el-form-item__content {
      line-height: 30px;
    }
  }

  .bg-grey-custom {
    background-color: #f6f6f6;
  }

  .bg-grey-shade-custom {
    background-color: #f5f5f5;
  }

  .bg-grey-darker-custom {
    background-color: #9d9d9d;
  }

  .preview__img {
    width: 20px;
    height: 20px;
  }

  .font-9px {
    font-size: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #51d88a;
    background-color: white;
  }

  .square-bullet {
    height: 8px;
    width: 8px;
  }

  .blurred {
    filter: blur(0.8px);
  }
}
</style>
