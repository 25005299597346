<template>
  <div class="flex flex-col mt-5 border-t border-grey border-solid w-full py-5">
    <div>
      <span class="text-sm text-grey-dark mr-2">{{ $t('column_labels.chargeable') }}:</span>
      <span class="text-sm text-black font-semibold">{{ toDecimalString(totals.chargeable) }}</span>
    </div>
    <div class="mt-3">
      <span class="text-sm text-grey-dark mr-2"
        >{{ $t('column_labels.free') }}
        <el-popover placement="top-start" width="300" trigger="hover">
          <div class="text-xs">{{ $t('chat_apps.reports.free_label') }}.</div>
          <i slot="reference" class="el-icon-question" /> </el-popover
        >:</span
      >
      <span class="text-sm text-black font-semibold">{{ toDecimalString(totals.free) }}</span>
    </div>
  </div>
</template>

<script>
import { formatLocalizedNumber } from '../../../utils/common';
export default {
  name: 'WAChargeableAndFreeTotal',

  props: {
    totals: {
      default: () => ({}),
      type: Object,
    },
  },

  methods: {
    toDecimalString(n, opt) {
      return formatLocalizedNumber(n, opt);
    },
  },
};
</script>
