<template>
  <div class="relative">
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <span class="text-xs block mb-2"> {{ $t('column_labels.date_range') }} (UTC-00) </span>
        <el-date-picker
          v-model="dates"
          type="daterange"
          format="dd MMM yyyy"
          unlink-panels
          range-separator="—"
          :start-placeholder="$t('date_labels.start_date')"
          :end-placeholder="$t('date_labels.end_date')"
          size="small"
          class="ca-datepicker"
          :picker-options="pickerOptions"
          @change="(v) => handleDaterange({ daterange: v })"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="flex flex-col rounded p-4 w-full justify-between text-black bg-grey-lighter">
      <h3 class="font-medium text-black text-sm mb-5">
        {{ $t('app_labels.filters') }}
      </h3>
      <div>
        <span class="text-xs block mb-2"> {{ $t('column_labels.report_type') }}</span>
        <el-select
          :value="filters.reportType"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="(v) => $emit('update-filter', { reportType: v })"
        >
          <el-option
            v-for="(reportType, i) in reportTypeOptions"
            :key="i"
            :label="reportType.name"
            :value="reportType.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mt-5">
        <span class="text-xs block mb-2"> {{ $t('column_labels.metric') }}</span>
        <el-select
          :value="filters.metric"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="(v) => $emit('update-filter', { metric: v })"
        >
          <el-option
            v-for="(metric, i) in metricOptions"
            :key="i"
            :label="metric.name"
            :value="metric.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'Filters',

  props: {
    reportTypeOptions: {
      default: () => [],
      type: Array,
    },

    metricOptions: {
      default: () => [],
      type: Array,
    },

    filters: {
      default: () => {},
      type: Object,
    },

    defaultDaterange: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    const self = this;

    return {
      dates: [],

      pickerOptions: {
        disabledDate(time) {
          return Moment() < time || Moment().diff(time, 'months') >= 6;
        },
        shortcuts: [
          {
            text: self.$t('date_labels.today'),
            onClick(picker) {
              const end = Moment().endOf('day');
              const start = Moment().startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_7_days'),
            onClick(picker) {
              const start = Moment().subtract(1, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_14_days'),
            onClick(picker) {
              const start = Moment().subtract(2, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_30_days'),
            onClick(picker) {
              const start = Moment().subtract(30, 'days').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'MTD',
            onClick(picker) {
              const start = Moment().startOf('month');
              const end = Moment();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  created() {
    this.dates = this.defaultDaterange;
  },

  methods: {
    handleDaterange(dt) {
      this.$emit('update-filter', dt);
    },

    getOptionsName(val, options) {
      return options.find((o) => o.value === val).name;
    },
  },
};
</script>

<style scoped lang="scss">
.ca-datepicker {
  width: 100% !important;
}
</style>
