<!-- eslint-disable vue/valid-v-bind -->
<!-- eslint-disable vue/no-parsing-error -->
<template>
  <el-button-group size="mini" class="text-xs toggle-chart-type">
    <el-button
      size="mini"
      class="text-xs btns"
      :class="{ 'bg-blue-lightest text-blue': active === 'linear' }"
      @click="
        $emit('toggle-chart-type', 'linear');
        active = 'linear';
      "
    >
      <span class="material-icons-outlined align-middle mr-1"> linear_scale </span>Linear</el-button
    >
    <el-button
      size="mini"
      class="text-xs btns"
      :class="{ 'bg-blue-lightest text-blue': active === 'logic_scale' }"
      @click="
        $emit('toggle-chart-type', 'logic_scale');
        active = 'logic_scale';
      "
    >
      <span class="material-icons-outlined align-middle mr-1">show_chart </span>Log Scale</el-button
    >
  </el-button-group>
</template>
<script>
export default {
  name: 'VueChartType',

  data() {
    return {
      active: 'linear',
    };
  },
};
</script>

<style lang="scss">
.toggle-chart-type {
  .btns {
    padding: 0 7px;
  }
}
</style>
