export default {
  methods: {
    mediaInfo(mediaType, fileSize, supportedFilesTxt) {
      let info = {
        supportedFilesTxt: [''],
        supportedFiles: [''],
        maxSize: 0,
      };

      let sp = [];
      let spTxt = [];

      if (supportedFilesTxt && supportedFilesTxt.length) {
        spTxt = supportedFilesTxt;
        const spObj = supportedFilesTxt.reduce(
          (a, b) => {
            a.l.push(`.${b.toLowerCase()}`);
            a.u.push(`.${b.toUpperCase()}`);

            return a;
          },
          { l: [], u: [] }
        );

        sp = sp.concat(spObj.l).concat(spObj.u);
      }

      switch (mediaType) {
        case 'image': {
          info = {
            supportedFilesTxt: spTxt.length ? spTxt : ['png', 'jpg', 'jpeg'],
            supportedFiles: sp.length ? sp : ['.png', '.jpg', '.jpeg', '.PNG', '.JPG', '.JPEG'],
            maxSize: fileSize || 5, // 5MB
          };
          break;
        }
        case 'document': {
          info = {
            supportedFilesTxt: spTxt.length ? spTxt : ['pdf'],
            supportedFiles: sp.length ? sp : ['.pdf', '.PDF'],
            maxSize: fileSize || 100, // 100MB
          };
          break;
        }
        case 'video': {
          info = {
            supportedFilesTxt: spTxt.length ? spTxt : ['mp4', '3gpp'],
            supportedFiles: sp.length ? sp : ['.mp4', '.3gpp', '.MP4', '.3GPP'],
            maxSize: fileSize || 16, // 16MB
          };
          break;
        }
        default: {
          break;
        }
      }

      return info;
    },

    regex(type) {
      let regex = /.*/;

      switch (type) {
        case 'video': {
          regex = /\.([3gpp|mp4]+)(?:[\?#]|$)/i;
          break;
        }

        case 'document': {
          regex = /\.([pdf]+)(?:[\?#]|$)/i;
          break;
        }

        case 'image': {
          regex = /\.([jpeg|jpg|png]+)(?:[\?#]|$)/i;
          break;
        }

        case 'location': {
          regex = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/;
          break;
        }

        case 'url': {
          regex =
            /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/;
          break;
        }

        default:
      }

      return regex;
    },

    getErrorMessage(type, field) {
      let errorMessage = this.$t('validations.valid', {
        value: this.$t('chat_apps.templates.sample_content'),
      });

      switch (type) {
        case 'image': {
          errorMessage = this.$t('validations.valid', {
            value: this.$t('chat_apps.templates.sample_media_url'),
          });
          break;
        }

        case 'document': {
          errorMessage = this.$t('validations.valid', {
            value: this.$t('chat_apps.templates.sample_doc'),
          });
          break;
        }

        case 'video': {
          errorMessage = this.$t('validations.valid', {
            value: this.$t('chat_apps.templates.sample_vid'),
          });
          break;
        }

        case 'location': {
          if (field === 'latlong') {
            errorMessage = this.$t('validations.valid', {
              value: `${this.$t('chat_apps.templates.sample_latlong')}(${this.$t(
                'app_labels.separated_by_comma'
              )})`,
            });
          }

          if (field === 'name') {
            errorMessage = this.$t('validations.valid', {
              value: `${this.$t('chat_apps.templates.sample_name')}`,
            });
          }

          if (field === 'address') {
            errorMessage = this.$t('validations.valid', {
              value: `${this.$t('chat_apps.templates.sample_address')}`,
            });
          }
          break;
        }

        case 'url': {
          errorMessage = this.$t('validations.valid', {
            value: this.$t('chat_apps.templates.sample_url'),
          });
          break;
        }

        default:
      }

      return errorMessage;
    },

    isImageOnly(fileUrl = '') {
      return fileUrl.toLowerCase().match(/\.([jpeg|jpg|png]+)(?:[\?#]|$)/i);
    },

    isPDFOnly(fileUrl = '') {
      return fileUrl.toLowerCase().match(/\.([pdf]+)(?:[\?#]|$)/i);
    },

    rules(row) {
      const self = this;
      const check = (rule, value, callback) => {
        if (row.name && row.name.toLowerCase() === 'image') {
          const img = new Image();

          img.src = value;

          img.onload = () => callback();

          img.onerror = () => callback(new Error(self.$t('mcs.messages.chat_apps.media_url[6]')));
        } else {
          return callback();
        }
      };

      const rules = [
        {
          required: true,
          message: self.$t('validations.required', { value: self.$t('column_labels.content') }),
          trigger: ['change', 'blur'],
        },
      ];

      if (row.name && row.name.toLowerCase().match(/image|video|document|location|url/)) {
        const p = {
          message: self.getErrorMessage(row.name, row.field),
          trigger: ['change', 'blur'],
        };

        if (!['name', 'address'].includes(row.field)) {
          p.pattern = self.regex(row.name.toLowerCase());
        }

        rules.push(p);
      }

      if (row.name && row.name.toLowerCase().match(/image|video/)) {
        rules.push({
          validator: check,
          trigger: ['blur', 'change'],
        });
      }

      return rules;
    },

    getFileNameInUrl(url = '') {
      const regx = url.match(/[^\/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/);
      if (regx && regx.length) {
        return regx[0];
      }

      return url;
    },
  },
};
