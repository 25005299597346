/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    logs: [],
    filters: {
      status: '',
      subAccountId: '',
      channel: '',
      daterange: [],
      limit: 10,
      direction: '',
      country: '',
      msisdn: '',
      umid: '',
      dir: 0,
    },
    pageInfo: {
      index: 0,
      end: false,
    },
    loading: false,
    statusList: [],
  },

  getters: {
    getLogsData: (st) => st.logs.logs,
    getStatusList: (st) => st.statusList,
    getPageInfo: (st) => st.pageInfo,
  },

  mutations: {
    SET_LOGS: (st, payload) => {
      st.logs = payload.logs;
    },
    SET_FILTERS: (st, payload) => {
      st.filters = payload.filters;
    },
    SET_LOADING: (st, payload) => {
      st.loading = payload.bool;
    },
    SET_STATUS_LIST: (st, payload) => {
      st.statusList = payload.list;
    },
    SET_PAGE: (st, payload) => {
      st.pageInfo = payload.pageInfo;
    },
  },

  actions,
};
