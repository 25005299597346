<template>
  <el-dialog
    custom-class="wa-modal"
    :visible.sync="showModal"
    :show-close="false"
    destroy-on-close
    center
    width="50%"
    @open="activeStep = 0"
    @close="closeModal"
  >
    <div class="channel-setup">
      <el-row>
        <el-col :span="24">
          <div class="flex justify-between items-center mb-12 sticky pin-t bg-white z-50">
            <h1 class="text-xl text-black font-normal pl-8">
              {{ title }}
            </h1>
            <i
              class="mr-12 text-xl el-icon-close cursor-pointer hover:text-blue"
              @click="closeModal"
            ></i>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-steps :active="activeStep" finish-status="success" align-center>
            <el-step
              :title="$t('chat_apps.channels.waes.step', { num: 1 })"
              description="Create a subaccount"
            />
            <el-step
              :title="$t('chat_apps.channels.waes.step', { num: 2 })"
              :description="$t('chat_apps.channels.waes.done')"
            />
          </el-steps>
        </el-col>
      </el-row>
      <el-row
        v-if="activeStep === 0"
        v-loading="loading"
        :element-loading-text="loadingText"
        class="waes-progress pt-4"
      >
        <el-col :span="24" class="step-container">
          <div v-if="retry">
            <p>
              <center>
                <span v-if="retryMessage" class="font-bold pb-2 text-red no-break">{{
                  retryMessage
                }}</span>
              </center>
            </p>
            <p v-if="errorMessage" class="mt-5">
              <center>
                <span class="text-black text-xs no-break">{{ errorMessage }}</span>
              </center>
            </p>
          </div>
          <div v-if="createdSubaccount">
            <div class="mb-5">
              <center>
                <span class="font-bold mb-2">A new WhatsApp subaccount has been created!</span>
                <!-- <br />
                <span class="text-xs">See the details below.</span> -->
              </center>
            </div>
            <div v-if="createdSubaccount" class="subaccount-tag flex p-5 m-3 justify-around">
              <p>Subaccount ID</p>
              <span>|</span>
              <p>{{ createdSubaccount.SubAccountId }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="activeStep === 1"
        v-loading="loading"
        :element-loading-text="loadingText"
        class="waes-progress"
      >
        <el-col v-if="!isUpdate" :span="24" class="step-container">
          <div>
            <p class="text-sm text-black pb-2 mb-3 leading-tight no-break">
              Thank you for signing up for WhatsApp Business Account. <br />
              Your account setup is in progress, and you'll receive an email in a few minutes once
              it's ready.
            </p>
            <p class="text-sm text-black mb-2">To unlock full functionality:</p>
            <ol class="text-sm text-grey-darker wa-steps">
              <li>
                <a
                  href="https://www.facebook.com/business/help/2058515294227817?id=180505742745347"
                  target="_blank"
                  class="text-blue underline"
                >
                  Verify your business
                </a>
                &nbsp;in Meta Business Manager.
              </li>
              <li>
                Ensure compliance with the
                <a
                  href="https://business.whatsapp.com/policy"
                  target="_blank"
                  class="text-blue underline"
                >
                  WhatsApp Commerce Policy
                </a>
                &nbsp;to avoid restrictions.
              </li>
              <li>
                <a
                  href="https://www.facebook.com/business/help/378834799515077"
                  target="_blank"
                  class="text-blue underline"
                >
                  Submit your display name
                </a>
                &nbsp;for approval, following Meta's guidelines.
              </li>
            </ol>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="actions">
          <el-button
            type="primary"
            class="mt-3 ml-3"
            :disabled="loading || retry"
            @click="clickNext"
          >
            <span v-if="activeStep === 1">Done</span>
            <span v-else>{{ $t('actions.next') }}</span>
          </el-button>
          <el-button v-if="retry" class="mt-3 ml-3" @click="retryStep"> Retry </el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import timeFormat from '../../../mixins/timeFormat';
import amplitude from '../../../mixins/amplitude';
import Moment from 'moment';

export default {
  mixins: [timeFormat, amplitude],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: 'WhatsApp Channel Setup',
    },

    waChannelToUpdate: {
      type: Object,
      default: () => {},
    },

    waFormDetails: {
      type: Object,
      default: () => {},
    },

    isUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeStep: 0,
      savedDetails: null,
      loading: false,
      loadingText: '',
      retry: false,
      isBounded: false,
      retryMessage: null,
      errorMessage: null,
      step: null,
      createdSubaccount: null,
    };
  },

  computed: {
    ...mapState('channels', [
      'caSystemUsers',
      'productMeta',
      'phoneDetails',
      'creditLine',
      'wabaDetails',
      'selectedWabaId',
      'selectedPhoneNumberId',
    ]),
    ...mapState('user', ['subaccounts']),
    ...mapState('channels', ['startTime']),
    ...mapGetters({
      user: 'user/getUser',
      getChannelData: 'channels/getChannelData',
    }),
  },

  watch: {
    showModal: {
      async handler(val) {
        if (val) {
          await this.processWABA();
        }
      },
    },

    activeStep: {
      immediate: true,
      handler() {
        if (this.activeStep === 1) {
          this.saveData();
        }
      },
    },
  },

  methods: {
    ...mapActions({
      processIntegration: 'channels/processIntegration',
      saveWAESDetails: 'channels/saveWAESDetails',
      submitPrepaidCA: 'channels/submitPrepaidCA',
    }),
    ...mapMutations('channels', ['SET_SELECTED_WABA_ID', 'SET_SELECTED_PHONE_NUMBER_ID']),

    showErrorMessage(msg, details) {
      if (window.Bugsnag) {
        const bugSnag = window.Bugsnag;

        bugSnag.notify(new Error(msg), (event) => {
          event.severity = 'error'; // eslint-disable-line
          event.addMetadata('details', details); // eslint-disable-line
          event.addMetadata('user', this.user); // eslint-disable-line
          event.addMetadata('formDetails', this.waFormDetails); // eslint-disable-line
        });
      }

      this.$message.error(this.$t('chat_apps.channels.waes.set_account_error'));
      console.log('An error occurred during the WhatsApp channel setup.'); // eslint-disable-line
    },

    closeModal() {
      this.$emit('close-modal');

      this.activeStep = 0;
      this.loading = false;
      this.selectedSubAccount = null;
      this.savedDetails = null;
      this.selectedPhoneNumberId = null;
      this.selectedPhoneNumber = null;
      this.retry = false;
      this.retryMessage = null;
    },

    retryStep() {
      if (this.step === 'setup-waba') {
        this.processWABA();
      }

      if (this.step === 'create-subaccount') {
        this.createChatappSubAccount();
      }
    },

    clickNext() {
      const timestamp = Moment().format();

      if (this.activeStep === 1) {
        const totalTimeSpent = this.startTime ? (Date.now() - this.startTime) / 1000 : 0;
        const formattedTotalTimeSpent =
          totalTimeSpent > 0 ? this.formatTimeInSeconds(totalTimeSpent) : 'No data';

        this.sendAmplitudeEvent('whatsapp_signup_completed', {
          uiArea: 'wa_embedded_signup_channels_page',
          userId: this.user && this.user.UserId,
          accountId: this.user && this.user.AccountId,
          subAccountId: this.createdSubaccount.SubAccountId,
          totalTimeSpent: formattedTotalTimeSpent,
          numberOfStepsCompleted: 4,
          timestamp,
        });
        this.closeModal();
      }

      if (this.activeStep === 0) {
        this.sendAmplitudeEvent('whatsapp_signup_waba_integration_completed', {
          uiArea: 'wa_embedded_signup_channels_page',
          userId: this.user && this.user.UserId,
          timestamp,
        });

        this.activeStep = this.activeStep + 1;
      }
    },

    async processWABA() {
      this.retry = false;
      this.loading = true;
      this.loadingText = 'Processing WABA...';
      this.step = 'setup-waba';

      try {
        // Setup WABA integrations
        await this.processIntegration({ wabaId: this.selectedWabaId });

        // Create WhatsApp subaccount
        await this.createChatappSubAccount();
      } catch (error) {
        let msg = error.message || '';
        if (error && error.error && error.error.message) {
          msg = error.error.message;
        }

        if (error && error.data && error.data.error) {
          msg = error.data.error;
        }

        this.retry = true;
        this.retryMessage =
          'An error occurred while setting up your WhatsApp Business Account. Please try again.';
        this.errorMessage = msg;
      } finally {
        this.loading = false;
      }
    },

    async createChatappSubAccount() {
      try {
        const { name: channelName } = this.waFormDetails;
        const createSaDetails = {
          channelName,
          subAccountName: `${this.user.AccountId}_CA_WA_${this.getRandomString(3)}`,
          additionalChannel: true,
        };

        this.retry = false;
        this.loading = true;
        this.loadingText = 'Creating sub account...';
        this.step = 'create-subaccount';

        // Create SubAccount to be used in Prepaid CA
        this.createdSubaccount = await this.submitPrepaidCA(createSaDetails);

        // Fetch user data in Nav for updated values
        const event = new Event('fetchUserData');
        window.dispatchEvent(event);

        // Send amplitude event
        this.sendAmplitudeEvent('whatsapp_signup_subaccount_created', {
          uiArea: 'wa_embedded_signup_channels_page',
          userId: this.user && this.user.UserId,
          subAccountId: this.createdSubaccount.SubAccountId,
          timestamp: Moment().format(),
        });

        // Show user notification
        this.$notify({
          type: 'success',
          message: 'Sub account is successfully created.',
        });
      } catch (error) {
        let msg = error.message || '';
        if (error && error.error && error.error.message) {
          msg = error.error.message;
        }

        if (error && error.data && error.data.error) {
          msg = error.data.error;
        }

        this.retry = true;
        this.retryMessage = 'An error occurred while creating the subaccount. Please try again.';
        this.errorMessage = msg;
      } finally {
        this.loading = false;
        this.loadingText = '';
      }
    },

    async saveData() {
      this.retry = false;
      this.loading = true;
      this.loadingText = 'Finishing setup...';
      let subAccountName = '';

      if (this.selectedSubAccount) {
        subAccountName = this.getSubAccountName(this.selectedSubAccount);
      } else {
        subAccountName = this.createdSubaccount.SubAccountId;
      }

      let params = {
        type: 'WhatsApp',
        whatsappBusinessAccountId: this.selectedWabaId,
        whatsappTemplateNamespace: this.selectedPhoneNumberId,
        boundedSubAccount: subAccountName, // boundedSubAccount: this.subAccount && this.subAccount.SubAccountId,
        subAccountId: subAccountName, // this.selectedSubAccount,
        isUpdate: this.isUpdate,
        id: this.waChannelToUpdate ? this.waChannelToUpdate.id : null,
        userId: this.user.UserId,
        accountId: this.user.AccountId,
      };

      if (this.waFormDetails) {
        const {
          name: channelName,
          accountName,
          email,
          street,
          city,
          state,
          country,
          zipcode,
          tagline: about,
          description,
          category,
          waCategory,
          websiteUrl: accountUrl,
        } = this.waFormDetails;

        const address = `${street} ${city}, ${state}, ${country} ${zipcode}`;
        params = {
          ...params,
          name: channelName,
          comment: `${channelName} WA Channel created from Connect`,
          accountName,
          about,
          description,
          accountUrl,
          email,
          category,
          waCategory,
          address: address.trim(),
          userEmail: this.user.Email || this.user.Login || '',
        };
      }

      if (this.isUpdate) {
        params = {
          ...this.waChannelToUpdate,
          ...params,
        };
      }

      this.savedDetails = params;
      this.loading = false;

      try {
        await this.saveWAESDetails(params);

        // Send amplitude event
        this.sendAmplitudeEvent('whatsapp_signup_channel_creation_started', {
          uiArea: 'wa_embedded_signup_channels_page',
          userId: this.user && this.user.UserId,
          channelName,
          timestamp: Moment().format(),
        });
      } catch (error) {
        let msg = error.message || '';
        if (error && error.error && error.error.message) {
          msg = error.error.message;
        }

        this.errorMessage = msg;
      } finally {
        this.loading = false;
        this.loadingText = '';
      }
    },

    getSubAccountName(id) {
      const sa = this.subaccounts.find((s) => s.SubAccountUid === id);

      return sa ? sa.SubAccountId : '';
    },

    getPhoneLabel(p) {
      return `${p.verifiedName} (${p.phoneNumber})`;
    },

    getLabel(sa) {
      const channel = this.getChannelData.find(c => c.type === 'WhatsApp' && c.boundedSubAccount === sa.SubAccountId && c.status === 'Active'); // eslint-disable-line

      return channel ? `${sa.SubAccountId} (Bounded)` : sa.SubAccountId;
    },

    getValidSubAccounts() {
      const getLinkedChannel = (subAccountId) => {
        return this.getChannelData.find(c => c.type === 'WhatsApp' && c.boundedSubAccount === subAccountId && c.status === 'Active'); // eslint-disable-line
      };

      const validSubAccounts = this.subaccounts.filter(
        (s) => s.Product_CA && !getLinkedChannel(s.SubAccountId)
      );

      return validSubAccounts;
    },

    getRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result.trim();
    },
  },
};
</script>

<style lang="scss" scoped>
.wa-steps {
  li {
    margin: 7px 0;
  }
}

.waes-progress {
  height: 280px;
}

.channel-setup {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}

.wa-modal {
  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog .el-dialog__body {
    padding: 30px 20px !important;
  }
}

.subaccount-tag {
  background: #f1f1f1;
  border-radius: 7px;
}

.step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 70px;
}

.actions {
  padding: 0px 70px;
}

.no-break {
  white-space: normal;
  word-break: keep-all;
  overflow-wrap: break-word;
}
</style>
