<template>
  <el-dialog
    :title="data.title"
    :visible.sync="visible"
    width="40%"
    :before-close="closeModal"
    top="60px"
    append-to-body
    @open="openModal"
  >
    <div v-if="form" class="ca-upload-media">
      <el-form ref="form" :model="form" :rules="rules" @submit.prevent.native>
        <el-form-item class="action">
          <el-radio v-model="form.action" label="upload" :disabled="uploading">
            {{ $t('mcs.messages.chat_apps.custom_fields[7]') }}
          </el-radio>
          <el-radio
            v-model="form.action"
            label="url"
            :disabled="uploading || data.type === 'video'"
          >
            {{ $t('mcs.messages.chat_apps.custom_fields[4]') }}
          </el-radio>
        </el-form-item>
        <div v-if="form && form.action === 'upload'" v-loading="uploading" class="min-h-full">
          <el-upload
            v-if="!form.fileName"
            ref="upload"
            class="w-full upload-media"
            :accept="
              mediaInfo(data.type, data.maxFileSize, data.supportedFiles).supportedFiles.toString()
            "
            :show-file-list="false"
            :http-request="
              (f) => onRequest(f, mediaInfo(data.type, data.maxFileSize, data.supportedFiles))
            "
            :limit="1"
            drag
            action=""
          >
            <div class="min-h-full" :style="{ backgroundColor: '#F1F6FF' }">
              <i class="el-icon-upload text-sm"></i>
              <!-- <div class="text-xs">
                Drag & drop to upload
                <br/>or <em class="text-blue roman">browse</em> to choose a file.
              </div> -->
              <i18n path="upload_labels.drag_drop" tag="div" class="text-xs">
                <template #br>
                  <br />
                </template>
                <template #browse>
                  <em class="text-blue roman">{{ $t('actions.browse') }}</em>
                </template>
              </i18n>
              <div slot="tip" class="el-upload__tip text-center mt-6">
                {{ $t('upload_labels.supported_types') }}
                {{
                  mediaInfo(
                    data.type,
                    data.maxFileSize,
                    data.supportedFiles
                  ).supportedFilesTxt.join(', ')
                }}
                ({{
                  $t('upload_labels.size_less_than', {
                    size: `${
                      mediaInfo(data.type, data.maxFileSize, data.supportedFiles).maxSize
                    }MB`,
                  })
                }})
              </div>
            </div>
          </el-upload>
          <div
            v-else
            class="border border-grey solid rounded"
            :style="{ backgroundColor: '#F1F6FF', minHeight: '275px' }"
          >
            <div class="bg-white p-3 text-left rounded-t flex">
              <div class="flex-auto pt-1">
                <span class="text-xs text-black truncate">
                  {{ form.fileName }}
                </span>
                <span class="text-xs text-grey-dark ml-3">
                  {{ Number(getFileSize(form.fileSize).size).toLocaleString() }}
                  {{ getFileSize(form.fileSize).label }}
                </span>
              </div>
              <div class="flex-shrink text-right ml-5">
                <el-button size="mini" type="text" class="text-xs font-normal" @click="reUpload">
                  {{ $t('upload_labels.reupload') }}
                </el-button>
              </div>
            </div>
            <div v-loading="loading" class="p-3">
              <div class="flex flex-col justify-center" :style="{ minHeight: '220px' }">
                <div v-if="data.type.match(/image|video/i)" ref="contentRef" class="text-center" />
                <div
                  v-if="data.type.match(/document/i) && form.previewUrl"
                  class="flex justify-center items-center"
                >
                  <div class="relative bg-white">
                    <img
                      :src="urlResolver(bgUrl, require('@/assets/misc/pdf.png'))"
                      :style="{ width: '180px' }"
                    />
                    <a
                      :href="form.previewUrl"
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      :style="{ bottom: '0px' }"
                      class="no-underline absolute block text-xs text-white px-1 py-2 bg-black opacity-75 w-full truncate"
                    >
                      {{ getFileNameInUrl(form.previewUrl) }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <el-form-item
            class="text-xs file-url"
            prop="fileUrl"
            :label="$t('mcs.messages.chat_apps.custom_fields[4]')"
          >
            <el-input
              v-model="form.fileUrl"
              size="small"
              :style="{ width: '80%' }"
              :placeholder="$t('mcs.messages.chat_apps.custom_fields[4]')"
            />
          </el-form-item>
          <div
            v-if="
              data.type.match(/document|image/i) &&
              form.fileUrl &&
              (isImageOnly(form.fileUrl) || isPDFOnly(form.fileUrl))
            "
            class="mt-3 flex justify-center items-center"
          >
            <div class="relative bg-white">
              <img
                :src="
                  data.type === 'document'
                    ? urlResolver(bgUrl, require('@/assets/misc/pdf.png'))
                    : form.fileUrl
                "
                :style="{ width: '180px' }"
              />
              <a
                :href="form.fileUrl"
                download
                target="_blank"
                rel="noopener noreferrer"
                :style="{ bottom: '0px' }"
                class="no-underline hover:text-blue absolute block text-xs text-white px-1 py-2 bg-black opacity-75 w-full truncate"
              >
                {{ getFileNameInUrl(form.fileUrl) }}
              </a>
            </div>
          </div>
          <div class="mt-5 text-xs">
            {{ $t('upload_labels.supported_types') }}:
            {{
              mediaInfo(data.type, data.maxFileSize, data.supportedFiles).supportedFilesTxt.join(
                ', '
              )
            }}
            (({{
              $t('upload_labels.size_less_than', {
                size: `${mediaInfo(data.type, data.maxFileSize, data.supportedFiles).maxSize}MB`,
              })
            }})
          </div>
        </div>
        <div class="mt-10">
          <el-button size="small" @click="closeModal">
            {{ $t('actions.discard') }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            :disabled="!form.fileUrl || uploading"
            :loading="loading"
            @click="submit"
          >
            {{
              form.action === 'upload'
                ? $t('upload_labels.attach_file')
                : $t('mcs.messages.chat_apps.media_url[2]')
            }}
          </el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import { formatBytes, urlResolver } from '@/utils/common';

import MediaMixin from '@/mixins/media';

export default {
  name: 'UploadMedia',

  mixins: [MediaMixin],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const self = this;

    return {
      uploading: false,
      loading: false,

      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),

      form: {
        action: 'upload',
        fileUrl: '',
        fileSize: '',
        fileName: '',
        fileType: '',
        previewUrl: '',
        blobUrl: '',
      },

      rules: {
        fileUrl: self.rules({ name: self.data.type }),
      },
    };
  },

  watch: {
    'form.action': {
      handler(v) {
        this.resetForm(v);
      },
    },
  },

  methods: {
    ...mapActions({
      getUploadUrl: 'common/getUploadUrl',
      getDownloadUrl: 'common/getDownloadUrl',
      downloadFileUrl: 'common/downloadFileUrl',
      request: 'common/request',
    }),

    urlResolver(url, imgUrl) {
      return urlResolver(url, imgUrl);
    },

    openModal() {
      this.resetForm();
    },

    closeModal() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.resetForm();
      this.$emit('close-modal');
    },

    reUpload() {
      this.resetForm('upload');
    },

    onRequest(data, mediaInfo) {
      const { file } = data;

      const isLt2M = file.size / 1024 / 1024 < mediaInfo.maxSize;
      // eslint-disable-next-line no-useless-escape
      const ext = file.name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

      if (!file.size || !isLt2M) {
        if (!file.size) {
          this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[4]'));
        }

        if (!isLt2M) {
          this.$message.error(
            `${this.$t('mcs.messages.chat_apps.upload_media[5]')} ${mediaInfo.maxSize}MB!`
          );
        }
        this.resetForm();
        return;
      }

      if (
        ext &&
        ext.length > 1 &&
        !this.mediaInfo(
          this.data.type,
          data.maxFileSize,
          data.supportedFiles
        ).supportedFilesTxt.includes(ext[1].toLowerCase())
      ) {
        if (this.data.type === 'document') {
          this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[0]'));
        } else if (this.data.type === 'image') {
          this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[1]'));
        } else if (this.data.type === 'video') {
          this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[2]'));
        } else {
          this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[3]'));
        }
        this.resetForm();
        return;
      }

      this.uploadMediaFile(data);
    },

    async uploadMediaFile(data) {
      const { file } = data;

      try {
        this.uploading = true;

        const uploadData = await this.getUploadUrl({ file });
        const { uploadUrl, dbUrl, mimeType } = uploadData;

        const storageOrigin = uploadUrl ? uploadUrl.split('/')[2] : ''; // If from S3 or Google Cloud Storage

        const headers =
          storageOrigin === 'storage.googleapis.com'
            ? {
                'Content-Type': mimeType,
              }
            : {
                'Content-Type': mimeType,
                'Content-Disposition': `attachment;filename="${file.name}"`,
              };

        const uploadReq = {
          method: 'PUT',
          url: uploadUrl,
          data: file,
          headers,
        };

        await this.request({ ...uploadReq });

        // Decode jwt and determine region based on RegionId
        const token = localStorage.getItem('WWW-Authenticate');
        const decodedData = this.decodeJwt(token);
        const { apiConfig } = this.http;
        let regionId = null;
        let rootUrl = '';

        if (decodedData && Object.keys(decodedData).includes('RegionId')) {
          const { RegionId: region } = decodedData;
          regionId = region;
        }

        rootUrl = regionId !== null ? apiConfig['v2'][regionId] : window.location.origin;

        this.form.fileUrl = `${rootUrl}${dbUrl}?regionId=${regionId}`;
        this.form.fileSize = file.size;
        this.form.fileName = file.name;
        this.form.fileType = mimeType;

        await setTimeout(() => {
          this.displayUploadedFile();
        }, 0);
      } catch (err) {
        // eslint-disable-next-line no-console
        // console.error('file upload error', e);
        this.resetForm();
        this.$showError(this, err);
        // this.$message.error('Fail to upload. Please try again.');
      } finally {
        this.uploading = false;
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }
      }
    },

    getFileSize(size) {
      const fileSize = parseInt(size, 10);

      return formatBytes(fileSize);
    },

    // Decode jwt token
    decodeJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
        );

        const decodedData = JSON.parse(jsonPayload);
        return decodedData;
      } catch (err) {
        this.$showError(this, err);
        console.error('Failed to validate region'); // eslint-disable-line
        return 0;
      }
    },

    getVideoLogo(fileType) {
      let logo = 'video.svg';

      if (fileType.match(/mp4/g)) {
        logo = 'mp4.svg';
      }

      if (fileType.match(/3gp/g)) {
        logo = '3gp.svg';
      }

      return logo;
    },

    displayUploadedFile() {
      const { type } = this.data;
      const { fileUrl } = this.form;
      this.loading = true;

      this.downloadFileUrl({ fileUrl })
        .then((response) => {
          this.form.previewUrl = response.data.url;

          if (this.$refs.contentRef) {
            if (type === 'image') {
              const imgRef = this.$refs.contentRef;

              imgRef.innerHTML = '';

              const img = new Image();

              img.width = 200;
              img.src = response.data.url;
              img.alt = 'loading...';
              img.style.verticalAlign = 'middle';

              img.onload = () => {
                imgRef.appendChild(img);
              };

              img.onerror = () => {
                this.$message.error('Image cannot be loaded.');
                this.form.previewUrl = '';
              };
            }

            if (type === 'video') {
              const videoRef = this.$refs.contentRef;
              const video = document.createElement('video');

              videoRef.innerHTML = '';

              video.src = response.data.url;

              video.controls = true;
              video.muted = true;
              video.width = 300; // in px

              videoRef.appendChild(video);

              video.addEventListener('error', () => {
                this.$message.error('Video cannot be loaded.');
              });
            }
          }
        })
        .catch((err) => {
          this.$message.error('File cannot be downloaded.');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetForm(action) {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
      this.form.action = action || 'upload';
      this.form.fileUrl = '';
      this.form.fileSize = '';
      this.form.fileName = '';
      this.form.fileType = '';
      this.form.previewUrl = '';
      this.form.blobUrl = '';
      this.uploading = false;
    },

    submit() {
      try {
        const data = JSON.parse(JSON.stringify(this.form));
        const row = JSON.parse(JSON.stringify(this.data));
        const { type } = row;

        // eslint-disable-next-line no-useless-escape
        const ext = data.fileUrl.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

        if (
          ext &&
          ext.length > 1 &&
          !this.mediaInfo(type, data.maxFileSize, data.supportedFiles).supportedFilesTxt.includes(
            ext[1].toLowerCase()
          )
        ) {
          if (type === 'document') {
            this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[0]'));
          } else if (type === 'image') {
            this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[1]'));
          } else if (type === 'video') {
            this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[2]'));
          } else {
            this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[3]'));
          }
          this.resetForm();
          return;
        }

        if (this.$refs.form) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              if (!data.previewUrl) {
                data.previewUrl = data.fileUrl;
              }

              this.$emit('apply-data', row, data);

              this.resetForm();
              this.$emit('close-modal');
            }
          });
        }
      } catch (err) {
        // if (window.Bugsnag) {
        //   window.Bugsnag.notify(e);
        // }
        this.$showError(this, err);
        // do nothing
      }
    },
  },
};
</script>
<style lang="scss">
.ca-upload-media {
  .upload-media {
    .el-upload {
      width: 100%;
    }

    .el-upload-dragger {
      width: 100%;
      min-height: 200px !important;
      background-color: #ffffff;
    }
  }

  .action {
    margin-bottom: 15px;
  }
}

// .file-url {
//   label {
//     line-height: 20px;
//     font-size: 11px;
//     float: none;
//   }
// }
</style>
