import { parsePhoneNumber } from 'awesome-phonenumber';

export default {
  methods: {
    isValidPhoneNumber(phoneNumber) {
      try {
        const { valid } = parsePhoneNumber(phoneNumber);
        return valid;
      } catch (error) {
        return false;
      }
    },
  },
};
