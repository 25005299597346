import Register from './views/Register.vue';
import Discover from './views/register/Discover.vue';
import CreateWA from './views/register/CreateWA.vue';
import Layout from './views/Layout.vue';
import Templates from './views/templates/Templates.vue';
import Dashboard from './views/dashboard/Dashboard.vue';
import Reports from './views/reports/Reports.vue';
import Channels from './views/channels/Channels.vue';
import Logs from './views/logs/Logs.vue';

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Layout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'logs',
        name: 'logs',
        component: Logs,
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
      },
      {
        path: 'channels',
        name: 'channels',
        component: Channels,
      },
      {
        path: 'templates',
        name: 'templates',
        component: Templates,
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    children: [
      {
        path: '',
        name: 'discover',
        component: Discover,
      },
      {
        path: 'create-wa',
        name: 'createWA',
        component: CreateWA,
      },
    ],
  },
];
