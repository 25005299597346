<template>
  <div v-if="rows.length">
    <div
      class="sample-reminder mt-5 text-black text-xs p-3 bg-yellow-lightest border border-solid border-yellow rounded mb-5 w-full leading-normal break-words-p"
    >
      {{ $t('chat_apps.templates.whats_app_template_guidelines') }}
    </div>

    <el-table :data="rows" size="small">
      <el-table-column
        v-slot="scope"
        :label="$t('column_labels.dynamic_fields')"
        prop="label"
        scope="scope"
        width="200"
      >
        <span class="text-xs">{{ scope.row.label || '' }}</span>
      </el-table-column>
      <el-table-column prop="value" scope="scope">
        <template slot="header">
          <span>
            {{ capitalizeF($t('chat_apps.templates.sample_content')) }}
            <el-popover placement="top-start" width="300" trigger="hover">
              <div class="sample-reminder text-xs">
                {{ $t('chat_apps.templates.whats_app_sample_purpose') }}
              </div>
              <i slot="reference" class="el-icon-question" />
            </el-popover>
          </span>
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="'samples.' + scope.$index + '.value'"
            :rules="rules(scope.row, scope.row.field)"
            class="mt-2"
          >
            <span>
              <el-input
                v-model="rows[scope.$index].value"
                class="w-full"
                size="small"
                :placeholder="getPlaceHolder(scope.row.name, scope.row.field)"
                @focus="
                  {
                    if (scope.row.name && scope.row.name === 'video') {
                      onSelectRow(scope.$index, scope.row);
                    }
                  }
                "
                @change="
                  (value) => {
                    onChange(value, scope.$index);
                  }
                "
              >
                <i
                  v-if="
                    scope.row.name &&
                    ['image', 'location', 'video', 'document'].includes(scope.row.name) &&
                    !['name', 'address'].includes(scope.row.field)
                  "
                  slot="suffix"
                  class="text-gray-darker cursor-pointer"
                  @click="onSelectRow(scope.$index, scope.row)"
                >
                  <el-tooltip
                    effect="dark"
                    :content="
                      scope.row.name.match(/location/)
                        ? $t('mcs.messages.chat_apps.custom_fields[5]')
                        : $t('mcs.messages.chat_apps.custom_fields[7]')
                    "
                    placement="top"
                  >
                    <i
                      class="font-semibold text-sm"
                      :class="
                        scope.row.name.match(/location/)
                          ? 'el-icon-location-outline'
                          : 'el-icon-upload2'
                      "
                    />
                  </el-tooltip>
                </i>
              </el-input>
            </span>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <UploadMedia
      v-if="selectedRow && Object.keys(selectedRow).length && selectedRow.action === 'upload'"
      :data="selectedRow"
      :visible="visible"
      @apply-data="applyData"
      @close-modal="closeModal"
    />
    <LocationMedia
      v-if="selectedRow && Object.keys(selectedRow).length && selectedRow.action === 'location'"
      :data="selectedRow"
      :visible="visible"
      @apply-data="applyData"
      @close-modal="closeModal"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import MediaMixin from '@/mixins/media';

import UploadMedia from './UploadMedia';
import LocationMedia from './LocationMedia';

export default {
  name: 'TemplateSamples',

  components: {
    UploadMedia,
    LocationMedia,
  },

  mixins: [MediaMixin],

  props: {
    samples: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      rows: [],
      visible: false,
      selectedRow: {},
    };
  },

  watch: {
    samples(samples) {
      this.rows = samples;
    },

    rows(rows) {
      this.$emit('apply-sample-changes', JSON.parse(JSON.stringify(rows)));
    },
  },

  methods: {
    closeModal() {
      this.visible = false;
      this.selectedRow = {};
    },

    capitalizeF(str = '') {
      return _.startCase(str);
    },

    onChange(value, index) {
      this.rows[index].value = value;

      this.$emit('apply-sample-changes', this.rows);
    },

    onSelectRow(index, row) {
      if (row.name && ['image', 'video', 'document'].includes(row.name.toLowerCase())) {
        const data = {
          action: 'upload',
          title: row.label,
          index,
          type: row.name.toLowerCase(),
        };

        this.selectedRow = { ...data };

        this.visible = true;

        return;
      }

      if (row.name && ['location'].includes(row.name.toLowerCase())) {
        const data = {
          action: 'location',
          title: row.label,
          index,
          type: row.name.toLowerCase(),
        };

        this.selectedRow = { ...data };

        this.visible = true;

        return;
      }
    },

    getPlaceHolder(type, field = '') {
      if (type && type.match(/location/g)) {
        if (field === 'latlong') {
          return this.$t('validations.valid', {
            value: `${this.$t('chat_apps.templates.sample_latlong')} (${this.$t(
              'app_labels.separated_by_comma'
            )})`,
          });
        }

        if (field === 'name') {
          return this.$t('validations.valid', {
            value: this.$t('chat_apps.templates.sample_name'),
          });
        }

        if (field === 'address') {
          return this.$t('validations.valid', {
            value: this.$t('chat_apps.templates.sample_address'),
          });
        }
      }

      if (type && type.match(/video|document|image/g)) {
        return this.$t('validations.valid', {
          value: this.$t('chat_apps.templates.sample_media_url'),
        });
      }

      if (type && type.match(/url/g)) {
        return this.$t('validations.valid', { value: this.$t('chat_apps.templates.sample_url') });
      }

      return this.$t('validations.valid', { value: this.$t('chat_apps.templates.sample_value') });
    },

    applyData(row, data) {
      const { index } = row;
      const { previewUrl, lat, long } = data;
      if (previewUrl) {
        // add the the preview url (signed url which expired in 14 days)
        this.rows[index].value = previewUrl;
      }

      if (lat && long) {
        this.rows[index].value = `${lat},${long}`;
      }
    },
  },
};
</script>

<style>
.sample-reminder {
  word-break: break-word !important;
}
</style>
