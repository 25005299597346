import http from '../../../utils/http';

export default {
  // Fetch filter enums
  getFilterEnums: async ({ commit }, payload = {}) => {
    const query = Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&');
    try {
      const { data } = await http.get(`/chats/templates/enums?${query}`);
      commit({ type: 'SET_FILTER_ENUMS', enums: data });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Fetch subaccounts
  getSubaccounts: async ({ commit }) => {
    try {
      const { data } = await http.get('/chats/sub_accounts');

      commit({ type: 'SET_SUBACCOUNTS', subaccounts: data });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Fetch whatsapp channel id
  getWAChannels: async ({ commit }) => {
    try {
      const { data } = await http.get('/chats/channels/whatsapp');
      commit({ type: 'SET_WA_CHANNELS', channels: data });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getUser({ commit }) {
    try {
      const cpv3User = localStorage.getItem('CPV3_User') || {};
      const userData = JSON.parse(cpv3User);

      const token = localStorage.getItem('WWW-Authenticate');
      const user = { ...userData, Token: token };

      commit('SET_USER', { user });
    } catch (err) {
      commit('API_ERROR', err, { root: true });
    }
  },
};
