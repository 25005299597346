<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('chat_apps.logs.title') }}
    </h1>
    <div class="flex -mx-3">
      <div class="px-3 w-full">
        <div class="w-full">
          <div class="relative">
            <div class="flex justify-between mb-10 items-start">
              <span class="text-sm text-grey block mr-10">
                {{ $t('chat_apps.logs.index[0]') }}
              </span>
              <div class="ml-auto">
                <!-- Phone filter -->
                <div class="w-full">
                  <el-input
                    v-model="msisdn"
                    :disabled="loading || filters.direction.match(/Incoming/)"
                    :debounce="debounceDefault"
                    suffix-icon="el-icon-search"
                    :placeholder="$t('chat_apps.logs.index[1]')"
                    size="small"
                    :class="`w-64 form-item ${!phoneValid ? 'is-error' : ''}`"
                    @change="(v) => updateContactFilter()"
                  />
                  <div v-if="!phoneValid" class="mt-1 pn text-red-light text-xs">
                    Invalid phone number
                  </div>
                </div>
              </div>
            </div>
            <Table
              :loading="loading"
              :data="getLogsData"
              :language-list="countriesJson"
              :show-message-body="showMessageBody"
            />
          </div>
          <div class="my-5 mx-2 text-right">
            <el-pagination
              :page-sizes="innerPaginationDef.pageSizes"
              :page-size="parseInt(innerPaginationDef.pageSize, 10)"
              :layout="innerPaginationDef.layout"
              @size-change="handleSizeChange"
              @prev-click="prevPage"
              @next-click="nextPage"
            />
          </div>
        </div>
      </div>
      <div class="main__aside px-3">
        <div class="pt-20 -mt-20 sticky pin-t">
          <Filters
            :loading="loading"
            :filters="filters"
            :channel-list="channelsJson"
            :status-list="statusList"
            :subaccount-list="getSubaccountsList"
            :country-list="countriesJson"
            :default-daterange="defaultDaterange"
            @update-filter="updateFilter"
            @apply-filters="getData"
          />
          <div class="mt-5 shadow p-5 rounded flex flex-col justify-center">
            <p class="text-sm text-black text-center">
              {{ $t('chat_apps.logs.index[2]') }}
            </p>
            <el-button class="mt-2" size="small" type="primary" @click="displayExportLogs(true)">
              {{ $t('actions.export') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <ExportModal
      :title="$t('chat_apps.logs.index[3]')"
      :filters="filters"
      :is-visible="showExportModal"
      :subaccount-list="getSubaccountsList"
      :country-list="countriesJson"
      @submit="getDownloadLogs"
      @close-modal="displayExportLogs(false)"
    />
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

import channelsJson from '@/json/channels.json';
import countriesJson from '@/json/countries.json';
import Table from './partials/Table.vue';
import Filters from './partials/Filters.vue';
import ExportModal from './partials/ExportModal.vue';
import phoneNumber from '../../mixins/phoneNumber';

export default {
  name: 'Logs',

  components: {
    Table,
    Filters,
    ExportModal,
  },

  mixins: [phoneNumber],

  data() {
    return {
      defaultDaterange: [
        Number(Moment().startOf('day').subtract(1, 'w')),
        Number(Moment().endOf('day')),
      ],

      showExportModal: false,

      channelsJson,

      countriesJson,

      debounceDefault: 500,

      msisdn: '',
      paginationDef: {
        layout: 'sizes, prev, next',
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
      },
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    ...mapState({
      filters: (state) => state.logs.filters,
      loading: (state) => state.logs.loading,
    }),

    ...mapGetters({
      getSubaccountsList: 'user/getSubaccountsList',
      getLogsData: 'logs/getLogsData',
      statusList: 'logs/getStatusList',
      pageInfo: 'logs/getPageInfo',
      showMessageBody: 'user/getMessageBodyAccess',
      waChannels: 'user/waChannels',
    }),

    phoneValid() {
      if (this.msisdn === '') return true;
      return this.isValidPhoneNumber(this.msisdn);
    },

    innerPaginationDef() {
      return Object.assign(
        {},
        {
          layout: 'sizes, prev, next',
          pageSizes: [10, 20, 50, 100],
        },
        this.paginationDef
      );
    },
  },

  created() {
    // Initialize defualt filter
    this.setFilters({
      filters: {
        ...this.filters,
        status: '',
        subAccountId: '',
        channel: '',
        daterange: this.defaultDaterange,
        offset: this.timeZoneOffset,
      },
    });

    this.loadSelectOptions();
  },

  methods: {
    ...mapMutations({
      setFilters: 'logs/SET_FILTERS',
      setPage: 'logs/SET_PAGE',
      setLogs: 'logs/SET_LOGS',
    }),

    ...mapActions({
      getLogs: 'logs/getLogs',
      getSubaccounts: 'user/getSubaccounts',
      getFilterEnums: 'user/getFilterEnums',
      getStatusList: 'logs/getStatusList',
      exportLogsData: 'logs/exportLogsData',
      getWAChannels: 'user/getWAChannels',
    }),

    nextPage() {
      this.setFilters({
        filters: {
          ...this.filters,
          dir: 1,
        },
      });
      this.getData(true);
    },

    prevPage() {
      this.setFilters({
        filters: {
          ...this.filters,
          dir: -1,
        },
      });
      this.getData(true);
    },

    handleSizeChange(size) {
      this.paginationDef.pageSize = size;
      this.setPage({
        pageInfo: {
          index: 0,
          end: false,
        },
      });
      this.setFilters({
        filters: {
          ...this.filters,
          limit: size,
          dir: 0,
        },
      });
      this.getData(true);
    },

    async getDownloadLogs(p) {
      // Convert all subaccount values to subaccounts name
      const subAccounts = this.getSubaccountsList.reduce((list, item) => {
        if (p.subAccounts.includes(item.value)) {
          list.push(item.name);
        }
        return list;
      }, []);

      const payload = {
        ...p,
        subAccounts,
      };

      try {
        await this.exportLogsData(payload);
        this.$notify({
          title: this.$t('chat_apps.reports.index[2]'),
          message: this.$t('chat_apps.reports.index[3]'),
          type: 'success',
          duration: 8000,
        });
        this.showExportModal = false;
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error('Oops, failed to export logs');
      }
    },

    // Update filter state
    updateFilter(filter = {}) {
      this.setFilters({
        filters: {
          ...this.filters,
          ...filter,
          prev: '',
          next: '',
          dir: 0,
        },
      });
    },

    // Fetch logs data
    getData(isPagination = false) {
      // true
      if (!isPagination) {
        this.setLogs({
          logs: [],
        });
        this.setPage({
          pageInfo: {
            index: 0,
            end: false,
          },
        });
      }
      this.getLogs({ ...this.filters, pageInfo: this.pageInfo }).catch((err) => {
        this.$showError(this, err);
      });
    },

    updateContactFilter() {
      this.setFilters({
        filters: {
          ...this.filters,
          msisdn: this.msisdn,
          prev: '',
          next: '',
          dir: 0,
        },
      });
      this.getData();
    },

    async loadSelectOptions() {
      try {
        await this.getSubaccounts();

        // Set init subaccount filter value
        if (this.getSubaccountsList.length) {
          this.setFilters({
            filters: {
              ...this.filters,
              subAccountId: this.getSubaccountsList[0].value,
            },
          });
        }

        await this.getStatusList();

        // Get enums
        // await this.getWAChannels();

        // const channels = this.waChannels;
        // const channelId = (channels && channels.length && channels[0].id) || null;

        // await this.getFilterEnums({ channelId });

        await this.getLogs(this.filters);

        this.setPage({
          pageInfo: {
            index: 0,
            end: false,
          },
        });
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error('Failed to load filter options. Please try again.');
      }
    },

    displayExportLogs(bool = false) {
      this.showExportModal = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}

.el-input.is-error {
  .el-input__inner {
    border: solid 1px #cb2233 !important;
  }
}
</style>
