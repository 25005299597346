<!-- eslint-disable no-underscore-dangle -->
<script>
import { Line, mixins } from 'vue-chartjs';

export default {
  name: 'LineChart',

  extends: Line,

  mixins: [mixins.reactiveProp],

  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'right',
            labels: {
              boxWidth: 13,
            },
          },
        };
      },
    },
  },

  watch: {
    options: {
      deep: true,
      handler(options) {
        // eslint-disable-next-line no-underscore-dangle
        this.$data._chart.options = options;
        // eslint-disable-next-line no-underscore-dangle
        this.$data._chart.destroy();
        this.renderChart(this.chartData, options);
      },
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
