<template>
  <div class="mt-8">
    <p class="text-sm text-grey-darker w-full mt-8">
      {{ $t('chat_apps.register.facebook_setup.select_phone') }}
    </p>
    <el-row v-if="!subAccount" class="mt-5">
      <el-col :offset="3" :span="8">
        <div
          class="channel-card bg-white shadow w-full block p-3 text-center rounded mt-2"
          :disabled="phoneDetails && phoneDetails.length"
          @click="launchWhatsAppSignup()"
        >
          <img :src="getIcon(channelCode)" width="25" height="25px" alt="channel-icon" />
          <p class="mt-2 text-xs text-black">
            {{ $t('chat_apps.channels.waes.title') }}
          </p>
          <p class="text-xs text-blue mt-2">
            {{ $t('chat_apps.channels.card[2]') }}
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row class="mt-5">
      <el-col
        v-if="setupData.facebookSetup.processRetry || setupData.facebookSetup.submitRetry"
        :offset="3"
        :span="8"
      >
        <p class="text-red pt-6 pb-3 text-sm no-break">
          {{ $t('chat_apps.register.facebook_setup.retry_message') }}
        </p>
        <p v-if="errMessage" class="text-red text-sm no-break">
          {{ errMessage }}
        </p>
      </el-col>
      <el-col v-else :offset="3" :span="8">
        <div v-if="subAccount">
          <div class="mb-5">
            <center>
              <span class="font-bold text-sm mb-2 no-break"
                >A new WhatsApp subaccount has been created!</span
              >
              <!-- <br />
              <span class="text-xs">See the details below.</span> -->
            </center>
          </div>
          <div class="subaccount-tag flex p-5 m-3 justify-around">
            <p>Subaccount ID</p>
            <span>|</span>
            <p>{{ subAccount.SubAccountId }}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <p class="pb-8 pt-8">&nbsp;</p>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import amplitude from '../../mixins/amplitude';
import whatsAppIcon from '@/assets/channels/whatsapp.svg';
import Moment from 'moment';

export default {
  name: 'FacebookSetup',
  mixins: [amplitude],

  props: {
    setupData: {
      type: Object,
      default: () => {},
    },

    user: {
      type: Object,
      default: () => {},
    },

    update: {
      type: Function,
      default: () => {},
    },

    setLoading: {
      type: Function,
      default: () => {},
    },

    triggerRetry: {
      type: Boolean,
      default: false,
    },

    submit: {
      type: Function,
      default: () => {},
    },

    errMessage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      channelCode: 'WA',
      accessToken: null,
    };
  },

  computed: {
    ...mapState('channels', [
      'caSystemUsers',
      'selectedWabaId',
      'selectedPhoneNumberId',
      'productMeta',
      'subAccount',
    ]),
    ...mapGetters({
      user: 'user/getUser',
    }),
  },

  watch: {
    triggerRetry: {
      immediate: true,
      handler(val) {
        if (val) {
          if (val) this.processWABA();
        }
      },
    },
  },

  async created() {
    window.fbAsyncInit = () => {
      // JavaScript SDK configuration and setup
      // eslint-disable-next-line
      FB.init({
        appId: process.env.VUE_APP_FB_WHATSAPP_APP_ID, // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v19.0', // GAPI version
      });
    };

    // Load the JavaScript SDK asynchronously
    ((d, s, id) => {
      let fjs = d.getElementsByTagName(s)[0]; // eslint-disable-line
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  },

  mounted() {
    window.addEventListener('message', this.sessionInfoListener);
  },

  beforeDestroy() {
    window.removeEventListener('message', () => {});
  },

  methods: {
    ...mapActions({
      getSharedWABAId: 'channels/getSharedWABAId',
      processIntegration: 'channels/processIntegration',
      uploadProfilePicture: 'channels/uploadProfilePicture',
    }),
    ...mapMutations('channels', [
      'SET_SELECTED_WABA_ID',
      'SET_SELECTED_PHONE_NUMBER_ID',
      'SET_SUB_ACCOUNT',
    ]),

    sessionInfoListener(event) {
      if (event.origin == null) {
        return;
      }

      // Make sure the data is coming from facebook.com
      if (!event.origin.endsWith('facebook.com')) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          const { event: statusType } = data;
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH') {
            const { phone_number_id: phoneNumberId, waba_id: wabaId } = data.data;

            this.SET_SELECTED_WABA_ID({ data: wabaId });
            this.SET_SELECTED_PHONE_NUMBER_ID({ data: phoneNumberId });
            this.sendAmplitudeEvent('whatsapp_signup_fb_setup_finished', {
              uiArea: 'wa_embedded_signup_page',
              phoneNumberId,
              wabaId,
              timestamp: Moment().format(),
            });
          }
          // if user reports an error during the Embedded Signup flow
          else if (data.event === 'ERROR') {
            const { error_message: errorMessage, current_step: currentStep } = data.data;
            console.error('ERROR IN FACEBOOK SIGNUP: ', errorMessage);
            this.sendAmplitudeEvent('whatsapp_signup_fb_setup_error', {
              uiArea: 'wa_embedded_signup_page',
              userId: this.user && this.user.UserId,
              stepName: currentStep,
              timestamp: Moment().format(),
              errorMessage: errorMessage,
            });
          }
          // if user cancels the Embedded Signup flow
          else {
            const { current_step: currentStep } = data.data;
            this.sendAmplitudeEvent('whatsapp_signup_fb_setup_cancelled', {
              uiArea: 'wa_embedded_signup_page',
              userId: this.user && this.user.UserId,
              stepName: currentStep,
              timestamp: Moment().format(),
            });
          }
        }
      } catch (e) {
        // Don’t parse info that’s not a JSON
        console.log('Non JSON Response', event.data);
      }
    },

    getIcon(code) {
      let icon = '';
      switch (code) {
        case 'WA':
          icon = whatsAppIcon;
          break;
        default:
          break;
      }
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    getPhoneLabel(p) {
      return `${p.verifiedName} (${p.phoneNumber})`;
    },

    showErrorMessage(msg, details) {
      if (window.Bugsnag) {
        const bugSnag = window.Bugsnag;

        bugSnag.notify(new Error(msg), (event) => {
          event.severity = 'error'; // eslint-disable-line
          event.addMetadata('details', details); // eslint-disable-line
          event.addMetadata('user', this.user); // eslint-disable-line
          event.addMetadata('setupData', this.setupData); // eslint-disable-line
        });
      }

      this.$message.error(this.$t('chat_apps.channels.waes.set_account_error'));
      console.log('An error occurred during the WhatsApp channel setup.'); // eslint-disable-line
    },

    async processWABA() {
      try {
        if (!this.wabaId) {
          this.$emit('update', {
            facebookSetup: {
              selectedWabaId: this.selectedWabaId,
              selectedPhoneNumberId: this.selectedPhoneNumberId,
              wabaSetupDone: false,
              processRetry: false, // For when processIntegration() returns an error
              triggerRetry: false, // Retry button to show
              submitRetry: false, // For when creating a CA subaccount returns an error
              submitDone: false,
              errMessage: '.',
            },
          });
        }
        // Upload profile picture
        // Should be a non blocker step
        await this.uploadAccountProfilePicture();

        this.$emit('update', {
          facebookSetup: {
            selectedWabaId: this.selectedWabaId,
            selectedPhoneNumberId: this.selectedPhoneNumberId,
            wabaSetupDone: false,
            processRetry: false, // For when processIntegration() returns an error
            triggerRetry: false, // Retry button to show
            submitRetry: false, // For when creating a CA subaccount returns an error
            submitDone: false,
            errMessage: null,
          },
        });

        this.$emit('set-loading', { loading: true, text: 'Processing WABA...' });
        await this.processIntegration({ wabaId: this.selectedWabaId });

        this.$emit('update', {
          facebookSetup: {
            selectedWabaId: this.selectedWabaId,
            selectedPhoneNumberId: this.selectedPhoneNumberId,
            wabaSetupDone: true,
            processRetry: false,
            triggerRetry: false,
            submitRetry: false,
            submitDone: false,
            errMessage: null,
          },
        });

        // This triggers the creation of the CA subaccount
        this.$emit('set-loading', { loading: true, text: 'Creating sub account...' });
        this.$emit('submit');
      } catch (err) {
        const details = {
          selectedWabaId: this.selectedWabaId,
          productMeta: this.productMeta,
        };

        this.showErrorMessage(`Error processing WABA ID: ${err.message}`, details);
        this.SET_SUB_ACCOUNT({ data: null });
        this.$emit('set-loading', { loading: false, text: '' });
        this.$emit('update', {
          facebookSetup: {
            selectedWabaId: this.selectedWabaId,
            selectedPhoneNumberId: this.selectedPhoneNumberId,
            wabaSetupDone: false,
            processRetry: true,
            triggerRetry: false,
            submitRetry: false,
            submitDone: false,
            errMessage: err.message,
          },
        });
      }
    },

    async uploadAccountProfilePicture() {
      try {
        const { accessToken } = this;
        if (!accessToken) {
          throw new Error('No access token from Facebook response.');
        }

        const {
          contactInfo: { profilePicture },
        } = this.setupData;

        if (!profilePicture) {
          throw new Error('Profile picture is null.');
        }

        this.$emit('set-loading', { loading: true, text: 'Uploading profile picture...' });
        await this.uploadProfilePicture({
          phoneNumberId: this.selectedPhoneNumberId,
          accessToken,
          file: profilePicture,
        });
      } catch (err) {
        if (window.Bugsnag) {
          const bugSnag = window.Bugsnag;

          bugSnag.notify(
            new Error(`Failed to upload WABA profile picture: ${err.message}`),
            (event) => {
              event.severity = 'error';
              event.addMetadata('phoneNumberId', this.selectedPhoneNumberId);
              event.addMetadata('wabaId', this.wabaId);
              event.addMetadata('user', this.user);
            }
          );
        }
      } finally {
        this.$emit('set-loading', { loading: false, text: '' });
      }
    },

    async launchWhatsAppSignup() {
      this.sendAmplitudeEvent('whatsapp_signup_fb_login_clicked', {
        uiArea: 'wa_embedded_signup_page',
        userId: this.user.UserId,
        timestamp: Moment().format(),
        accountId: this.user.AccountId,
      });

      const {
        businessDetails: { phoneNational, countryCode, accountName, waCategory, description },
        contactInfo: { email, websiteUrl, street, city, state, country, zipcode, timezone },
      } = this.setupData;

      let tZone = 'UTC+00';
      if (timezone) {
        const [tz] = timezone.split(' ');
        tZone = `UTC${tz}`;
      }

      const channelDetails = {
        business: {
          name: accountName,
          email,
          phone: {
            code: countryCode,
            number: phoneNational,
          },
          website: websiteUrl,
          address: {
            streetAddress1: street,
            city,
            state,
            zipPostal: zipcode,
            country,
          },
          timezone: tZone,
        },
        phone: {
          displayName: accountName,
          category: waCategory,
          description: description || '',
          code: countryCode,
          number: phoneNational,
        },
      };
      // Conversion tracking code
      // eslint-disable-next-line
      window.fbq && window.fbq('trackCustom', 'PrepaidCAStart', {
          appId: process.env.VUE_APP_FB_WHATSAPP_APP_ID,
          feature: 'whatsapp_embedded_signup',
        });

      // Launch Facebook login
      // eslint-disable-next-line
      FB.login((response) => {
          console.log('FB LOGIN RESPONSE: ', response);
          if (response.authResponse) {
            this.accessToken = response.authResponse.accessToken; // eslint-disable-line

            this.processWABA();
          } else {
            if (window.Bugsnag) {
              const bugSnag = window.Bugsnag;

              bugSnag.notify(
                new Error('User cancelled WhatsApp embedded signup or did not fully authorize.'),
                (event) => {
              event.severity = 'warning'; // eslint-disable-line
              event.addMetadata('response', response); // eslint-disable-line
              event.addMetadata('user', this.user); // eslint-disable-line
                }
              );
            }

            this.$message.error('User cancelled WhatsApp channel setup or did not fully authorize.'); // eslint-disable-line
            console.log('User cancelled WhatsApp embedded signup or did not fully authorize.'); // eslint-disable-line
          }
        },
        {
          scope: 'business_management,whatsapp_business_management',
          response_type: 'code',
          override_default_response_type: true,
          extras: {
            feature: 'whatsapp_embedded_signup',
            sessionInfoVersion: 3,
            setup: {
              business: channelDetails.business,
              phone: channelDetails.phone,
            },
          },
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.channel-card {
  cursor: pointer;
  transition: all 0.05s ease-in;
  border: solid 1px transparent;

  &:hover {
    border-color: #3490dc;
    transform: scale(1.03);
    transform-origin: center;
  }

  &__disabled {
    cursor: default;

    &:hover {
      border-color: #dae1e7;
      opacity: 0.7;
      transform: none;
      filter: grayscale(100%);
    }
  }
}

.subaccount-tag {
  background: #f1f1f1;
  border-radius: 7px;
}

.no-break {
  white-space: normal;
  word-break: keep-all;
  overflow-wrap: break-word;
}
</style>
