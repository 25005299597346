import Moment from 'moment';
import http from '../../../utils/http';

export default {
  // Fetch dashboard data
  getData: async ({ commit }, payload = {}) => {
    commit({ type: 'SET_LOADING', bool: true });
    const { prevDays, ...f } = payload;

    const subtractDay = prevDays > 0 ? prevDays : 0;

    const dates = [
      Moment().utc().subtract(subtractDay, 'days').startOf('day').unix(),
      Moment().utc().endOf('day').unix(),
    ];
    const p = { daterange: dates, ...f };
    const query = Object.keys(p)
      .map((key) => `${key}=${p[key]}`)
      .join('&');
    try {
      const { data } = await http.get(`/chats/summary?${query}`);
      commit({ type: 'SET_DATA', data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', bool: false });
    }
  },
};
