import http from '../../../utils/http';

export default {
  // Fetch templates
  async getTemplates({ commit }, payload = {}) {
    if (!payload.channelId) return;
    // commit({ type: 'SET_LOADING_TEMPLATE', bool: true });
    const query = Object.keys(payload)
      .map((key) => `${key}=${payload[key]}`)
      .join('&');
    try {
      const { data } = await http.get(`chats/templates?${query}`);
      commit({ type: 'FETCH_TEMPLATES', templates: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Save template
  async saveTemplate({ commit }, payload = {}) {
    try {
      return await http.post('/chats/templates', payload);
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Delete template
  async removeTemplate({ commit }, payload = {}) {
    const { template, filters } = payload;
    try {
      return await http.del(`/chats/templates/${filters.channelId}`, {
        name: template.templateName,
        channelId: template.channelId,
      });
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
