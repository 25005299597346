<template>
  <el-form
    :model="form"
    :rules="formRules"
    ref="form"
    class="leading-none"
    hide-required-asterisk
  >
    <el-form-item prop="name">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.channel_name') }}
        </p>
        <el-input
          v-model="form.name"
          :placeholder="$t('chat_apps.channels.messenger[0]')"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item prop="accountId">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.account_id') }}
        </p>
        <el-select
          v-model="form.accountId"
          :placeholder="$t('column_labels.account_id')"
          class="w-full"
          size="small"
          filterable
        >
          <el-option
            v-for="sa in subaccountList"
            :key="sa.value"
            :label="sa.name"
            :value="sa.value"
          ></el-option>
        </el-select>
      </slot>
    </el-form-item>

    <hr class="w-1/2 h-0 border-t border-grey-dark my-8 ml-0">

    <el-form-item prop="accountName">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.app_id') }}
        </p>
        <el-input v-model="form.accountName"
          :placeholder="$t('chat_apps.channels.zalo[0]')"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item prop="countries">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.primary_countries') }}
        </p>
        <p class="note text-xs text-grey-dark leading-tight my-2">
          ({{ $t('validations.at_least_one', { value: $tc('column_labels.subaccount', 1) } ) }})
        </p>
        <el-select
          v-model="form.countries"
          :placeholder="$t('chat_apps.channels.viber[1]')"
          class="w-full"
          size="small"
          multiple
          filterable
        >
          <el-option
            v-for="country in countriesJson"
            :key="country.value"
            :label="country.name"
            :value="country.value"
          ></el-option>
        </el-select>
      </slot>
    </el-form-item>

    <el-form-item prop="accountType">
      <slot name="label">
        <p class="text-sm text-grey-darkest leading-none">
          {{ $t('column_labels.type_of_account') }}
        </p>
        <el-select
          v-model="form.accountType"
          :placeholder="$t('chat_apps.channels.viber[0]')"
          class="mt-2 w-full"
          size="small"
          filterable
        >
          <el-option
            v-for="accountType in accountTypeOptions"
            :key="accountType.value"
            :label="accountType.name"
            :value="accountType.value"
          ></el-option>
        </el-select>
      </slot>
    </el-form-item>

    <el-form-item prop="iconUrl">
      <slot name="label">
        <div class="flex justify-between items-center">
          <p class="text-sm text-grey-darkest leading-none">
             {{ $t('chat_apps.channels.we_chat[2]')}}
          </p>
          <p class="text-xs text-grey leading-none">
             {{ $t('validations.optional')}}
          </p>
        </div>
        <el-input
          v-model="form.iconUrl"
          placeholder="http://www.example.com/Icon.png"
          size="small"
        />
      </slot>
    </el-form-item>

    <el-form-item class="mt-10">
      <el-button
        type="primary"
        size="small"
        @click="submit"
      >
        {{ $t('chat_apps.templates.create[21]') }}
      </el-button>
      <el-button
        size="small"
        @click="cancel"
      >
        {{ $t('actions.cancel') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import countriesJson from '@/json/countries.json';

export default {
  name: 'wechat-form',

  props: {
    channel: {
      default: () => {},
      type: Object,
    },
    subaccountList: {
      default: () => [],
      type: Array,
      required: true,
    },
  },

  data() {
    const accountTypeOptions = [
      { name: '1-way', value: 1 },
      { name: '2-way', value: 2 },
    ];

    const self = this;

    return {
      countriesJson,

      accountTypeOptions,

      form: {
        channel: this.channel.code,
        name: '',
        accountId: '',
        accountName: '',
        countries: [],
        accountType: 2,
        iconUrl: '',
      },

      formRules: {
        channel: [
          { required: true, message: self.$t('validations.required', { value: self.$tc('column_labels.channel', 1) }), trigger: 'blur' },
        ],

        name: [
          { required: true, message: self.$t('validations.required', { value: self.$t('column_labels.channel_name') }), trigger: 'blur' },
        ],

        accountId: [
          { required: true, message: self.$t('validations.required', { value: self.$t('column_labels.account_id') }), trigger: 'blur' },
        ],

        accountName: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.account_name') }), trigger: 'blur' },
        ],

        countries: [
          { required: true, message: self.$t('validations.at_least_one', { value: self.$t('fields.country') }), trigger: 'blur' },
        ],

        accountType: [
          { required: true, message: self.$t('validations.at_least_one', { value: self.$t('column_labels.account_type') }), trigger: 'blur' },
        ],

        iconUrl: [
          {
            pattern: /(?:([^:/?#]+):)?(?:\/\/([^/?#]*))?([^?#]*\.(?:jpe?g|png)$)/,
            message: self.$t('validations.valid', { value: self.$t('column_labels.url') }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  methods: {
    cancel() {
      this.$parent.$emit('close');
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('submit', this.form);
          } else {
            return false;
          }
          return false;
        });
      }
    },
  },
};
</script>

<styles lang="scss" scoped>
.el-form-item__label {
  line-height: 0;
  margin: 20px 0 10px;
}
.note {
  word-break: break-word;
}
</styles>
