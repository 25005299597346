import axios from 'axios';
import http from '../../../utils/http';

export default {
  // Fetch channel list
  getChannels: async ({ commit }) => {
    try {
      const { data, status } = await http.get('/chats/channels/all');

      if (Number(status) === 200 && data) {
        commit({ type: 'SET_DATA', data });
        return data;
      } else {
        throw new Error('Error fetching channels');
      }
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Send add channel json to email
  addChannel: async ({ commit }, payload) => {
    try {
      await http.post('/chats/channels/add', payload);

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Get service purchased
  getProductMeta: async ({ commit }) => {
    try {
      const { data, status } = await http.get('/chats/product-meta');

      if (Number(status) === 200 && data) {
        commit('SET_SERVICE_PURCHASED', { data: data[0] });

        return data;
      } else {
        throw new Error(`Error fetching product meta ${JSON.stringify(data)}`);
      }
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Get Shared WABA ID with Access Token
  getSharedWABAId: async ({ commit }, payload) => {
    try {
      const { data, status } = await http.get(
        `/chats/channels/whatsapp/wabas?accessToken=${payload.accessToken}`
      );

      if (Number(status) === 200) {
        commit('SET_PHONE_DETAILS', { data });
      } else {
        throw new Error('Error getting shared WABA ID');
      }
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Integrate with the WhatsApp API endpoints needed to complete the integration process
  processIntegration: async ({ commit }, payload) => {
    try {
      const { wabaId } = payload;

      const { data, status } = await http.post('/chats/channels/whatsapp/integrations', { wabaId });

      if (Number(status) === 200) {
        return data;
      } else {
        throw new Error('Error processing integration');
      }
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Save details to DB
  saveWAESDetails: async ({ commit }, payload) => {
    try {
      const { data, status } = await http.post('/chats/channels/whatsapp', payload);

      if (Number(status) === 200) {
        return data;
      } else {
        throw new Error('Error saving WhatsApp Channel details');
      }
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  submitPrepaidCA: async ({ commit }, payload) => {
    try {
      const { status, data } = await http.post('/chats/channels/activate/prepaid-ca', payload);

      if (Number(status) === 200) {
        return data;
      } else {
        throw new Error('Error saving WhatsApp Channel details');
      }
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  uploadProfilePicture: async ({ commit }, payload) => {
    try {
      const { file, accessToken, phoneNumberId } = payload;
      const formData = new FormData();
      formData.append('file', file);

      const { status, data } = await http.post(
        `/chats/channels/whatsapp/upload?phoneNumberId=${phoneNumberId}&accessToken=${accessToken}`,
        formData
      );

      if (Number(status) === 200) {
        return data;
      } else {
        throw new Error('Error uploading profile picture to Meta.');
      }
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
