<template>
  <div class="dashboard py-16 mx-10">
    <RouterView
      :user="user"
    />
  </div>
</template>

<script>
export default {
  name: 'register',

  props: {
    user: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin: 0 auto;
  max-width: 1200px;

  &__title {
    height: 70px;
  }

  &__inner {
    padding-right: 25em;
  }

  &__chat-illustration {
    width: 35%;
    position: absolute;
    bottom: -20px;
    right: 50px;
  }
}

.list {
  & li {
    padding-left: 10px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:before {
      content: '';
      width: 5px;
      height: 5px;
      background: var(--red);
      position: absolute;
      left: -5px;
      top: 6px;
      border-radius: 50%;
    }
  }
}

.header-logo {
  width: 48px;
}
</style>
