<template>
  <div v-loading="loading">
    <el-table
      :data="rawdata"
      :row-class-name="tableRowClassName"
      @row-click="(row) => showDetail(row)"
    >
      >
      <!-- Name column -->
      <el-table-column v-slot="scope" :label="$t('column_labels.name')">
        <div class="text-xs truncate" v-html="highlight(scope.row.templateName, search)"></div>
      </el-table-column>

      <!-- Category column -->
      <el-table-column v-slot="scope" :label="$t('column_labels.category')">
        <div>
          {{ $t(`chat_apps.categories.${scope.row.categoryName}`) }}
        </div>
      </el-table-column>

      <!-- Template column -->
      <el-table-column v-slot="scope" :label="$t('column_labels.template')">
        <el-tooltip
          placement="top"
          effect="light"
          popper-class="template-bubble-pop"
          :enterable="false"
        >
          <div
            :class="[
              'text-xs',
              'truncate',
              'cursor-pointer',
              'hover:text-blue',
              { 'text-grey': scope.row.status === 'REJECTED' },
            ]"
          >
            {{ sanitize(getContent(scope.row)) }}
          </div>
          <div slot="content">
            <div class="text-xs">
              <div class="flex">
                <div class="flex-auto leading-normal w-full pr-2">
                  <p class="text-xs font-medium truncate">
                    {{ scope.row.templateName }}
                  </p>
                  <span class="text-grey truncate" :style="{ fontSize: '10px' }">
                    {{ scope.row.languageName }}
                  </span>
                </div>
                <div
                  :class="[
                    'flex-shrink',
                    'font-bold',
                    'text-grey-dark',
                    { 'text-red': scope.row.status === 'REJECTED' },
                    { 'text-green': scope.row.status === 'APPROVED' },
                  ]"
                >
                  {{ scope.row.statusName }}
                </div>
              </div>
              <div class="template-bubble-pop__text w-full rounded-lg mt-3">
                <div
                  v-if="getContentHTML(scope.row).header.type"
                  class="header bg-white break-words rounded-t-lg"
                  :style="{ minHeight: '20px' }"
                >
                  <div
                    v-if="getContentHTML(scope.row).header.type === 'text'"
                    class="text-black font-medium p-3 py-2"
                  >
                    {{ getContentHTML(scope.row).header.text }}
                  </div>
                  <div
                    v-if="getContentHTML(scope.row).header.type !== 'text'"
                    class="text-grey-darker text-center p-3"
                  >
                    <img
                      :src="urlResolver(bgUrl, getContentHTML(scope.row).header.text)"
                      :alt="getContentHTML(scope.row).header.type"
                      :style="{ width: '25px', height: '25px' }"
                    />
                  </div>
                  <div
                    v-if="
                      getContentHTML(scope.row).header.type === 'location' &&
                      getContentHTML(scope.row).header.locFields.name &&
                      getContentHTML(scope.row).header.locFields.address
                    "
                    class="bg-grey-shade-custom pt-2 pb-2"
                  >
                    <div class="break-words mx-3" :style="{ fontSize: '11px' }">
                      {{ getContentHTML(scope.row).header.locFields.name }}
                    </div>
                    <div
                      class="break-words text-grey-darker mt-1 mx-3"
                      :style="{ fontSize: '10px' }"
                    >
                      {{ getContentHTML(scope.row).header.locFields.address }}
                    </div>
                  </div>
                </div>
                <div
                  class="body whitespace-pre-wrap break-words pt-2 px-3"
                  v-html="getContentHTML(scope.row).body"
                />
                <div
                  class="footer py-3 px-3 break-words text-grey-darker"
                  :style="{ fontSize: '10px' }"
                >
                  {{ getContentHTML(scope.row).footer }}
                </div>
              </div>
              <div v-if="getContentHTML(scope.row).buttons" class="button-grid w-full">
                <div
                  v-for="(button, index) in getContentHTML(scope.row).buttons.items"
                  :key="index"
                  class="button-grid__item px-1 mt-2"
                  :class="
                    (button.type === 'URL' || button.type === 'PHONE_NUMBER') &&
                    'button-grid__item--block'
                  "
                >
                  <div class="text-xs rounded p-2 bg-white shadow text-blue text-center">
                    {{ button.text || 'Button' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tooltip>
      </el-table-column>

      <!-- Language column -->
      <el-table-column v-slot="scope" :label="$tc('column_labels.language', 1)">
        <div class="text-xs truncate">{{ scope.row.languageName }}</div>
      </el-table-column>

      <!-- Status column -->
      <el-table-column v-slot="scope" :label="$t('column_labels.status')">
        <div class="text-xs truncate">
          <span :class="['template-status', `template-status--${scope.row.status.toLowerCase()}`]">
            {{ $t(`chat_apps.status.${scope.row.statusName}`) }}
          </span>
        </div>
      </el-table-column>

      <!-- Last updated column -->
      <el-table-column
        v-slot="scope"
        :label="getDateHeader(timeZone, $t('column_labels.last_updated'))"
      >
        <div class="text-xs truncate">
          {{ timeFromCalendar(scope.row.updatedAt, timeZoneOffset) }}
        </div>
      </el-table-column>

      <!-- Options column -->
      <el-table-column v-slot="scope" width="25" @click.prevent>
        <div class="flex items-center justify-center">
          <el-dropdown @command="(c) => handleCommand(c, scope.row)">
            <span class="el-dropdown-link">
              <i class="el-icon-more text-grey"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="scope.row.statusName.toLowerCase().match(/approved|rejected/g)"
                command="DUPLICATE"
              >
                <span class="text-xs">{{ $t('actions.duplicate') }} </span>
              </el-dropdown-item>
              <!-- <el&#45;dropdown&#45;item command="ADD_NEW_LANGUAGE"> -->
              <!--   <span class="text&#45;xs">Add new language</span> -->
              <!-- </el&#45;dropdown&#45;item> -->
              <el-dropdown-item command="DELETE">
                <span class="text-xs">{{ $t('actions.delete') }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="sizes, prev, pager, next"
      :hide-on-single-page="true"
      :total="paginationTotal"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import _ from 'lodash'; // eslint-disable-line
import { mapState } from 'vuex';

import timeFormat from '@/mixins/timeFormat';

import imageSrc from '@/assets/media/image-dark.svg';
import videoSrc from '@/assets/media/video-dark.svg';
import locationSrc from '@/assets/media/location-dark.svg';
import documentSrc from '@/assets/media/document-dark.svg';

import { urlResolver, formatReadableButtonValues } from '@/utils/common';

export default {
  name: 'TemplateTable',

  mixins: [timeFormat],

  props: {
    search: {
      default: '',
      type: String,
    },
    rawdata: {
      type: Array,
      default: () => [],
    },
    paginationTotal: {
      default: 0,
      type: Number,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    highlight(str, search) {
      return search.length
        ? this.$sanitize(str.replace(new RegExp(`(${search})`, 'i'), '<mark>$1</mark>'))
        : str;
    },

    sanitize(s) {
      return _.unescape(this.$sanitize(s));
    },

    urlResolver(url, imgUrl) {
      return urlResolver(url, imgUrl);
    },

    getContent(row) {
      const { components } = row;

      let header = '';
      let body = '';
      let footer = '';

      components.forEach((component) => {
        const { type, format, text } = component;

        if (type === 'HEADER' && format) {
          switch (format.toLowerCase()) {
            case 'text': {
              header = _.unescape(this.$sanitize(text));
              break;
            }

            case 'image': {
              header = '[image]';
              break;
            }

            case 'video': {
              header = '[video]';
              break;
            }

            case 'document': {
              header = '[document]';
              break;
            }

            case 'location': {
              header = '[location]';
              break;
            }

            default:
          }
        }

        if (type === 'BODY') {
          body = _.unescape(this.$sanitize(text));
        }

        if (type === 'FOOTER') {
          footer = _.unescape(this.$sanitize(text));
        }
      });

      return `${header} ${body} ${footer}`;
    },

    getContentHTML(row) {
      const { components } = row;

      let headerType = '';
      let headerText = '';
      let locFields = {
        latlong: '',
        name: '',
        address: '',
      };

      let body = '';
      let footer = '';
      let buttons = '';

      components.forEach((component) => {
        const { type, format, text, example } = component;

        // const bgUrl = process.env.VUE_APP_BASE_URL.replace(/\/$/g, '');

        if (type === 'HEADER' && format) {
          headerType = format.toLowerCase();
          switch (format.toLowerCase()) {
            case 'text': {
              headerText = _.unescape(this.sanitize(text));
              break;
            }

            case 'image': {
              headerText = imageSrc;
              break;
            }

            case 'video': {
              headerText = videoSrc;
              break;
            }

            case 'document': {
              headerText = documentSrc;
              break;
            }

            case 'location': {
              headerText = locationSrc;
              if (example && example.headerHandle && example.headerHandle.length > 0) {
                locFields.latlong = example.headerHandle[0];
                locFields.name = example.headerHandle[1];
                locFields.address = example.headerHandle[2];
              }
              break;
            }

            default:
          }
        }

        if (type === 'BODY') {
          body = _.unescape(this.sanitize(text));
        }

        if (type === 'FOOTER') {
          footer = _.unescape(this.sanitize(text));
        }

        if (type === 'BUTTONS') {
          const buttonValue = formatReadableButtonValues(component);
          buttons = { ...buttonValue };
        }
      });

      return {
        header: {
          type: headerType,
          text: headerText,
          locFields,
        },
        body,
        footer,
        buttons,
      };
    },

    tableRowClassName({ row }) {
      const classList = ['template-row'];
      if (row.status === 'PENDING_DELETION') {
        classList.push('template-row--disable');
      }
      return classList.toString().replace(',', ' ');
    },
    showDetail(row) {
      this.$emit('show-detail', row);
    },
    handleCommand(c, t) {
      switch (c) {
        case 'DUPLICATE':
          this.$emit('duplicate-template', t);
          break;
        // case 'ADD_NEW_LANGUAGE':
        //   this.$emit('add-new-language', t);
        //   break;
        case 'DELETE':
          this.$emit('delete-template', t);
          break;
        default:
          break;
      }
    },
    handleCurrentChange(page) {
      this.$emit('handlePageChange', page);
    },
    handleSizeChange(size) {
      this.$emit('handleSizeChange', size);
    },

    // For parsing lat and long if ever the GoogleMap component will be required here
    getLatLng(samples) {
      let latlng = {
        lat: '',
        lng: '',
      };

      const location = samples.latlong ? samples.latlong : '0,0';

      if (location) {
        const ll = location.split(',');
        latlng = {
          lat: ll[0],
          lng: ll[1],
        };
      }

      return latlng;
    },
  },
};
</script>

<style lang="scss">
.button-grid {
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex-grow: 1;
    min-width: 50%;

    &--block {
      width: 100%;
      min-width: 0;
    }
  }
}

.el-table {
  .template-row {
    &--disable {
      color: lightgray;
      background-color: whitesmoke;
      pointer-events: none;
    }
  }
}

.bg-grey-shade-custom {
  background-color: #f5f5f5;
}

.bg-grey-darker-custom {
  background-color: #9d9d9d;
}

.template-status {
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    margin-right: 5px;
    width: 6px;
    height: 6px;
    border-radius: 999px;
    background-color: gray;
  }

  &--approved {
    &:before {
      background-color: #38c172;
    }
  }

  &--rejected {
    &:before {
      background-color: #e3342f;
    }
  }
}

.template-bubble-pop {
  padding: 10px;
  background: white !important;
  border: solid 1px #e7e7e7 !important;
  width: 280px;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &__text {
    background-color: #e7e7e7;
    padding: 1px;
  }

  & .popper__arrow {
    border-top-color: #e7e7e7 !important;

    &:after {
      border-left: 5px solid transparent !important;
      border-right: 5px solid transparent !important;
      border-top: 5px solid white !important;
    }
  }
}
</style>
