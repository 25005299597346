import Vue from 'vue'; // eslint-disable-line
import Vuex from 'vuex';

import templates from './modules/templates';
import user from './modules/user';
import dashboard from './modules/dashboard';
import reports from './modules/reports';
import logs from './modules/logs';
import channels from './modules/channels';
import common from './modules/common';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
  },

  mutations: {
    API_ERROR(state, payload) {
      const message = payload;

      // if (Object.keys(payload).includes('error')
      // && Object.keys(payload.error).includes('message')) {
      //   const { error: { message: m } } = payload;
      //   message = m;
      //   Message.error({ message });
      // }

      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(message);
      // }
      state.errors.push(message); // eslint-disable-line no-param-reassign
    },
  },

  actions: {},

  modules: {
    templates,
    user,
    dashboard,
    reports,
    logs,
    channels,
    common,
  },

  strict: debug,
});

export default store;
