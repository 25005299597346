var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rows.length)?_c('div',[_c('div',{staticClass:"sample-reminder mt-5 text-black text-xs p-3 bg-yellow-lightest border border-solid border-yellow rounded mb-5 w-full leading-normal break-words-p"},[_vm._v("\n    "+_vm._s(_vm.$t('chat_apps.templates.whats_app_template_guidelines'))+"\n  ")]),_c('el-table',{attrs:{"data":_vm.rows,"size":"small"}},[_c('el-table-column',{attrs:{"label":_vm.$t('column_labels.dynamic_fields'),"prop":"label","scope":"scope","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(scope.row.label || ''))])]}}],null,false,1007657021)}),_c('el-table-column',{attrs:{"prop":"value","scope":"scope"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{staticClass:"mt-2",attrs:{"prop":'samples.' + scope.$index + '.value',"rules":_vm.rules(scope.row, scope.row.field)}},[_c('span',[_c('el-input',{staticClass:"w-full",attrs:{"size":"small","placeholder":_vm.getPlaceHolder(scope.row.name, scope.row.field)},on:{"focus":function($event){{
                  if (scope.row.name && scope.row.name === 'video') {
                    _vm.onSelectRow(scope.$index, scope.row);
                  }
                }},"change":function (value) {
                  _vm.onChange(value, scope.$index);
                }},model:{value:(_vm.rows[scope.$index].value),callback:function ($$v) {_vm.$set(_vm.rows[scope.$index], "value", $$v)},expression:"rows[scope.$index].value"}},[(
                  scope.row.name &&
                  ['image', 'location', 'video', 'document'].includes(scope.row.name) &&
                  !['name', 'address'].includes(scope.row.field)
                )?_c('i',{staticClass:"text-gray-darker cursor-pointer",attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.onSelectRow(scope.$index, scope.row)}},slot:"suffix"},[_c('el-tooltip',{attrs:{"effect":"dark","content":scope.row.name.match(/location/)
                      ? _vm.$t('mcs.messages.chat_apps.custom_fields[5]')
                      : _vm.$t('mcs.messages.chat_apps.custom_fields[7]'),"placement":"top"}},[_c('i',{staticClass:"font-semibold text-sm",class:scope.row.name.match(/location/)
                        ? 'el-icon-location-outline'
                        : 'el-icon-upload2'})])],1):_vm._e()])],1)])]}}],null,false,3996193076)},[_c('template',{slot:"header"},[_c('span',[_vm._v("\n          "+_vm._s(_vm.capitalizeF(_vm.$t('chat_apps.templates.sample_content')))+"\n          "),_c('el-popover',{attrs:{"placement":"top-start","width":"300","trigger":"hover"}},[_c('div',{staticClass:"sample-reminder text-xs"},[_vm._v("\n              "+_vm._s(_vm.$t('chat_apps.templates.whats_app_sample_purpose'))+"\n            ")]),_c('i',{staticClass:"el-icon-question",attrs:{"slot":"reference"},slot:"reference"})])],1)])],2)],1),(_vm.selectedRow && Object.keys(_vm.selectedRow).length && _vm.selectedRow.action === 'upload')?_c('UploadMedia',{attrs:{"data":_vm.selectedRow,"visible":_vm.visible},on:{"apply-data":_vm.applyData,"close-modal":_vm.closeModal}}):_vm._e(),(_vm.selectedRow && Object.keys(_vm.selectedRow).length && _vm.selectedRow.action === 'location')?_c('LocationMedia',{attrs:{"data":_vm.selectedRow,"visible":_vm.visible},on:{"apply-data":_vm.applyData,"close-modal":_vm.closeModal}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }