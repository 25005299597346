/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    loadingFilters: false,
    templates: [],
    loadingTemplate: false,
    savingLoading: false,
    deleteLoading: false,
    selectedTemplate: {},
    cloneTemplate: {},
  },

  getters: {
    savingLoading: (st) => st.savingLoading,
    deleteLoading: (st) => st.deleteLoading,
  },

  mutations: {
    SET_LOADING_FILTERS: (st, payload) => {
      st.loadingFilters = payload.bool;
    },
    SET_LOADING_TEMPLATE: (st, payload) => {
      st.loadingTemplate = payload.bool;
    },
    SET_SAVING_LOADING: (st, payload) => {
      st.savingLoading = payload.bool;
    },
    SET_DELETE_LOADING: (st, payload) => {
      st.deleteLoading = payload.bool;
    },
    FETCH_TEMPLATES: (st, payload) => {
      st.templates = payload.templates;
    },
    SET_SELECTED_TEMPLATE: (st, payload) => {
      st.selectedTemplate = payload.template;
    },
    SET_CLONE_TEMPLATE: (st, payload) => {
      st.cloneTemplate = payload.template;
    },
  },

  actions,
};
