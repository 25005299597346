<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('chat_apps.templates.index[0]') }}
    </h1>
    <form class="relative mt-5">
      <div class="flex items-center w-full">
        <div class="mr-4 flex w-full items-center">
          <!-- channelId Select -->
          <div class="mr-3 mt-4">
            <span class="text-xs text-black block mb-2">{{ $t('column_labels.channel_id') }}</span>
            <el-select
              v-model="filters.channelId"
              size="small"
              filterable
              :loading="loadingFilters"
              @change="handleChangeChannel"
            >
              <el-option
                v-for="subaccount in waChannels"
                :key="subaccount.id"
                :label="subaccount.name"
                :value="subaccount.id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- Category Select -->
          <div class="mr-3 mt-4">
            <span class="text-xs text-black block mb-2">{{ $t('column_labels.category') }}</span>
            <el-select
              v-model="filters.category"
              size="small"
              filterable
              :loading="loadingFilters"
              @change="getTemplates(filters)"
            >
              <el-option
                v-for="category in getFilterList('category', 'All')"
                :key="category.value"
                :label="$t(`chat_apps.categories.${category.name}`)"
                :value="category.value"
              >
              </el-option>
            </el-select>
          </div>

          <!-- Language Select -->
          <div class="mr-3 mt-4">
            <span class="text-xs text-black block mb-2">{{
              $tc('column_labels.language', 1)
            }}</span>
            <el-select
              v-model="filters.language"
              size="small"
              filterable
              :loading="loadingFilters"
              @change="handleFetchTemplates(filters)"
            >
              <el-option
                v-for="language in getFilterList('language', $t('chat_apps.templates.index[2]'))"
                :key="language.value"
                :label="language.name"
                :value="language.value"
              >
              </el-option>
            </el-select>
          </div>

          <!-- Status Select -->
          <div class="mr-3 mt-4">
            <span class="text-xs text-black block mb-2">{{ $t('column_labels.status') }}</span>
            <el-select
              v-model="filters.status"
              size="small"
              filterable
              :loading="loadingFilters"
              @change="handleFetchTemplates(filters)"
            >
              <el-option
                v-for="status in getFilterList('status', 'All')"
                :key="status.value"
                :label="$t(`chat_apps.status.${status.name}`)"
                :value="status.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="ml-auto">
            <el-button
              :disabled="!Object.keys(enums).length"
              type="primary"
              size="small"
              @click="handleCtModalOpen"
            >
              {{ $t('chat_apps.templates.index[4]') }}
            </el-button>
          </div>
        </div>
      </div>
    </form>

    <div class="relative">
      <div
        v-if="showNote"
        class="border border-blue-lighter bg-blue-lightest p-3 pr-8 text-xs text-grey-dark leading-normal relative mt-5"
      >
        {{ $t('chat_apps.templates.index[5]') }}
        <a
          class="absolute pin-t pin-r text-sm text-black hover:text-blue p-1 m-2 cursor-pointer"
          @click="hideNote"
        >
          <i class="el-icon-close"></i>
        </a>
      </div>
      <div class="mt-10 w-full">
        <div class="flex justify-between mb-5">
          <div class="flex items-center">
            <h2 class="text-sm font-normal items-center">
              {{ $t('chat_apps.templates.index[6]') }}
            </h2>
            <i
              :class="[
                'el-icon-refresh',
                'ml-3',
                'text-sm',
                'text-grey-dark',
                'cursor-pointer',
                'hover:text-blue',
                { 'loading-spin': loadingTemplate },
              ]"
              @click="handleFetchTemplates(filters)"
            >
            </i>
          </div>
          <div class="w-64">
            <el-input v-model="search" :placeholder="$t('actions.type_to_search')" size="small">
              <i slot="suffix" class="el-icon-search el-input__icon"></i>
            </el-input>
          </div>
        </div>
        <Table
          :search="search"
          :rawdata="tableData"
          :loading="loadingTemplate"
          :pagination-total="paginationTotal"
          @duplicate-template="duplicateTemplate"
          @add-new-language="newLanguageTemplate"
          @delete-template="deleteTemplate"
          @show-detail="handlePtModalOpen"
          @handlePageChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
    <preview-template
      :template="selectedTemplate"
      :pt-visible="ptVisible"
      :loading="deleteLoading"
      @close="handlePtModalClose"
      @duplicate-template="duplicateTemplate"
      @delete-template="deleteTemplate"
    />
    <create-template
      :filters="filters"
      :default-form-value="cloneTemplate"
      :enums="enums"
      :wa-channels="waChannels"
      :ct-visible="ctVisible"
      :loading="savingLoading"
      :validate-name="hasDuplicateName"
      :get-duplicate-name="getDuplicateTemplateName"
      @close="handleCtModalClose"
      @create-template="createTemplate"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash'; // eslint-disable-line
import Table from './partials/Table.vue';
import CreateTemplate from './partials/CreateTemplate.vue';
import PreviewTemplate from './partials/PreviewTemplate.vue';

export default {
  name: 'Template',

  components: {
    Table,
    CreateTemplate,
    PreviewTemplate,
  },

  data() {
    return {
      showNote: true,
      search: '',
      filters: {
        category: '',
        language: '',
        status: '',
        channelId: '',
      },
      pagination: {
        size: 10,
        page: 1,
      },
      ctVisible: false,
      ptVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      getFilterList: 'user/getFilterList',
      getSubaccountsList: 'user/getSubaccountsList',
      enums: 'user/enums',
      savingLoading: 'templates/savingLoading',
      deleteLoading: 'templates/deleteLoading',
      waChannels: 'user/waChannels',
    }),

    ...mapState('templates', [
      'templates',
      'loadingFilters',
      'loadingTemplate',
      'selectedTemplate',
      'cloneTemplate',
    ]),

    paginationTotal() {
      let total = this.templates.length;

      if (this.search.trim().length) {
        total = this.tableData;
      }

      return total;
    },

    tableData() {
      if (this.search.trim().length) {
        const filters = ['templateName', 'statusName'];
        const result = this.templates.filter((row) =>
          filters.some((k) => {
            if (row[k]) {
              return row[k]
                .replace(/\s+(?=\d)|[+]/g, '')
                .toLowerCase()
                .includes(this.search.replace(/\s+(?=\d)|[+]/g, '').toLowerCase());
            }
            return 0;
          })
        );

        return this.paginateData(result);
      }
      return this.paginateData(this.templates);
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination = { size: 10, page: 1 };
      },
    },
    search() {
      this.pagination = { size: 10, page: 1 };
    },
  },

  created() {
    // Set selected template to empty
    this.setSelectedTemplate({ template: {} });

    // Load all filter options
    this.loadSelectOptions();

    // Hide note depending on local storage
    this.showNote = !localStorage.getItem('template-note');
  },

  beforeDestory() {
    this.setSelectedTemplate({ template: {} });
  },

  methods: {
    ...mapMutations({
      setLoadingFilters: 'templates/SET_LOADING_FILTERS',
      setSelectedTemplate: 'templates/SET_SELECTED_TEMPLATE',
      setDefaultFormValue: 'templates/SET_CLONE_TEMPLATE',
      setTemplateLoading: 'templates/SET_LOADING_TEMPLATE',
      setSaveLoading: 'templates/SET_SAVING_LOADING',
      setDeleteLoading: 'templates/SET_DELETE_LOADING',
    }),

    ...mapActions({
      getTemplates: 'templates/getTemplates',
      removeTemplate: 'templates/removeTemplate',
      getFilterEnums: 'user/getFilterEnums',
      getSubaccounts: 'user/getSubaccounts',
      saveTemplate: 'templates/saveTemplate',
      getWAChannels: 'user/getWAChannels',
    }),

    handleSizeChange(size) {
      this.pagination.size = size;
      this.paginateData();
    },

    handlePageChange(page) {
      this.pagination.page = page;
      this.paginateData();
    },

    paginateData(data) {
      if (!data || !data.length) return [];

      const index = this.pagination.page ? this.pagination.page - 1 : 0;
      const size = this.pagination.size ? this.pagination.size : 10;

      const chunks = _.chunk(data, size);

      this.paginatedTemplates = chunks[index];

      return this.paginatedTemplates;
    },

    hasDuplicateName(s) {
      return !this.templates.filter((t) => t.templateName === s).length;
    },

    async handleFetchTemplates(filters) {
      try {
        this.setLoadingFilters({ bool: true });
        this.setTemplateLoading({ bool: true });
        await this.getTemplates(filters);
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.setLoadingFilters({ bool: false });
        this.setTemplateLoading({ bool: false });
      }
    },

    async handleChangeChannel() {
      // Fetch templates and enums after
      try {
        this.setLoadingFilters({ bool: true });
        this.setTemplateLoading({ bool: true });
        this.getTemplates(this.filters);

        this.getFilterEnums({ channelId: this.filters.channelId });
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.setLoadingFilters({ bool: false });
        this.setTemplateLoading({ bool: false });
      }
    },

    getDuplicateTemplateName(s) {
      const reg = /_copy\d+$/g;
      const str = s.replace(reg, '');
      const index = this.templates
        .filter((t) => t.templateName.replace(reg, '') === str && !!t.templateName.match(reg))
        .reduce((max, t) => {
          const val = t.templateName.match(/(\d*)$/g)
            ? parseInt(t.templateName.match(/(\d*)$/g).toString(), 10)
            : -1;
          return val > max ? val : max;
        }, 0);
      return `${str}_copy${index + 1}`;
    },

    async loadSelectOptions() {
      this.setLoadingFilters({ bool: true });
      try {
        this.setTemplateLoading({ bool: true });
        // Fetch subaccount then set it to default values
        await this.getSubaccounts();
        await this.getWAChannels();

        const channels = this.waChannels;
        this.filters.channelId = (channels && channels.length && channels[0].id) || null;

        // Fetch templates and enums after
        this.getTemplates(this.filters);
        this.getFilterEnums({ channelId: this.filters.channelId });
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.setLoadingFilters({ bool: false });
        this.setTemplateLoading({ bool: false });
      }
    },

    // Hide create template modal
    handleCtModalClose() {
      this.ctVisible = false;
      this.setDefaultFormValue({ template: {} });
    },

    // Show create template modal
    handleCtModalOpen() {
      this.ctVisible = true;
    },

    // Hide preview template modal
    handlePtModalClose() {
      this.ptVisible = false;
      this.setSelectedTemplate({ template: {} });
    },

    // Show preview template modal
    handlePtModalOpen(template) {
      this.ptVisible = true;
      this.setSelectedTemplate({ template });
    },

    async hideNote() {
      const self = this;
      try {
        await this.$confirm(this.$t('chat_apps.templates.index[7]'), {
          confirmButtonText: self.$t('actions.yes'),
          cancelButtonText: self.$t('chat_apps.templates.index[8]'),
        });
        localStorage.setItem('template-note', true);
      } catch (e) {
        // Show again
      } finally {
        this.showNote = false;
      }
    },

    duplicateTemplate(t) {
      this.setDefaultFormValue({
        template: {
          ...t,
          type: 'DUPLICATE',
        },
      });
      this.handlePtModalClose();
      this.handleCtModalOpen();
    },

    newLanguageTemplate(t) {
      this.setDefaultFormValue({
        template: {
          ...t,
          type: 'NEW_LANGUAGE',
        },
      });
      this.handlePtModalClose();
      this.handleCtModalOpen();
    },

    async deleteTemplate(t) {
      const note = t.status === 'APPROVED' ? this.$t('chat_apps.templates.index[9]') : '';

      const self = this;

      try {
        await this.$confirm(
          `${note}`,
          this.$t('chat_apps.templates.index[10]', { template: t.templateName }),
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: self.$t('chat_apps.templates.index[11]'),
            cancelButtonText: self.$t('actions.cancel'),
          }
        );

        this.setDeleteLoading({ bool: true });

        // remove template
        await this.removeTemplate({
          template: t,
          filters: this.filters,
        });

        this.$message.success(
          this.$t('success.deleted', {
            value: t.templateName,
          })
        );

        this.handlePtModalClose();

        // get template list
        this.setTemplateLoading({ bool: true });
        await this.getTemplates(this.filters);
      } catch (err) {
        if (err !== 'cancel') {
          this.$showError(this, err);
          // this.$message.error('Failed to remove template. Please try again.');
        }
      } finally {
        this.setDeleteLoading({ bool: false });
        this.setTemplateLoading({ bool: false });
      }
    },

    async createTemplate(payload) {
      const p = payload;

      try {
        this.setSaveLoading({ bool: true });
        await this.saveTemplate(p);

        // this.$message.success(`${payload.templateName}
        // template has been submitted for approval.`);
        this.$message.success(
          this.$t('chat_apps.templates.index[12]', {
            template: payload.templateName,
          })
        );

        this.handleCtModalClose();

        // get template list
        this.setTemplateLoading({ bool: true });
        await this.getTemplates(this.filters);
      } catch (e) {
        if (e.error && e.error.code && e.error.code === 3032) {
          this.$showError(this, e, { useMessage: this.$t('chat_apps.templates.index[13]') });
        } else {
          this.$showError(this, e);
        }
      } finally {
        this.setSaveLoading({ bool: false });
        this.setTemplateLoading({ bool: false });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
</style>
