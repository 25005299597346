<template>
  <div id="chat" class="h-full">
    <RouterView :user="user" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },

  created() {
    this.getUser();
  },

  beforeDestroy() {
    this.$el.className = '';
  },

  mounted() {
    // window.addEventListener('localeChanged', () => {
    //   this.$i18n.locale = this.$getSelectedLocale();
    // });
  },

  methods: {
    ...mapActions({
      getUser: 'user/getUser',
    }),
  },
};
</script>

<style lang="scss">
.el-table th {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
}

html,
body {
  min-width: 1250px;
  height: 100%;
  font-family: 'roboto';
}

mark {
  color: #3490dc;
  background: none;
}

@font-face {
  font-family: 'icomoon';
  src: url('./assets/icons/icomoon.eot?llnvlg');
  src: url('./assets/icons/icomoon.eot?llnvlg#iefix') format('embedded-opentype'),
    url('./assets/icons/icomoon.ttf?llnvlg') format('truetype'),
    url('./assets/icons/icomoon.woff?llnvlg') format('woff'),
    url('./assets/icons/icomoon.svg?llnvlg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ca-logs:before {
  content: '\c900';
}
.icon-ca-reports:before {
  content: '\c901';
}
.icon-ca-pricing:before {
  content: '\c902';
}
.icon-ca-overview:before {
  content: '\c903';
}
.icon-ca-dashboard:before {
  content: '\c904';
}
.icon-ca-templates:before {
  content: '\c905';
}
.icon-ca-channels:before {
  content: '\c906';
}
</style>
