<template>
  <el-dialog
    :title="title"
    width="50%"
    :visible.sync="isVisible"
    destroy-on-close
    @open="openDialog"
    @close="closeDialog"
  >
    <el-form ref="form" :model="form" :rules="formRules">
      <div class="w-full flex -mx-1">
        <div class="w-1/2 px-1">
          <el-form-item class="w-full" prop="subAccounts">
            <el-select
              v-model="form.subAccounts"
              size="small"
              :placeholder="$tc('column_labels.subaccount', 2)"
              multiple
              collapse-tags
              class="w-full"
            >
              <el-option
                v-for="subAcc in subaccountList"
                :key="subAcc.value"
                :label="subAcc.name"
                :value="subAcc.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="w-1/2 px-1">
          <el-form-item class="w-full" prop="daterange">
            <div class="w-full">
              <el-date-picker
                v-model="form.daterange"
                type="daterange"
                size="small"
                format="dd MMM yyyy"
                unlink-panels
                :start-placeholder="$t('date_labels.start_date')"
                :end-placeholder="$t('date_labels.end_date')"
                class="export-date-picker"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="-mx-1 w-full flex">
        <el-form-item class="px-1 w-1/3" prop="country">
          <span class="text-xs text-black block mb-2 leading-none">
            {{ $t('column_labels.country') }}
          </span>
          <el-select
            v-model="form.country"
            size="mini"
            :placeholder="$t('column_labels.country')"
            class="w-full"
            filterable
          >
            <el-option
              v-for="status in [
                { name: $t('chat_apps.logs.filters[2]'), value: '' },
                ...countryList,
              ]"
              :key="status.value"
              :label="status.name"
              :value="status.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="px-1 w-1/3" prop="msisdn">
          <span class="text-xs text-black block mb-2 leading-none">
            {{ $t('column_labels.phone_no') }}
          </span>
          <el-input
            v-model="form.msisdn"
            :debounce="debounceDefault"
            :placeholder="$t('column_labels.phone_no')"
            size="mini"
          />
        </el-form-item>
        <el-form-item class="px-1 w-1/3" prop="umid">
          <span class="text-xs text-black block mb-2 leading-none">
            {{ $t('column_labels.umid') }}
          </span>
          <el-input
            v-model="form.umid"
            :debounce="debounceDefault"
            :placeholder="$t('column_labels.umid')"
            size="mini"
          />
        </el-form-item>
      </div>
      <hr class="w-full h-px bg-grey-light my-5" />
      <p class="text-xs text-grey">
        {{ $t('messaging.logs_full_download[1]') }}
      </p>
      <!-- <p class="text-sm text-grey-darker mt-5 mb-3">
       {{ $t('messaging.logs_full_download[2]') }}
      <b class="text-grey-darkest">
        {{ userEmail }}
      </b>
      as soon as it's ready.
      </p> -->
      <i18n
        class="text-sm text-grey-darker mt-5 mb-3"
        path="messaging.logs_full_download[2]"
        tag="p"
      >
        <template #email>
          <b class="text-grey-darkest">{{ userEmail }}</b>
        </template>
      </i18n>
      <a v-if="!addEmail" class="text-sm text-blue cursor-pointer" @click="showEmailInput">
        {{ $t('messaging.logs_full_download[3]') }}
      </a>
      <el-form-item v-else class="w-1/2" prop="email">
        <el-input
          v-model="form.email"
          size="small"
          :placeholder="$t('messaging.reports.export_modal[5]')"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="exportData">
        {{ $t('messaging.logs_full_download[5]') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'ExportModal',

  props: {
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: () => {},
      type: Object,
    },
    isVisible: {
      default: false,
      type: Boolean,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
    countryList: {
      default: () => [],
      type: Array,
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    const self = this;
    return {
      addEmail: false,

      userEmail: '',

      debounceDefault: 500,

      form: {
        subAccounts: [],
        daterange: [],
        email: '',
        country: '',
        msisdn: '',
        umid: '',
      },

      formRules: {
        subAccounts: [
          {
            required: true,
            message: self.$t('validations.at_least_one', {
              value: self.$tc('column_labels.subaccount', 1),
            }),
            trigger: ['blur', 'change'],
          },
        ],
        daterange: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.date_range'),
            }),
            trigger: 'blur',
          },
        ],
        email: [
          {
            type: 'email',
            message: self.$t('validations.required', { value: self.$t('fields.email') }),
            trigger: 'blur',
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return (
            Moment() < time || Moment().diff(time, 'months') >= 6 || Moment().isSame(time, 'day')
          );
        },
      },
    };
  },

  created() {
    this.userEmail = this.getLoginUser().Login;
    this.form.email1 = this.userEmail;
  },

  methods: {
    getLoginUser() {
      const cpv3User = localStorage.getItem('CPV3_User');
      return JSON.parse(cpv3User || {});
    },

    openDialog() {
      this.subaccountList.forEach((sa) => {
        this.form.subAccounts.push(sa.value);
      });

      // If either default start and end dates are today; then change it to yesterday
      const noTodayDaterange = this.filters.daterange.map((dt) =>
        Moment().isSame(dt, 'day') ? Moment().endOf('day').subtract(1, 'd') : dt
      );

      this.form.daterange = noTodayDaterange;

      // Set form values same as filters
      this.form.country = this.filters.country;
      this.form.msisdn = this.filters.msisdn;
      this.form.umid = this.filters.umid;
    },

    closeDialog() {
      this.$emit('close-modal');
      this.addEmail = false;
      this.resetForm();
    },

    resetForm() {
      this.$refs.form.resetFields();

      this.form.subAccounts = [];
      this.form.daterange = '';
      this.form.country = '';
      this.form.msisdn = '';
      this.form.umid = '';
    },

    exportData() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { subAccounts, daterange, email, country, msisdn, umid } = this.form;

            const payload = {
              subAccounts,
              daterange,
              emails: [this.userEmail],
              country,
              msisdn,
              umid,
              offset: this.timeZoneOffset,
            };

            // Add additional email
            if (email) {
              payload.emails.push(email);
            }

            this.$emit('submit', payload);
          } else {
            return false;
          }
          return false;
        });
      }
    },

    showEmailInput() {
      this.addEmail = true;
    },
  },
};
</script>

<style lang="scss">
.export-date-picker {
  width: 100% !important;
}
</style>
