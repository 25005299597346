/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    filters: {
      reportType: '',
      metric: '',
      daterange: [],
      channels: [],
      chargable: [],
    },
    reports: {},
    loading: true,
  },

  getters: {
    getMessagesData: (st) => {
      const { channels, chargable, reportType } = st.filters;
      let messages = [];

      if (
        Object.keys(st.reports).length &&
        st.reports.messages &&
        reportType.match(/channel_wise/i)
      ) {
        // Generate data by channel
        messages = Object.keys(st.reports.messages)
          .filter((c) => channels.includes(c))
          .reduce((channel, code) => {
            channel[code] = st.reports.messages[code];
            return channel;
          }, {});
      } else if (Object.keys(st.reports).length && st.reports.messages) {
        // Generate data by free and chargable
        messages = Object.keys(st.reports.messages)
          .filter((c) => chargable.includes(c))
          .reduce((data, code) => {
            data[code] = st.reports.messages[code];
            return data;
          }, {});
      } else if (Object.keys(st.reports).length && st.reports.conversations) {
        if (st.reports.chargeable) {
          st.reports.conversations.chargeable = st.reports.chargeable;
        }

        if (st.reports.free) {
          st.reports.conversations.free = st.reports.free;
        }

        messages = Object.keys(st.reports.conversations)
          .filter((c) => chargable.includes(c))
          .reduce((data, code) => {
            data[code] = st.reports.conversations[code];
            return data;
          }, {});
      }

      return messages;
    },
    getMessagesPerType: (st) => {
      if (!Object.keys(st.reports).length) {
        return [];
      }

      if (st.reports.messagesPerChannel) {
        return st.reports.messagesPerChannel.filter((c) => c.totalSent);
      }
      if (st.reports.conversationsPerType) {
        return st.reports.conversationsPerType
          .filter((c) => c.total)
          .map((t) => {
            if (t.total) {
              t.totalSent = t.total;
            }

            return t;
          });
      }

      return [];
    },

    getReportsTable: (st) => st.reports.grid,
  },

  mutations: {
    SET_FILTERS: (st, payload) => {
      st.filters = payload.filters;
    },
    SET_REPORTS: (st, payload) => {
      st.reports = payload.reports;
    },
    SET_LOADING: (st, payload) => {
      st.loading = payload.bool;
    },
  },

  actions,
};
